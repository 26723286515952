import React, { useState } from 'react';
import { Modal, Input } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { addEditedCourse } from '../../features/Courses/Courses';

const AddCompetency = ({ isModalOpen, setIsModalOpen }) => {

    const dispatch = useDispatch();
    const editedCourse = useSelector((state) => state.courses.editedCourse);

    const [newComp, setNewComp] = useState();

    const handleOk = () => {
        setIsModalOpen(false);
        dispatch(addEditedCourse({
            ...editedCourse,
            course_competencies: [...editedCourse.course_competencies, newComp]
        }));
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setNewComp()
    };

    const handleInputChange = (e) => {
        setNewComp(e.target.value);
    };

    return (
        <div>
            <Modal
                styles={{ padding: "1rem", marginLeft: "-1rem" }}
                title="Add Competency"
                open={isModalOpen}
                onOk={handleOk}
                okText="Save Changes"
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { textAlign: "center", width: "10vw", height: "6vh", backgroundColor: "#FFE60A", fontSize: "1.1vw", borderRadius: "0.3vw", fontWeight: "500", color: "black", marginTop: "0.5rem" } }}
                width={"70rem"}>
                <Input.TextArea style={{ backgroundColor: "#EBF5FF" }} value={newComp} onChange={handleInputChange} />
            </Modal>
        </div>
    )
}

export default AddCompetency
