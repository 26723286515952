import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

import { ReactComponent as PieChartLogo } from "../../../Assets/SVG/charts/piechart.svg"
import { ReactComponent as Info } from "../../../Assets/SVG/charts/info.svg"
import { ReactComponent as Response } from "../../../Assets/SVG/charts/response.svg"
import ResponseStats from '../ResponseStats';

const PieChartComponent = () => {
  const selectedPieChart = useSelector(state => state.feedback.selectedPieChart);
  console.log(selectedPieChart);
  const colorMap = {
  
    5: "#2AC239", // excellent
    4: "#95FFA0", // good
    3: "#FFCA21", // satisfactory
    2: "#FF8484", // improvement
    1: "#FF4848", // poor
    "not given":"#B4B4B4",
  };

  const renderTooltipContent = (data) => {
    const { payload } = data;
    if (payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc',color: '#414D55', boxShadow: '0px 2px 40px rgba(0, 0, 0, 0.10)',borderRadius: 8, }}>
          <p style={{ margin: 0 }}>{`${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className='linegraph'>
      <div className='linegraph__header'>
        <div className='linegraph__header__logo'>
          <PieChartLogo />
          <span>Feedback Summary</span>
        </div>
        <Info />
      </div>

      <div className='linegraph__pie'>
        <div className='linegraph__pie__info'>
          <div>Total Responses</div>
          <div className='linegraph__pie__info__ratings' style={{ display: "flex", fontSize: "1.8vw", fontWeight: "600", gap: "0.3vw" }}>
            <span>{selectedPieChart.feedback_given}</span>
            <span style={{ color: "#B4B4B4" }} >/</span>
            <span style={{ color: "#B4B4B4" }}>{selectedPieChart.total_participants}</span>
          </div>
          <div style={{ margin: "2vh 0" }}>
            <ResponseStats/>
          </div>
        </div>

        <div className='linegraph__pie__chart'>
          <PieChart width={250} height={250}>
            <Pie
              data={selectedPieChart.pie_chart_data}
              dataKey="percentage"
              nameKey="type"
              cx="50%"
              cy="50%"
              outerRadius={120}
              innerRadius={70}
              label={false}
              labelLine={false}
            >
              {selectedPieChart.pie_chart_data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorMap[entry.type]} />
              ))}
            </Pie>
            <Tooltip content={renderTooltipContent} />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default PieChartComponent;
