import React, { useState } from "react";
import SignUpContainer from "../../Container/Participants/signupcontainer/SignUpContainer";
import "../../css/login.css"
import { Button } from "antd";
import illustrator from "../../Assets/Images/Illustration -Participants.png"
import logo from "../../Assets/Images/logo.png"

const SignupPage = () => {
    return (
        <main>
            <div className="login">
                <div className="login-image">

                    <div className="login-navigation"><Button type="link" block>
                        Back to CBDT website
                    </Button></div>
                    <img src={illustrator} alt="login-background" className="carousel" />
                    <div className="login-subheading"><h3>Platform to capture training participation.</h3></div>
                </div>
                <div className="login-Content">
                    <div className="login-Content-logo">
                        <img src={logo} alt="login-background" className="carousel" />
                        <h3>Directorate Of Training, CBDT</h3>
                    </div>
                    <div className="login-Content-heading" >
                        <h1 style={{marginBottom:"3px"}}>Create Account</h1>
                        {/* <Button type="link" block>
                            Please enter your details
                        </Button> */}
                    </div>
                    <SignUpContainer />
                    <p style={{fontSize:"14px", marginBottom:"2rem" }}>
                        Already have an account! <a href="/login/participants">Login </a>
                    </p>
                </div>
            </div>
        </main>
    );
};

export default SignupPage;
