import React, { useState } from "react";
import participantsIcon from "../../Assets/SVG/participants/participants.svg";
import ChooseCourse from "../Feedback/ChooseCourseModal";
import { useSelector } from "react-redux";
import { Input } from "antd";
const { Search } = Input;
const ParticipantsTopbar = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const selectedCourseP = useSelector(state => state.participants.selectedCourseP)

  const placeholder = selectedCourseP ? selectedCourseP.name : "Input search text"
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const handleOpenModal = () => {
    setModalVisible(true);
  };
  return (
    <div className="participants__topbar">
      <div className="participants__topbar__elem1">
        <img
          className="participants__topbar__elem1__icon"
          src={participantsIcon}
          alt="participants"
        />
        <span className="participants__topbar__elem1__text">Participants</span>
      </div>
      <div style={{display: "flex", alignItems: "center", gap: "1vw"}}>
        <div className="participants__topbar__elem2">
          <Search
            style={{ marginLeft: "16rem", backgroundColor: "#dcedff", width: "20vw" }}
            placeholder={placeholder}
            allowClear
            // enterButton={
            //   <ChooseCourse />
            // }
            size="large"
            onSearch={onSearch}
          />
        </div>
        <div className="feedback-header__button">
          <button onClick={handleOpenModal}>Choose Course</button>
        </div>
      </div>
      <ChooseCourse type={"participants"} visible={modalVisible} setModalVisible={setModalVisible} />
    </div>
  );
};

export default ParticipantsTopbar;
