import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Radio, Input, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addEditSession, addEditedCourse } from '../../features/Courses/Courses';

const EditGuestLecture = ({ data, setIsEditModal, isEditModal }) => {
    const [speakerName, setSpeakerName] = useState(data.speaker_name || '');
    const [session, setSession] = useState(data.session || '');
    const [courseId, setCourseId] = useState(data.course_id || '');
    const [speakerType, setSpeakerType] = useState(data.speaker_type || '');
    const [competencies, setCompetencies] = useState(data.competencies || '');


    const dispatch = useDispatch();

    const selectedCourse = useSelector((state) => state.courses.selectedCourse);

    const handleOk = () => {

        const values = {
            speaker_name: speakerName,
            session: session,
            // course_id: courseId,
            speaker_type: speakerType,
            competencies: ["IoT"]
        };

        const editData = {
            id: data.id,
            data: values
        }

        const index = selectedCourse.session_details.findIndex(session => session.id === editData.id);

        if (index !== -1) {
            const updatedSessionDetails = [
                ...selectedCourse.session_details.slice(0, index),
                { ...selectedCourse.session_details[index], ...editData.data },
                ...selectedCourse.session_details.slice(index + 1),
            ];

            dispatch(addEditedCourse({ ...selectedCourse, session_details: updatedSessionDetails }));
        }

        dispatch(addEditSession(editData));
        setIsEditModal(false);
    };

    const handleCancel = () => {
        setIsEditModal(false);
    };

    return (
        <>
            <Modal
                title="Edit Guest Lecture"
                open={isEditModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Add Lecture"
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { textAlign: "center", width: "10vw", height: "6vh", backgroundColor: "#FFE60A", fontSize: "1.1vw", borderRadius: "0.3vw", fontWeight: "500", color: "black" } }}
                centered
                closeIcon={<CloseOutlined style={{ color: 'red' }} />}
            >
                <Form layout="vertical">
                    <Form.Item label="Speaker Name" className="custom-label" rules={[{ required: true, message: 'Please input guest name!' }]}>
                        <Input
                            placeholder="Typing..."
                            style={{ backgroundColor: "#EBF5FF" }}
                            value={speakerName}
                            onChange={(e) => setSpeakerName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Session" className="custom-label" rules={[{ required: true, message: 'Please input lecture title!' }]}>
                        <Input
                            placeholder="Guest Lecture : Basics of IntelliJ"
                            style={{ backgroundColor: "#EBF5FF" }}
                            value={session}
                            onChange={(e) => setSession(e.target.value)}
                        />
                    </Form.Item>
                    {/* <Form.Item label="Course Id" className="custom-label" rules={[{ required: true, message: 'Please input Course Id!' }]}>
                        <InputNumber 
                            placeholder="Course Id" 
                            style={{ backgroundColor: "#EBF5FF" }} 
                            value={courseId} 
                            onChange={(value) => setCourseId(value)} 
                        />
                    </Form.Item> */}
                    <Form.Item label="Select Guest type" className="custom-label" rules={[{ required: true, message: 'Please select guest type!' }]}>
                        <Radio.Group onChange={(e) => setSpeakerType(e.target.value)} value={speakerType}>
                            <Radio className='custon-radio' value="Inhouse" style={{ fontWeight: "bold" }}> Inhouse </Radio>
                            <Radio className='custon-radio' value="External" style={{ fontWeight: "bold" }}> External </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default EditGuestLecture;
