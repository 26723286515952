import React from 'react'

const SaveBtn = ({handleSave}) => {
  return (
    <div onClick={handleSave} className='save-btn'>
      Save & Exit
    </div>
  )
}

export default SaveBtn
