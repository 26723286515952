import React from "react";
import activateCourses from "../../Assets/SVG/courseslist/activateCourses.svg"
import { FaPlus } from "react-icons/fa";

import ActiveCoursesTable from "./ActiveCoursesTable";

const CoursesList = ({ selectedRole }) => {
  return (
    <div className="coursesList">
      <div className="coursesList__box">
        <div className="coursesList__box__elem1">
          <div className="coursesList__box__elem1__info">
            <img src={activateCourses} alt="activate" className="coursesList__box__elem1__info__img" />
            <span className="coursesList__box__elem1__info__span">Activate Courses</span>
          </div>
          <div className="coursesList__box__elem1__btn">
            <FaPlus size={20} color="white" />
          </div>
        </div>
        <div className="coursesList__box__elem2">
          <ActiveCoursesTable selectedRole={selectedRole} />
        </div>
      </div>
    </div>
  );
};

export default CoursesList;
