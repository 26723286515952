import React from 'react'
import FeedbackHeader from '../Components/Feedback/FeedbackHeader'
import FeedbackContainer from '../Components/Feedback/FeedbackContainer'
const Feedback = () => {
  return (
    <div className='feedback'>
      <FeedbackHeader/>
      <FeedbackContainer/>
    </div>
  )
}

export default Feedback
