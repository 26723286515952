import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Input } from 'antd';
import EditBtn from '../Buttons/EditBtn'

import { useDispatch, useSelector } from 'react-redux';
import { addEditedCourse } from '../../features/Courses/Courses';


const EditDescription = () => {

  const dispatch = useDispatch()
  const editedCourse = useSelector((state) => state.courses.editedCourse);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [desc, setDesc] = useState(selectedCourse.details);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);

    const formData = {
      ...editedCourse,
      details: desc
    };

    dispatch(addEditedCourse(formData))
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    const formData = {
      ...editedCourse,
      details: selectedCourse.details
    };

    setDesc(selectedCourse.details)
    dispatch(addEditedCourse(formData))

  };

  const handleInputChange = (e) => {
    setDesc(e.target.value);
  };

  return (

    <>
      <Button className='EditDescBtn' onClick={showModal}>
        <EditBtn />
        <span>Edit</span>
      </Button>
      <Modal
        styles={{ height: "20rem", padding: "1rem", marginLeft: "-1rem" }}
        title="Edit Description"
        open={isModalOpen}
        onOk={handleOk}
        okText="Save Changes"
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { textAlign: "center", width: "10vw", height: "6vh", backgroundColor: "#FFE60A", fontSize: "1.1vw", borderRadius: "0.3vw", fontWeight: "500", color: "black", marginTop: "0.5rem" } }}
        closeIcon={<CloseOutlined style={{ color: 'red' }} />}

        width={"70rem"}>
        <Input.TextArea style={{ backgroundColor: "#EBF5FF" }} autoSize={{ minRows: 2, maxRows: 8 }} value={desc} onChange={handleInputChange} />
      </Modal>
    </>

  );
};

export default EditDescription;