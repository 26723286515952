import React from 'react'
import { CloseOutlined  } from '@ant-design/icons';
const CloseDis = ({handleDelete}) => {
  return (
    <div className='close-dis' onClick={handleDelete}>
      <CloseOutlined style={{ color: 'red' }}/>
    </div>
  )
}

export default CloseDis
