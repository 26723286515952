import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Select, message } from "antd";
import educationIcon from "../../Assets/icons/fluent-mdl2_education.png";
import feedbackIcon from "../../Assets/icons/fluent_person-feedback-48-regular.png";
import { useDispatch, useSelector } from 'react-redux';
import { hostname } from "../../data";
import tick from "../../Assets/icons/mdi_tick.png";
import sessionIcon from "../../Assets/icons/sessions.png";

// Ratings
import poor from "../../Assets/icons/Poor.svg";
import good from "../../Assets/icons/Good.svg";
import needsImprovement from "../../Assets/icons/Needs_Improvement.svg";
import excellent from "../../Assets/icons/Excellent.svg";
import satisfactory from "../../Assets/icons/Satisfactory.svg";

const { Option } = Select;

const FeedbackModal = ({ visible, onCancel }) => {
    const individualCourseDetails = useSelector(state => state.participants.individualCourseDetails);
    const dispatch = useDispatch();

    const reactions = [
        { emoji: poor, rating: 1, comment: "Poor" },
        { emoji: needsImprovement, rating: 2, comment: "Needs Improvement" },
        { emoji: satisfactory, rating: 3, comment: "Satisfactory" },
        { emoji: good, rating: 4, comment: "Good" },
        { emoji: excellent, rating: 5, comment: "Excellent" },
    ];

    const [selectedRating, setSelectedRating] = useState(null);
    const [comment, setComment] = useState("");
    const [step, setStep] = useState(1);
    const [sessionFeedbackData, setSessionFeedbackData] = useState([]);
    const [validationMessage, setValidationMessage] = useState("");

    useEffect(() => {
        if (!individualCourseDetails) {
            message.error("Course details not found.");
            onCancel();
        }
    }, [individualCourseDetails, onCancel]);

    const handleRatingChange = value => {
        setSelectedRating(value);
    };
    

    const handleNext = () => {
        if (step === 1) {
            if (selectedRating === null || comment.trim() === "") {
                setValidationMessage("Please provide a rating and comments before proceeding.");
            } else {
                setValidationMessage("");
                setStep(2);
            }
        } else if (step === 2) {
            const allSessionsRated = individualCourseDetails.sessions.every(session =>
                sessionFeedbackData.some(feedback => feedback.session_id === session.id && feedback.rating)
            );

            if (!allSessionsRated) {
                setValidationMessage("Please provide feedback for all sessions before confirming.");
            } else {
                setValidationMessage("");

                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const token = localStorage.getItem("accessToken");
                myHeaders.append("Authorization", `Bearer ${token}`);

                const raw = JSON.stringify({
                    course_id: individualCourseDetails.id,
                    participant_id: individualCourseDetails.id,
                    rating: selectedRating,
                    comments: comment,
                    session: sessionFeedbackData
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                fetch(`${hostname}api/participant/participant-feedback/`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "Participant has already provided feedback for this course.") {
                            message.warning(result.message); // Show warning message
                        } else if (result.message === "Participants matching query does not exist.") {
                            message.error("Participant matching query does not exist."); // Show error message
                        } else {
                            console.log("Feedback submitted successfully:", result);
                            message.success("Feedback submitted successfully"); // Show success message
                            onCancel();
                        }
                    })
                    .catch(error => console.error("Error submitting feedback:", error));

                setSelectedRating(null);
                setComment("");
                setStep(1); // Reset to step 1
            }
        }
    };

    const handleBack = () => {
        setStep(1); // Go back to step 1
    };

    const handleSessionValueChange = (id, value) => {
        setSessionFeedbackData(prevFeedbackData => {
            const existingFeedbackIndex = prevFeedbackData.findIndex(feedback => feedback.session_id === id);

            if (existingFeedbackIndex !== -1) {
                const updatedFeedbackData = [...prevFeedbackData];
                updatedFeedbackData[existingFeedbackIndex] = {
                    ...updatedFeedbackData[existingFeedbackIndex],
                    rating: value
                };
                return updatedFeedbackData;
            } else {
                return [
                    ...prevFeedbackData,
                    {
                        session_id: id,
                        rating: value,
                        comments: "good"
                    }
                ];
            }
        });
    };

    return (
        <Modal
            title={<span className="feedback_person_icon"><img src={feedbackIcon} alt="Education" style={{ marginRight: "10px" }} /> Course Feedback</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                step === 1 ? <Button key="cancel" onClick={onCancel}>Cancel</Button> : <Button key="back" onClick={handleBack}>Back</Button>,
                <Button key="next" type="primary" onClick={handleNext}>{step === 1 ? 'Next' : 'Confirm'}</Button>
            ]}
        >
            {step === 1 && (
                <div>
                    <div className='course_details_container'>
                        <h6>Please give your valuable feedback</h6>
                    </div>

                    <div className="enrolled_courses_info_heading" style={{ width: "100%", margin: "20px auto" }}>
                        <div className="enrolled_courses_info_heading_left">
                            <img src={educationIcon} alt="Education" style={{ marginRight: "10px" }} />
                            <p>{individualCourseDetails.name}</p>
                        </div>
                    </div>

                    <div className="feedback-reactions" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "20px" }}>
                        {reactions.map(reaction => (
                            <div key={reaction.rating} style={{ marginRight: "10px", position: "relative" }}>
                                <img
                                    src={reaction.emoji}
                                    alt={reaction.comment}
                                    onClick={() => handleRatingChange(reaction.rating)}
                                    style={{ cursor: "pointer", width: "80px" }}
                                />
                                {selectedRating === reaction.rating && (
                                    <img src={tick} alt="Selected" className="selected_tick_feedback" style={{ position: "absolute", top: 0, right: 0 }} />
                                )}
                            </div>
                        ))}
                    </div>

                    <div className="feedback-comments">
                        <Input.TextArea
                            placeholder="Add Comments.."
                            rows={4}
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            style={{ background: "#EBF5FF" }}
                        />
                    </div>

                    {validationMessage && <div style={{ color: "red", marginTop: "10px" }}>{validationMessage}</div>}
                </div>
            )}

            {step === 2 && (
                <div>
                    <div className='course_details_container'>
                        <h6>Please give feedback of all the guest lectures conducted.</h6>
                    </div>

                    <div className="enrolled_courses_info_heading" style={{ width: "100%", margin: "20px auto" }}>
                        <div className="enrolled_courses_info_heading_left">
                            <img src={educationIcon} alt="Education" style={{ marginRight: "10px" }} />
                            <p>{individualCourseDetails.name}</p>
                        </div>
                    </div>
                    <div style={{ width: "100%" }}>
                        <hr style={{ borderTop: '1px solid #B4B4B4', margin: "20px 0" }} />
                    </div>

                    <div className="feedback-sessions">
                        {individualCourseDetails.sessions.map((session, index) => (
                            <div key={index} className="feedback-sessions-cards">
                                <div className="feedback-sessions-cards-item1">
                                    <div className="feedback-sessions-cards-subitem">
                                        <img src={sessionIcon} alt="Education" style={{ marginRight: "10px" }} />
                                        <p>{session.session}</p>
                                    </div>
                                    <div style={{ justifyContent: "flex-start" }} className="enrolled_courses_info_heading_left">
                                        <p>{session.speaker_name}</p>
                                    </div>
                                </div>

                                <Select
                                    placeholder="Choose Feedback"
                                    style={{ width: "30%" }}
                                    onChange={value => handleSessionValueChange(session.id, value)}
                                    value={sessionFeedbackData.find(feedback => feedback.session_id === session.id)?.rating}
                                    dropdownStyle={{ width: "200px" }}
                                >
                                    {reactions.map(reaction => (
                                        <Option key={reaction.rating} value={reaction.rating}>
                                            {reaction.comment}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        ))}
                    </div>

                    {validationMessage && <div style={{ color: "red", marginTop: "10px" }}>{validationMessage}</div>}
                </div>
            )}
        </Modal>
    );
};

export default FeedbackModal;
