import React, { useEffect, useState } from 'react';
import { DownOutlined } from "@ant-design/icons";
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Content from './Modal/Content';
import { addSelectedCourse } from "../../features/Feedback/Feedback";
import { addCourse } from '../../features/Feedback/Feedback';
import { addParticipants, addSelectedCourseP } from '../../features/Courses/Participants';
import { makeRequest } from '../../api/api';
import { Input, Dropdown, Space, Table, Tag, Button, Menu } from "antd";
import CheckTokenExpiration from '../TokenExpiryCheck/CheckTokenExpiration';

const ChooseCourseModal = ({ type, visible, setModalVisible }) => {
    const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState(0);
    const [userSearchInput, setUserSearchInput] = useState("");

    const selectedCourseP = useSelector(state => state.participants.selectedCourseP)

    const { Search } = Input;

    const coursesData = useSelector(state => state.feedback.coursesData);
    const selectedCourse = useSelector(state => state.feedback.selectedCourse);
    const dispatch = useDispatch();

    console.log("selectedCourse: ", selectedCourse)

    const handleCancel = () => {
        dispatch(addSelectedCourse({}));
        setModalVisible(false);
    };

    const handleSelection = (course) => {
        if (type === "feedback") {
            dispatch(addSelectedCourse(course));
        }
        if (type === "participants") {
            dispatch(addSelectedCourseP(course))
        }
        setModalVisible(false);
    };


    const dropDownItems = [
        { key: 1, title: "Newest First" },
        { key: 2, title: "Oldest First" },
        { key: 0, title: "Clear" },
    ];

    const handleItemClick = (item) => {
        setCurrentSelectedDropdown(item.key);
    };

    const pageSize = 100;
    const fetchCourses = async () => {
        try {
            const url = apiBuilder();
            const data = await makeRequest('get', url);
            const response = await data.json();
            if (data.ok) {
                dispatch(addCourse(response));
            } else {
                dispatch(addCourse([]));
            }
        } catch (error) {
            console.log('Error: ', error);
            dispatch(addCourse([]));
        }
    };

    const apiBuilder = () => {
        <CheckTokenExpiration />
        let url = `/api/courses/fetch-courses/?page_size=${pageSize}`;

        // Newest First
        if (currentSelectedDropdown === 1) {
            url += "&sort_by=desc";
        }
        // Oldest First
        if (currentSelectedDropdown === 2) {
            url += "&sort_by=asc";
        }

        if (userSearchInput.length > 2) {
            if (currentSelectedDropdown === 0) {
                url += `&search=${userSearchInput}`;
            } else {
                url += `&search=${userSearchInput}`;
            }
        }

        return url;
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    useEffect(() => {
        if (currentSelectedDropdown !== 0) {
            fetchCourses();
        }
    }, [currentSelectedDropdown]);


    const menu = (
        <Menu>
            {dropDownItems.map((item) => (
                <Menu.Item key={item.key} onClick={() => handleItemClick(item)}>
                    {item.title}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Modal
            title="Choose Course"
            open={visible}
            footer={null}
            onCancel={handleCancel}
            width="60vw"
        >
            <div className='choose-course-modal'>
                <div className="choose-course-modal__heading">
                    <div className="choose-course-modal__heading__searchbar">
                        <Search
                            placeholder="search here..."
                            onChange={(e) => setUserSearchInput(e.target.value)}
                            style={{ width: "100%", height: "100%" }}
                            className="choose-course-modal__heading__searchbar__search"
                        />
                    </div>
                    <div className="choose-course-modal__heading__dropdown">
                        <Dropdown
                            // menu={{
                            //     items,
                            // }}
                            // trigger={["click"]}
                            overlay={menu}
                            trigger={["click"]}
                        >
                            <Space>
                                {currentSelectedDropdown === 0
                                    ? "Sort"
                                    : currentSelectedDropdown === 1
                                        ? "Newest First"
                                        : "Oldest First"}
                                <DownOutlined />
                            </Space>
                        </Dropdown>
                    </div>
                </div>
                {coursesData.map((course) => (
                    <div key={course.id} className='choose-course-modal__items' onClick={() => handleSelection(course)}>
                        <Content data={course} />
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default ChooseCourseModal;
