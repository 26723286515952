import React from "react";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Checkbox, message, Select } from "antd";
import { ConfigProvider } from "antd";
import { useState } from "react";
// import VerifyOTP from "../../../Components/Modals/VerifyOTP";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const SignUpContainer = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState();
    const [FullName, setFullName] = useState();
    const [PlaceOfPosting, setPlaceOfPosting] = useState();
    const [Cadre, setCadre] = useState();
    const [ID, setID] = useState();
    const [Number, setNumber] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [remember, setRemember] = useState(false);
    const navigate = useNavigate();

    const registerUser = async () => {
        const Data = {
            emp_id: ID,
            name: FullName,
            email: email,
            password: password,
            contact_number: Number,
            cadre: Cadre,
            place_of_posting: PlaceOfPosting,
        };
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify(Data);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        fetch(`${process.env.REACT_APP_BE_URL}/api/participant/register-participant/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                message.success("Submitted successfully");
                navigate("/login/participants");
            })
            .catch((error) =>{ 
                console.error(error);
                message.error("Submission failed. Please try again.");
            });    
    };

    const onFinish = async (values) => {
        if (password !== confirmPassword) {
            validateConfirmPassword();
        } else {
            if (step === 1) {
                setStep(2);
            } else if (step === 2) {
                await registerUser();
                // setStep(3);
                console.log("verify otp stepsss");
            }
        }
    };

    const validateUsername = (_, value) => {
        if (value && value.length < 3) {
            return Promise.reject(new Error("Enter valid username."));
        }
        if (value && /^\d+$/.test(value)) {
            return Promise.reject(new Error("Username must contain characters."));
        }
        return Promise.resolve();
    };

    const validatePassword = (_, value) => {
        if (value && value.length < 8) {
            return Promise.reject(new Error("Password should be at least 8 characters long."));
        }
        if (value && !/\d/.test(value)) {
            return Promise.reject(new Error("Password should include at least one number."));
        }
        if (value && !/[^a-zA-Z0-9]/.test(value)) {
            return Promise.reject(new Error("Password should include at least one special character."));
        }
        if (value && /\s/.test(value)) {
            return Promise.reject(new Error("Password should not include spaces."));
        }
        return Promise.resolve();
    };

    const validateConfirmPassword = () => {
        if (confirmPassword && password !== confirmPassword) {
            return Promise.reject(new Error("The passwords do not match!"));
        }
        return Promise.resolve();
    };

    const validatePlaceOfPosting = (_, value) => {
        if (value && /^\d+$/.test(value)) {
            return Promise.reject(new Error("Place of Posting must contain characters."));
        }
        return Promise.resolve();
    };

    // Static Data
    const cadreOfParticipantsArr = [
        "PR CCIT",
        "CCIT",
        "PR CIT",
        "CIT",
        // "MTS",
        // "Addl/Joint CIT",
        // "DC/ACIT",
        // "ITO",
        // "AO",
        // "ITI",
        // "PS",
        // "Sr TA",
        // "TA",
        // "Steno",
        // "Notice Server",
    ];

    const handleSelectChange = (value) => {
        setCadre(value); // set state variable
    };

    const { Option } = Select;

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: "#FFE60A",
                        defaultBg: "#FFE60A",
                        colorPrimaryHover: "#dfcb21",
                    },
                },
            }}
        >
            {/* {step === 3 && <VerifyOTP phoneNumber={Number} />} */}

            {step === 2 && (
                <Button
                    type="primary"
                    className="login-form-button"
                    style={{
                        color: "white",
                        position: "absolute",
                        top: "12rem",
                        right: "63rem",
                        backgroundColor: "#3185FC",
                    }}
                    onClick={() => {
                        setStep(1);
                    }}
                >
                    Back
                </Button>
            )}
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                size="large"
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
            >
                {step === 1 ? (
                    <>
                        <Form.Item
                            name="Email"
                            label="Email"
                            rules={[
                                { required: true, message: "Please input your Email !" },
                                { type: "email" },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="Full Name"
                            label="Full Name"
                            rules={[
                                { required: true, message: "Please input your fullName !" },
                                { validator: validateUsername },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Full Name"
                                value={FullName}
                                onChange={(e) => {
                                    setFullName(e.target.value);
                                }}
                            />
                        </Form.Item>

                        {/* Place of Posting */}
                        <Form.Item
                            name="Place of Posting"
                            label="Place of Posting"
                            rules={[
                                { required: true, message: "Please input Place of Posting!" },
                                { validator: validatePlaceOfPosting },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Place Of Posting"
                                value={PlaceOfPosting}
                                onChange={(e) => {
                                    setPlaceOfPosting(e.target.value);
                                }}
                            />
                        </Form.Item>

                        {/* Cadre of Participants */}
                        <Form.Item
                            name="cadreOfParticipants"
                            label="Cadre"
                            rules={[{ required: true, message: "Please choose an option!" }]}
                        >
                            <Select placeholder="Pick an option" allowClear onChange={handleSelectChange}>
                                {cadreOfParticipantsArr.map((elem, i) => (
                                    <Option key={i} value={elem}>
                                        {elem}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </>
                ) : (
                    <>
                        <Form.Item
                            name="Employee ID"
                            label="Employee ID"
                            rules={[
                                { required: true, message: "Please input Employee Id !" },
                                { pattern: /^[0-9]{1,6}$/, message: "Employee ID must be a number with a maximum of 6 digits!" },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Employee ID"
                                maxLength={6}
                                value={ID}
                                onChange={(e) => {
                                    setID(e.target.value);
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="Contact Number"
                            label="Enter Mobile Number"
                            rules={[
                                { required: true, message: "Please input Contact Number!" },
                                { pattern: /^[0-9]{10}$/, message: "Contact Number should be 10 digits!" },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Enter Mobile Number"
                                value={Number}
                                onChange={(e) => {
                                    setNumber(e.target.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="Password"
                            label="Enter Password"
                            rules={[
                                { required: true, message: "Please input password!" },
                                { validator: validatePassword },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Enter Password"
                                value={password}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="Confirm Password"
                            label="Confirm Password"
                            rules={[
                                { required: true, message: "Please input confirm Password!" },
                                { validator: validateConfirmPassword },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                placeholder="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    {step === 1 ? (
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            block
                            style={{ color: "black" }}
                        >
                            Continue
                        </Button>
                    ) : (
                        <>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                block
                                style={{ color: "black", marginBottom: "1rem" }}
                            >
                                Submit
                            </Button>
                        </>
                    )}
                </Form.Item>
            </Form>
        </ConfigProvider>
    );
};

export default SignUpContainer;
