import React from 'react'
import { Button,message } from 'antd';

import ExitWOSaveBtn from '../Buttons/ExitWOSaveBtn';
import SaveBtn from '../Buttons/SaveBtn';

import AC from "../../Assets/icons/AC.png"
import { makeRequest } from '../../api/api';

import { useSelector, useDispatch } from 'react-redux';
import AddGuestLecture from '../Modals/AddGuestLecture';
import { resetStates } from '../../features/Courses/Courses';
import CheckTokenExpiration from '../TokenExpiryCheck/CheckTokenExpiration';
const EditButtons = ({ showModal, guestLectureId, setIsEditCourseActive, editFormData, courseKey, isAborted  }) => {

    const dispatch = useDispatch();

    const selectedCourse = useSelector((state) => state.courses.selectedCourse);
    const editedCourse = useSelector((state) => state.courses.editedCourse);
    const newSession = useSelector((state) => state.courses.newSession)
    const deletedSession = useSelector((state) => state.courses.deletedSessionId)


    const editSession = useSelector((state) => state.courses.editSession)

    const handleEditSession = async () => {
        try {
            const myHeaders = new Headers();
            let token = "";

            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }

            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify(editSession.data);

            const requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            <CheckTokenExpiration />
            const url = `${process.env.REACT_APP_BE_URL}/api/courses/edit_session_details/${editSession.id}/`

            const data = await fetch(
                url,
                requestOptions
            )

            const response = await data.text()

        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const handleAddSession = async () => {
        try {
            const myHeaders = new Headers();
            let token = "";

            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }

            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify(newSession);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            <CheckTokenExpiration />
            const url = `${process.env.REACT_APP_BE_URL}/api/courses/create-session-details/`

            const data = await fetch(
                url,
                requestOptions
            )

            const response = await data.text()

        } catch (error) {
            console.log("Error: ", error)
        }
    }

    const handleGuestDelete = async () => {
        try {
            const myHeaders = new Headers();
            let token = "";

            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }

            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "guest_id": deletedSession.id,
                "course_id": deletedSession.course_id
            });

            const requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            <CheckTokenExpiration />
            const url = `${process.env.REACT_APP_BE_URL}/api/courses/delete_session_detail/`

            const data = await fetch(
                url,
                requestOptions
            )

            const response = data.text()



        } catch (error) {
            console.log("Error: ", error)
        }
    }


    const handleSave = async () => {
        if (isAborted) {
            message.warning('The course is aborted and cannot be saved.');
            return;
        }

        console.log("editedCourse: ", editedCourse);
        try {
            const myHeaders = new Headers();
            let token = "";

            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }

            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");

            const editedCourseRequestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: JSON.stringify(editedCourse),
                redirect: "follow"
            };

            <CheckTokenExpiration />;
            const editCourseUrl = `${process.env.REACT_APP_BE_URL}/api/courses/edit-course/${selectedCourse.id}/`;
            await fetch(editCourseUrl, editedCourseRequestOptions);

            const promises = [];

            // Handle new sessions in a single request
            if (newSession.length > 0) {
                const newSessionsPayload = {
                    course_id: selectedCourse.id,
                    sessions: newSession.map(session => ({
                        speaker_name: session.speaker_name,
                        session: session.session,
                        competencies: session.competencies,
                        duration: session.duration
                    }))
                };

                console.log("New sessions payload:", newSessionsPayload); // Debug log

                const newSessionRequestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(newSessionsPayload),
                    redirect: "follow"
                };

                <CheckTokenExpiration />;
                const addSessionUrl = `${process.env.REACT_APP_BE_URL}/api/courses/create-session-details/`;
                promises.push(fetch(addSessionUrl, newSessionRequestOptions));
            }

            // Handle edited sessions individually
            if (editSession.length > 0) {
                editSession.forEach(session => {
                    const editSessionRequestOptions = {
                        method: "PATCH",
                        headers: myHeaders,
                        body: JSON.stringify(session.data),
                        redirect: "follow"
                    };

                    <CheckTokenExpiration />;
                    const editSessionUrl = `${process.env.REACT_APP_BE_URL}/api/courses/edit_session_details/${session.id}/`;
                    promises.push(fetch(editSessionUrl, editSessionRequestOptions));
                });
            }

            // Handle deleted sessions individually
            if (deletedSession.length > 0) {
                deletedSession.forEach(session => {
                    const deleteSessionRequestOptions = {
                        method: "DELETE",
                        headers: myHeaders,
                        body: JSON.stringify({
                            "guest_id": session.id,
                            "course_id": session.course_id
                        }),
                        redirect: "follow"
                    };

                    <CheckTokenExpiration />;
                    const deleteSessionUrl = `${process.env.REACT_APP_BE_URL}/api/courses/delete_session_detail/`;
                    promises.push(fetch(deleteSessionUrl, deleteSessionRequestOptions));
                });
            }

            await Promise.all(promises);

            dispatch(resetStates());

            setIsEditCourseActive(false);

        } catch (error) {
            console.log("Error: ", error);

            // Log error response for debugging
            if (error.response) {
                console.log("Error response data:", error.response.data);
            }
        }
    };



    return (
        <div className='editbuttons'>
        <div className='editbuttons__addDetails'>
            <Button
                onClick={showModal}
                style={{ background: "#EBF5FF", color: "#3185FC", padding: "10px", height: "6vh" }}
                type="primary"
                icon={<img style={{ width: "15px" }} src={AC} alt="AC Icon" />}
                disabled={isAborted}
            >
                Add Competency
            </Button>

            <AddGuestLecture />
        </div>

        <div className='editbuttons__savebuttons'>
            <ExitWOSaveBtn setIsEditCourseActive={setIsEditCourseActive} />
            <SaveBtn handleSave={handleSave} disabled={isAborted} />
        </div>
    </div>
);
};


export default EditButtons
