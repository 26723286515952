import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";


const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    coursesData: [],
    selectedCourse: [],
    compObj:{},
    editedCourse: [],
    newSession: [],
    editSession: [],
    deletedSessionId: []
    },
  reducers: {
    addCourse: (state, action) => {
      state.coursesData = action.payload.data;
    },
    addSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
    addEditedCourse: (state, action) => {
      state.editedCourse = action.payload;
    },
    addCompObj: (state, action) => {
      state.compObj = action.payload;
    },
    addNewSession: (state, action) => {
      state.newSession.push(action.payload);
    },
    addEditSession: (state, action) => {
      state.editSession.push(action.payload);
    },
    deleteSession: (state, action) => {
      state.deletedSessionId.push(action.payload);
    },
    resetStates: (state) => {
      state.newSession = [];
      state.editSession = [];
      state.deletedSessionId = [];
    }
  },

});

export const { addCourse, addSelectedCourse, addEditedCourse, addCompObj, addNewSession, addEditSession, deleteSession, resetStates } = coursesSlice.actions;
export default coursesSlice.reducer;
