import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CheckTokenExpiration from "../../Components/TokenExpiryCheck/CheckTokenExpiration";
// import {bearerToken as userToken} from "../../Utility/Token"

// let userToken = ""
// if (localStorage.getItem("accessToken") != null) {
//     userToken = localStorage.getItem("accessToken")
// }



export const manHourAnalysisAPI = createAsyncThunk('manhouranalysis/fetch', async (input_data) => {
    let userToken = ""
    if (localStorage.getItem("accessToken") != null) {
        userToken = localStorage.getItem("accessToken")
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `Bearer ${userToken}` },
        redirect: "follow"
    };
    <CheckTokenExpiration />
    const res = await fetch(`${process.env.REACT_APP_BE_URL}/api/participant/man-hours-analysis/?year=${input_data.year}`, requestOptions);
    const data = await res.json();
    return data;
});


export const totalManHoursAPI = createAsyncThunk('totalmanhouranalysis/fetch', async (input_data) => {
    let userToken = ""
    if (localStorage.getItem("accessToken") != null) {
        userToken = localStorage.getItem("accessToken")
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `Bearer ${userToken}` },
        redirect: "follow"
    };
    <CheckTokenExpiration />
    const res = await fetch(`${process.env.REACT_APP_BE_URL}/api/participant/participant_man_hrs_analysis/`, requestOptions);
    const data = await res.json();
    return data;
});

export const curriculumAnalysisAPI = createAsyncThunk('curriculumanalysis/fetch', async (input_data) => {
    let userToken = ""
    if (localStorage.getItem("accessToken") != null) {
        userToken = localStorage.getItem("accessToken")
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `Bearer ${userToken}` },
        redirect: "follow"
    };
    <CheckTokenExpiration />
    const res = await fetch(`${process.env.REACT_APP_BE_URL}/api/participant/participant_curriculum_analysis/`, requestOptions);
    const data = await res.json();
    return data;
});



const participantsHomeSlice = createSlice({
    name: "participantshome",
    initialState: {
        // Man Hour Analysis
        manHourAnalysisAPIStatus: 'idle',
        manHourAnalysisXAxisData: [],
        manHourAnalysisYAxisData: [],


        // Total Man Hour Analysis
        totalManHrsAnalysisAPIStatus: 'idle',
        totalManHrs: "",
        totalManHrsCurrentMonth: "",
        totalManHrsCurrentMonthPercentage: -1,

        // Curriculum Analysis
        curriculumAnalysisAPIStatus: 'idle',
        curriculumAnalyticsData: {},
        curriculumAnalyticsContribution: -1,
    },
    extraReducers: (builder) => {
        builder
            // Man Hour Analysis
            .addCase(manHourAnalysisAPI.pending, (state) => {
                state.manHourAnalysisAPIStatus = 'pending';
                state.manHourAnalysisXAxisData = [];
                state.manHourAnalysisYAxisData = [];
            })
            .addCase(manHourAnalysisAPI.fulfilled, (state, action) => {
                state.manHourAnalysisAPIStatus = 'fulfilled';
                const xAxis = []
                const yAxis = []
                action.payload.forEach((elem) => {
                    xAxis.push(elem["month"])
                    yAxis.push(elem["total_hours"])
                })
                state.manHourAnalysisXAxisData = xAxis
                state.manHourAnalysisYAxisData = yAxis

            })
            .addCase(manHourAnalysisAPI.rejected, (state) => {
                state.manHourAnalysisAPIStatus = 'rejected';
                state.manHourAnalysisXAxisData = [];
                state.manHourAnalysisYAxisData = [];
            })

            // Total Man Hour Analysis
            .addCase(totalManHoursAPI.pending, (state) => {
                state.totalManHrsAnalysisAPIStatus = 'pending';
                state.totalManHrs = "";
                state.totalManHrsCurrentMonth = "";
                state.totalManHrsCurrentMonthPercentage = -1;
            })
            .addCase(totalManHoursAPI.fulfilled, (state, action) => {
                state.totalManHrsAnalysisAPIStatus = 'fulfilled';
                if (action.payload["total_men_hours"]) {
                    state.totalManHrs = action.payload["total_men_hours"];
                }
                if (action.payload["total_men_hours_current_month"]) {
                    state.totalManHrsCurrentMonth = action.payload["total_men_hours_current_month"];
                }
                if (action.payload["total_men_hours_current_month_percentage"]) {
                    state.totalManHrsCurrentMonthPercentage = action.payload["total_men_hours_current_month_percentage"];
                }
            })
            .addCase(totalManHoursAPI.rejected, (state) => {
                state.totalManHrsAnalysisAPIStatus = 'rejected';
                state.totalManHrs = "";
                state.totalManHrsCurrentMonth = "";
                state.totalManHrsCurrentMonthPercentage = -1;
            })

            // Curriculum Analysis
            .addCase(curriculumAnalysisAPI.pending, (state) => {
                state.totalManHrsAnalysisAPIStatus = 'pending';
                state.curriculumAnalyticsData = {};
                state.curriculumAnalyticsContribution = -1
            })
            .addCase(curriculumAnalysisAPI.fulfilled, (state, action) => {
                state.totalManHrsAnalysisAPIStatus = 'fulfilled';
                state.curriculumAnalyticsData = action.payload
                if (action.payload.hasOwnProperty("total_contributions")) {
                    state.curriculumAnalyticsContribution = action.payload["total_contributions"]
                }
            })
            .addCase(curriculumAnalysisAPI.rejected, (state) => {
                state.totalManHrsAnalysisAPIStatus = 'rejected';
                state.curriculumAnalyticsData = {}
                state.curriculumAnalyticsContribution = -1
            })
    }
})

export default participantsHomeSlice.reducer;