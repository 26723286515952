import React from 'react'
import { Input, Button } from 'antd';
import EditBtn from '../Buttons/EditBtn';
import SetPasswordModal from '../Modals/ViewProfile/SetPasswordModal';

const ViewProfileFooter = ({
  role,
  id,
  mobileNo,
  email,
  participantData
}) => {
  return (
    <div className="view-profile-footer">
      {
        role === "Participant" && (
          
          <div className='ViewProfile_grid-container'>
            {
              
              participantData?.map((data, index) => (
                <div key={index} style={{ display: "flex", width: "100%", justifyContent: 'space-between', justifyItems: 'center', padding: "10px", marginTop: "15px", backgroundColor: "#FFFFFF", paddingLeft: "15px", paddingRight: "15px", borderRadius: "5px" }}>
                  <div>
                    <h3 style={{ fontSize: "25px", fontWeight: "500" }}>{data?.value || "heyoo"}</h3>
                    <p>{data?.title || "Radha"}</p>

                  </div>
                  <div style={{ backgroundColor: "#3185FC", padding: "7px", borderRadius: "5px" }}>
                    <data.icon />
                  </div>
                </div>
              )

              )
            }

          </div>
        )
      }
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ width: "50%" }}>
          <label style={{ fontSize: "13px" }} htmlFor='EmployeeId'>Employee Id</label>
          <Input id='EmployeeId' style={{ backgroundColor: "white", color: "black" }} disabled value={id} />
        </div>
        <div style={{ width: "50%" }}>
          <label style={{ fontSize: "13px" }} htmlFor=' mobileNo'>Mobile No</label>
          <div className='edit-button'>
            <Input id=' mobileNo' style={{ backgroundColor: "white", color: "black" }} disabled value={mobileNo} />
            {/* <Button className='EditDescBtn'>
              <EditBtn />
              <span>Edit</span>
            </Button> */}
          </div>
        </div>
        
        </div>
      <div style={{ width: "100%" }}>
        <label style={{ fontSize: "13px" }} htmlFor=' email'> Email </label>
        <div className="edit-button" >
          <Input id='email' style={{ backgroundColor: "white", color: "black" }} disabled value={email} />
          {/* <Button className='EditDescBtn'>
            <EditBtn />
            <span>Edit</span>
          </Button> */}
        </div>
      </div>
      
      {/* <Button className='EditDescBtn'> */}
        {/* <EditBtn /> */}
        
        <SetPasswordModal/>
      {/* </Button> */}
      </div>
  )
}

export default ViewProfileFooter
