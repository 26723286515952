import React, {useState} from 'react'
// import EditBtn from '../Buttons/EditBtn'
import { FaCircleCheck } from "react-icons/fa6";
import EditDescription from "../Modals/EditDescription"
import EditCoursesOtherDetail from './EditCoursesOtherDetail';



import { useSelector } from 'react-redux';

const EditCoursesDesc = () => {
  const editedCourse = useSelector((state) => state.courses.editedCourse);
  return (
    <div className='courses-desc'>

      <div className='courses-desc__header'>
        Course ID:
        <div className='courses-desc__header__course'>
          <span>CID#_{editedCourse.id}</span>
          <div className={`courses-desc__header__course__status ${editedCourse.status}`}>{editedCourse.status}</div>
        </div>
      </div>

      <div className='courses-desc__body'>
        Description:
        <div className='courses-desc__body__desc'>{editedCourse.details}</div>
          <EditDescription /> 
      </div>

      <EditCoursesOtherDetail />

    </div>
  )
}

export default EditCoursesDesc
