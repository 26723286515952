import React, { useEffect, useState } from 'react';
import { makeRequest } from '../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import { addEnrollableCourses } from '../../features/Courses/Participants';
import { addIndividualCoursedetails } from "../../features/Courses/Participants";
import { Button, Input } from 'antd';
import { ReactComponent as EnrollableCourses } from "../../Assets/SVG/enrollablecourses.svg";
import { useNavigate } from 'react-router';
import CheckTokenExpiration from '../TokenExpiryCheck/CheckTokenExpiration';

const EnrollableCoursesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedInstitute = useSelector(state => state.participants.selectedInstitute);
  const selectedLocation = useSelector(state => state.participants.selectedLocation);
  const enrollableCourses = useSelector(state => state.participants.enrollableCourses);
  const userTypeFromStorage = localStorage.getItem('userRole');
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query

  const fetchEnrollableCoursesList = async () => {
    let url;
    try {
      if (selectedInstitute === "MSTU" || selectedInstitute === "RC") {
        <CheckTokenExpiration />
        url = `/api/courses/get-institute-courses/?institute=${selectedInstitute}&location=${selectedLocation}`;
        if (selectedLocation !== null) {
          const data = await makeRequest("get", url);
          const response = await data.json();
          dispatch(addEnrollableCourses(response));
          setEnrolledCourses(response.filter(course => course.enrolled === true));
        }
      } else if (selectedInstitute === "NADT" || selectedInstitute === "others") {
        <CheckTokenExpiration />
        url = `/api/courses/get-institute-courses/?institute=${selectedInstitute}`;
        const data = await makeRequest("get", url);
        const response = await data.json();
        dispatch(addEnrollableCourses(response));
        setEnrolledCourses(response.filter(course => course.enrolled === true));
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchEnrollableCoursesList();
  }, [selectedInstitute, selectedLocation]);

  const handleEnroll = (record) => {
    console.log('Enroll action for:', record);
    dispatch(addIndividualCoursedetails(record));
    navigate(`/${userTypeFromStorage}/course-details`);
  };

  // Filter courses based on the search query
  const filteredCourses = enrollableCourses.filter(course => 
    course.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='enrollable-courses-list'>
      <div className='enrollable-courses-list__header'>
        <span>Course List</span>
        <Input
          placeholder='Search here...'
          style={{ width: 400, height: 40 }}
          value={searchQuery} // Bind searchQuery state to the input
          onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery state on input change
        />
      </div>
      <div className='enrollable-courses-list__content'>
        <div className='enrollable-courses-list__content__header'>
          <div className='enrollable-courses-list__content__header__name'>
            Name
          </div>
          <div className='enrollable-courses-list__content__header__timeline'>
            Timeline
          </div>
          <div className='enrollable-courses-list__content__header__comp'>
            Competencies
          </div>
          <div className='enrollable-courses-list__content__header__actions'>
            Actions
          </div>
        </div>
        <div className="enrollable-courses-list__container" style={{ marginTop: "2vh" }}>
          {filteredCourses.map(course => (
            <div key={course.id} className='enrollable-courses-list__course'>
              <div className='enrollable-courses-list__course-info'>
                <div className='enrollable-courses-list__course-info__name'>
                  <EnrollableCourses />
                  <span>{course.name}</span>
                </div>
                <span className='enrollable-courses-list__course-info__timeline'>{`${course.start_date} to ${course.end_date}`}</span>
                <span className='enrollable-courses-list__course-info__comp'>{course.course_competencies_count}</span>
                <div className='enrollable-courses-list__course-info__actions'>
                  {enrolledCourses.some(enrolledCourse => enrolledCourse.id === course.id) ? (
                    <Button
                      className='enrollable-courses-list__enroll-btn'
                      style={{ backgroundColor: '#2AC239' }}
                      type='primary'
                      disabled
                    >
                      Enrolled
                    </Button>
                  ) : (
                    <Button
                      className='enrollable-courses-list__enroll-btn'
                      style={{ backgroundColor: '#2AC239' }}
                      type='primary'
                      onClick={() => handleEnroll(course)}
                    >
                      {course.enrolled ? 'Enrolled' : 'View & Enroll'}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnrollableCoursesList;
