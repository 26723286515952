import React from 'react'
import Deactivate from '../Modals/Deactivate'

const DeactivateBtn = ({setIsEditCourseActive,courseId}) => {
  return (
    <div className='deactivate-btn'>
      {/* Deactivate */}
      <Deactivate setIsEditCourseActive={setIsEditCourseActive} courseId ={courseId}/>
    </div>
  )
}

export default DeactivateBtn
