import React, { useState } from 'react';
import { Modal, Input } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { addEditedCourse, addCompObj } from '../../features/Courses/Courses';
const EditCompetency = ({ id, data, isEditModal, setIsEditModal }) => {
    const dispatch = useDispatch();
    const editedCourse = useSelector((state) => state.courses.editedCourse);
    const compObj = useSelector((state) => state.courses.compObj);


    const [editedComp, setEditedComp] = useState(data);
    const handleOk = () => {
        const updatedCompObj = compObj.map(obj => {
            if (obj.id === id) {
                return { ...obj, data: editedComp };
            }
            return obj;
        });
    
        dispatch(addCompObj(updatedCompObj));

        const newDataArray = updatedCompObj.map(obj => obj.data);
        const updatedEditedCourse = { ...editedCourse, course_competencies: newDataArray };
        dispatch(addEditedCourse(updatedEditedCourse));

        setIsEditModal(false);

    };

    const handleCancel = () => {
        setIsEditModal(false);
        setEditedComp(data)
    };

    const handleInputChange = (e) => {
        setEditedComp(e.target.value);
    };

    return (
        <div>
            <Modal
                styles={{ padding: "1rem", marginLeft: "-1rem" }}
                title="Edit Competency"
                open={isEditModal}
                onOk={handleOk}
                okText="Save Changes"
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { textAlign: "center", width: "10vw", height: "6vh", backgroundColor: "#FFE60A", fontSize: "1.1vw", borderRadius: "0.3vw", fontWeight: "500", color: "black", marginTop: "0.5rem" } }}
                width={"70rem"}>
                <Input.TextArea style={{ backgroundColor: "#EBF5FF" }} value={editedComp} onChange={handleInputChange} />
            </Modal>
        </div>
    )
}

export default EditCompetency
