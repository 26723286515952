import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import educationIcon from "../../Assets/icons/fluent-mdl2_education.png";

import enrollIcon from "../../Assets/icons/enrollIcon.png";
import { useDispatch, useSelector } from 'react-redux';

import { hostname } from "../../data";
import { useNavigate } from 'react-router';

const EnrollCourseModal = ({ visible, onCancel }) => {
    const individualCourseDetails = useSelector(state => state.participants.individualCourseDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userTypeFromStorage = localStorage.getItem("userRole")
    const handleConfirm = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const token = localStorage.getItem("accessToken");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "course_id": individualCourseDetails.id
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${hostname}api/participant/enroll-course/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log("Enrolled successfully:", result);

                onCancel();
                message.success('Enrolled Successfully');
                navigate(`/${userTypeFromStorage}/courses`);
            })
            .catch((error) => {
                console.error("Error:", error);
                message.error('Failed to enroll'); // Display error message if enrollment fails
            });
    };

    return (
        <Modal
            title={<span className="enroll_person_icon"><img src={enrollIcon} alt="Education" style={{ marginRight: "10px" }} /> Enroll In Course</span>}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>Cancel</Button>,
                <Button key="confirm" type="primary" onClick={handleConfirm}>Confirm</Button>
            ]}
        >
            <div>
                <div className='course_details_container'>
                    <h6>Confirm your enrollment in the course</h6>
                </div>

                <div className="enrolled_courses_info_heading" style={{ width: "100%", margin: "20px auto" }}>
                    <div className="enrolled_courses_info_heading_left">
                        <img src={educationIcon} alt="Education" style={{ marginRight: "10px" }} />
                        <p>{individualCourseDetails.name}</p>
                    </div>
                </div>
            </div >
        </Modal>
    );
};

export default EnrollCourseModal;
