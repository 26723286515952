import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";


const participantsSlice = createSlice({
  name: 'participants',
  initialState: {
    selectedInstitute: {},
    selectedLocation: null,
    enrollableCourses: [],
    enrolledCoursesStatus: true,
    individualCourseDetails: {},
    selectedCourseP : {},
    enrolledPartcipantsList: [],
    participantView: false,
    participantDetails: {}
  },
  reducers: {
    addSelectedInstitute: (state, action) => {
      state.selectedInstitute = action.payload.data;
    },
    addSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    addEnrollableCourses: (state, action) => {
      state.enrollableCourses = action.payload.data;
    },
    addIndividualCoursedetails: (state, action) => {
      state.individualCourseDetails = action.payload;
    },
    clearFilters: (state) => {
      state.selectedInstitute = {};
      state.selectedLocation = null;
      state.enrollableCourses = [];
      state.individualCourseDetails = {};
    },
    toggleStatus: (state) => {
      console.log(state)
      state.enrolledCoursesStatus = !state.enrolledCoursesStatus;
    },
    addSelectedCourseP: (state, action) => {
      state.selectedCourseP = action.payload
    },
    addParticipants: (state, action) => {
      state.enrolledPartcipantsList = action.payload
    },
    setParticipantView: (state, action) => {
      state.participantView = action.payload
    },
    addParticipantDetails: (state, action) => {
      state.participantDetails = action.payload
    }
  },

});

export const { addSelectedInstitute, addSelectedLocation, addEnrollableCourses, addIndividualCoursedetails, clearFilters, toggleStatus, addSelectedCourseP, addParticipants, setParticipantView, addParticipantDetails } = participantsSlice.actions;

export default participantsSlice.reducer;
