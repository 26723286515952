import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Checkbox, message } from 'antd';
import { useSelector } from 'react-redux';
import { CiUser, CiEdit } from "react-icons/ci";
import { ReactComponent as CertificateIcon } from "../../Assets/SVG/icon-park-outline_certificate.svg"
import { useDispatch } from 'react-redux';
import { addParticipantDetails, addParticipants, addSelectedCourseP, setParticipantView } from '../../features/Courses/Participants';
import CheckTokenExpiration from '../TokenExpiryCheck/CheckTokenExpiration';
const EnrollParticpantsTable = () => {

    const dispatch = useDispatch()

    // selected Course
    const selectedCourseP = useSelector(state => state.participants.selectedCourseP)

    // list of participants from store
    const enrolledPartcipantsList = useSelector(state => state.participants.enrolledPartcipantsList)

    const [outstandingActive, setOutstandingActive] = useState(false)
    const [excellentActive, setExcellentActive] = useState(false)
    const [goodActive, setGoodActive] = useState(false)

    // set participants list into useState for manipulation
    const [participantsData, setParticipantsData] = useState(enrolledPartcipantsList);

    // selected participant by edit button in remark
    const [selectedParticipant, setSelectedParticipant] = useState(null);

    // selected particpants by checkbox
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);

    // raw for body of issue-certificate-api
    const [raw, setRaw] = useState(null)

    // check if we can issue certificates
    const canIssueCertificates = enrolledPartcipantsList.some(participant => participant.is_certificate_issued=== null);

    useEffect(() => {
        setParticipantsData(enrolledPartcipantsList)
    }, [enrolledPartcipantsList])

    useEffect(() => {
        fetchParticipants(selectedCourseP.id)
    }, [selectedCourseP.id])

    console.log(enrolledPartcipantsList)

    const fetchParticipants = async (id) => {
        try {
            const myHeaders = new Headers();
            let token = "";
            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }
            myHeaders.append("Authorization", `Bearer ${token}`);
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
            };
            <CheckTokenExpiration />
            const url = `${process.env.REACT_APP_BE_URL}/api/participant/fetch-participants/?page=${1}&page_size=${100}&course_id=${id}`;
            const data = await fetch(url, requestOptions);
            const response = await data.json();
            dispatch(addParticipants(response.data))
        } catch {

        }
    }

    const fetchParticipantData = async (id) => {
        try {
            const myHeaders = new Headers();
            let token = "";
            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }
            myHeaders.append("Authorization", `Bearer ${token}`);
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
            };
            <CheckTokenExpiration />

            const url = `${process.env.REACT_APP_BE_URL}/api/participant/participant-details/?participant_id=${id}`;
            const data = await fetch(url, requestOptions);
            const response = await data.json();
            dispatch(addParticipantDetails(response))
        } catch {
        }
    }

    const selectRemark = (remark) => {

        // changing color of remark modal
        if (remark === "outstanding") {
            setOutstandingActive(true)
            setGoodActive(false)
            setExcellentActive(false)
        }
        else if (remark === "excellent") {
            setOutstandingActive(false)
            setGoodActive(false)
            setExcellentActive(true)
        }
        else if (remark === "good") {
            setOutstandingActive(false)
            setGoodActive(true)
            setExcellentActive(false)
        }

        // update the list on table
        const updatedParticipantsData = participantsData.map(participant => {
            if (participant.id === selectedParticipant.id) {
                return {
                    ...participant,
                    remarks: remark
                };
            }
            return participant;
        });

        setParticipantsData(updatedParticipantsData);

        // updating the selected partiicpants (checkbox) list
        setSelectedParticipants(prevParticipants => {
            return prevParticipants.map(participant => {
                if (participant.participant === selectedParticipant.id) {
                    return {
                        ...participant,
                        feedback: remark
                    };
                }
                return participant;
            });
        });
    };

    const handleCheckboxChange = (participantId, checked) => {
        setSelectedParticipants(prevParticipants => {
            const updatedParticipants = [...prevParticipants];
            if (checked) {
                updatedParticipants.push({
                    participant: participantId,
                    feedback: participantsData.find(p => p.id === participantId)?.remarks || null,
                    is_certificate_issued: false
                });
            } else {
                const index = updatedParticipants.findIndex(participant => participant.participant === participantId);
                updatedParticipants.splice(index, 1);
            }
            setIsAnyCheckboxChecked(updatedParticipants.length > 0);
            return updatedParticipants;
        });
    };
    


    const handleEdit = (data) => {
        setIsModalVisible(true);
        setSelectedParticipant(data);
        setOutstandingActive(false);
        setGoodActive(false);
        setExcellentActive(false);
    };
    
    const handleSubmit = () => {
        setIsModalVisible(false);
    };
    
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedParticipant(null);
        setOutstandingActive(false);
        setGoodActive(false);
        setExcellentActive(false);
        // Reset remarks for the selected participant
        if (selectedParticipant) {
            const updatedParticipantsData = participantsData.map(participant => {
                if (participant.id === selectedParticipant.id) {
                    return {
                        ...participant,
                        remarks: null
                    };
                }
                return participant;
            });
            setParticipantsData(updatedParticipantsData);
        }

        setSelectedParticipants(prevParticipants => {
            return prevParticipants.map(participant => {
                if (participant.participant === selectedParticipant.id) {
                    return {
                        ...participant,
                        feedback: null
                    };
                }
                return participant;
            });
        });
    };

    // manipulating raw according to the body of issue-certificate-api
    useEffect(() => {
        const rawState = JSON.stringify({
            participant: selectedParticipants,
            course_id: selectedCourseP.id,
            institute_name: selectedCourseP.institute_name
        });
        setRaw(rawState);
    }, [selectedParticipants, selectedCourseP]);

    

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: "center",
            render: (text, record) => (
                <div className='table-name'>
                    <div className='name-checkbox'>
                        {record.remarks !== null && record.is_certificate_issued === null && (
                            <Checkbox onChange={(e) => handleCheckboxChange(record.id, e.target.checked)} />
                        )}
                    </div>
                    <div className='name-details' onClick={() => handleParticipantView(record.id)}>
                        <CiUser size={28} color="#3185FC" />
                        <div className='name'>{text}</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Emp ID',
            dataIndex: 'emp_id',
            key: 'id',
            align: "center",
            render: (emp_id) => (
                <>
                    {emp_id}
                </>
            )
        },
        {
            title: 'Enrolled on',
            dataIndex: 'enrolled_on',
            key: 'date',
            align: "center",
            render: (enrolled_on) => {
                const date = new Date(enrolled_on);
                const formattedDate = `${date.toLocaleDateString()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                return formattedDate;
            },
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            align: "center",
            render: (remarks, record) => {
                if (remarks === null) {
                    return (
                        <div className='NA' style={{ display: "flex", justifyContent: "center" }}>
                            <span>NA</span>
                            <div className='NA-icon'>
                                <CiEdit size={28} style={{ backgroundColor: "white" }} color="#3185FC" onClick={() => handleEdit(record)} />
                            </div>
                        </div>
                    );
                }
                return <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className={`remark ${remarks}`}>
                        {remarks}
                    </div>
                </div>
            },
        }, {
            title: 'Issue Certificate',
            dataIndex: 'is_certificate_issued',
            key: 'issuecertificate',
            align: "center",
            render: (text, record) => (
                record.is_certificate_issued 
                ? <div style={{ display: "flex", justifyContent: "center",padding:"10px" }}><div className='issued'>Issued</div></div>
                : <div style={{ display: "flex", justifyContent: "center" }}><div className='issue-certificate' onClick={() => handleIssueCertificate()}><CertificateIcon /><span>Issue Certificate</span></div></div>
            ),
        },
    ];
    const handleIssueCertificate = async () => {
        // Check if any selected participant has null feedback
        const hasNullFeedback = selectedParticipants.some(participant => participant.feedback === null);
    
        if (hasNullFeedback) {
            message.error('Please provide remarks for all selected participants before issuing certificates.');
        } else {
            try {
                // Only proceed with issuing certificates if at least one checkbox is checked
                if (selectedParticipants.length > 0) {
                    const myHeaders = new Headers();
                    let token = "";
    
                    if (localStorage.getItem("accessToken") !== null) {
                        token = localStorage.getItem("accessToken");
                    }
    
                    myHeaders.append("Authorization", `Bearer ${token}`);
                    myHeaders.append("Content-Type", "application/json");
    
                    // Clone participantsData to update is_certificate_issued
                    const updatedParticipantsData = [...participantsData];
    
                    // Send individual requests for each selected participant
                    for (const participant of selectedParticipants) {
                        const requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: JSON.stringify({
                                participant: participant.participant,
                                course_id: selectedCourseP.id,
                                institute_name: selectedCourseP.institute_name,
                                feedback: participant.feedback
                            }),
                            redirect: "follow"
                        };
                        <CheckTokenExpiration />
                        const url = `${process.env.REACT_APP_BE_URL}/api/participant/create-certificate/`
    
                        const response = await fetch(url, requestOptions);
    
                        if (response.status === 201) {
                            // Update the participant's certificate status in the cloned participantsData
                            const updatedParticipant = updatedParticipantsData.find(p => p.id === participant.participant);
                            if (updatedParticipant) {
                                updatedParticipant.is_certificate_issued = true;
                            }
                        } else {
                            // Handle error response for each participant
                            const responseData = await response.json();
                            message.error(`Failed to issue certificate for participant: ${responseData.error}`);
                            return;
                        }
                    }
    
                    // Update the state with the modified participantsData
                    setParticipantsData(updatedParticipantsData);
    
                    message.success('Certificates successfully issued.');
                    dispatch(addSelectedCourseP([]));
                } else {
                    // Show a message if no checkbox is checked
                    message.error('Please select at least one participant before issuing certificates.');
                }
    
            } catch (error) {
                console.log("Error: ", error);
                message.error('An error occurred while issuing certificates.');
            }
        }
    };
    
    const handleParticipantView = (id) => {
        console.log("sss",id)
        fetchParticipantData(id)
        dispatch(setParticipantView(true));
    }
    const handleExitEnroll = () => {
        dispatch(addSelectedCourseP({}))
    }



    return (
        <div>
            <div style={{ height: "57vh", overflowY: "auto" }}>
                <Table dataSource={participantsData} columns={columns} scroll={{ x: true }} />
            </div>
            <Modal
                title="Issue Certificate"
                open={isModalVisible}
                onCancel={handleCloseModal}
                footer={null}
            >
                <div className='modal-content'>
                    <p>Give Course Remarks</p>
                    <div className='buttons'>
                        <Button className={`remark-modal-outstanding ${outstandingActive}`} onClick={() => selectRemark('outstanding')}>Outstanding</Button>
                        <Button className={`remark-modal-excellent ${excellentActive}`} onClick={() => selectRemark('excellent')}>Excellent</Button>
                        <Button className={`remark-modal-good ${goodActive}`} onClick={() => selectRemark('good')}>Good</Button>
                    </div>
                </div>


                <div className="modal-footer">
                    <Button onClick={handleSubmit}>Save Remarks</Button>
                </div>

            </Modal>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2vh" }}>
                <div onClick={handleExitEnroll}>
                    <Button className="exit-cert-btn" >Exit</Button>
                </div>
                {canIssueCertificates && (
                    <div className="issue-cert">
                       <Button 
    className="issue-cert-btn" 
    onClick={handleIssueCertificate} 
    disabled={!isAnyCheckboxChecked}
>
    Issue Certificates to All
</Button>
                    </div>
                )}
            </div>

        </div>
    );
};

export default EnrollParticpantsTable;
