import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Image } from "antd";

import EnrollCoursesHeading from './EnrollCoursesHeading';

import { useNavigate } from 'react-router';
import backIcon from "../../Assets/icons/Back.png";
import educationIcon from "../../Assets/icons/fluent-mdl2_education.png";
import tick from "../../Assets/icons/mdi_tick.png";

import EnrollCourseModal from "../../Container/courses/EnrollCourseModal"

import { useLocation } from 'react-router-dom';

const CourseDetails = () => {


    const navigate = useNavigate();

    const individualCourseDetails = useSelector(state => state.participants.individualCourseDetails);

    // console.log(individualCourseDetails)

    const [fromEnrolledCourses, setFromEnrolledCourses] = useState(false);

    const [isEnrollModalVisible, setIsEnrollModalVisible] = useState(false); // State for modal visibility
    const location = useLocation();
    const userTypeFromStorage = localStorage.getItem('userRole');

    useEffect(() => {
        if (location.state && location.state.fromEnrolledCourses) {
            setFromEnrolledCourses(true);
        }
    }, [location.state]);

    const courseComments = [
        {
            courseName: "Java Web Technologies",
            prof: "Dr. Parth Dwiwedi",
            comment: "Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost. Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost.",
            daytime: "12-12-2024 Tuesday | 11:30 AM"
        },
        {
            courseName: "Java Web Technologies",
            prof: "Dr. Parth Dwiwedi",
            comment: "Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost. Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost.",
            daytime: "12-12-2024 Tuesday | 11:30 AM"
        },
        {
            courseName: "Java Web Technologies",
            prof: "Dr. Parth Dwiwedi",
            comment: "Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost. Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost.",
            daytime: "12-12-2024 Tuesday | 11:30 AM"
        },
        {
            courseName: "Java Web Technologies",
            prof: "Dr. Parth Dwiwedi",
            comment: "Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost. Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost.",
            daytime: "12-12-2024 Tuesday | 11:30 AM"
        }
    ]
    const coursesArray = [
        "AI nad ML.",
        "ML",
        "AWS",
        "AI.",
        "ML",
        "AWS",
        "AI.",
        "ML",
        "AWS",
        "AI.",
        "ML",
        "AWS",
    ]

    const openEnrollModal = (courseData) => {

        // dispatch(addIndividualCoursedetails(courseData))
        setIsEnrollModalVisible(true);
    };

    const closeEnrollModal = () => {
        setIsEnrollModalVisible(false);
    };
    return (
        <div className="coursesList">
            <div className="coursesList__box">
                <div className="coursesList__box__elem1 enrolledCoursesHeadingMain">
                    <EnrollCoursesHeading />
                    <div className="enrolled_courses_info_heading" style={{ width: "100%", marginTop: "25px" }}>
                        <div className="enrolled_courses_info_heading_left">
                            <img onClick={() => navigate(`/${userTypeFromStorage}/courses`)} src={backIcon} alt="Education" style={{ marginRight: "20px", width: "30px", cursor: "pointer" }} />
                            <img src={educationIcon} alt="Education" style={{ marginRight: "10px" }} />
                            <p>{individualCourseDetails.name}</p>
                        </div>
                        <div className="enrolled_courses_info_heading_right">
                            {/* Check if the course is not enrolled and not from enrolled courses */}
                            {!individualCourseDetails.enrolled && !fromEnrolledCourses && (
                                <Button onClick={openEnrollModal} >Enroll</Button>
                            )}
                        </div>
                    </div>
                    <div style={{ width: "100%" }} >
                        <hr style={{ borderTop: '1px solid #B4B4B4', margin: "20px 0" }} />
                    </div>
                    <div className="enrolled_courses_info_container_Main" >
                        <div className='course_details_container'>
                            <h6>Course ID</h6>
                            <div className='course_details_container_sub'>
                                <h5>CID#_{individualCourseDetails.id}</h5>
                                <div className="course_details_button_status">
                                    <Button >{individualCourseDetails.status ? individualCourseDetails.status : "NA"}</Button>
                                </div>
                            </div>
                        </div>
                        <div className='course_details_container'>
                            <h6>Description</h6>
                            <div className="enrolled_courses_info_paragraph" style={{ width: "98%" }}>
                                <p>{individualCourseDetails.details}</p>
                            </div>
                        </div>
                        <div className='course_details_container'>
                            <h6>Competencies ({individualCourseDetails.course_competencies_count})</h6>
                            <div className="enrolled_courses_compentencies">
                                {
                                    individualCourseDetails.secondary_competencies.map((course, index) => (
                                        index % 4 === 0 ?
                                            <div key={index} className='enrolled_courses_compentencies_group'>
                                                <div className='enrolled_courses_compentencies_item'>
                                                    <img src={tick} alt="Education" style={{ marginRight: "10px" }} />
                                                    <p>{course}</p>
                                                </div>
                                            </div>
                                            :
                                            <div key={index} className='enrolled_courses_compentencies_item'>
                                                <img src={tick} alt="Education" style={{ marginRight: "10px" }} />
                                                <p>{course}</p>
                                            </div>
                                            
                                    )).reduce((acc, curr, index, array) => {
                                        // Group items into sets of four
                                        if (index % 4 === 0) {
                                            acc.push(array.slice(index, index + 4));
                                        }
                                        return acc;
                                    }, []).map((group, index) => (
                                        <div key={index} className='enrolled_courses_compentencies_group'>
                                            {group}
                                        </div>
                                    ))
                                }
                                
                            </div>
                        </div>
                        <div className="enrolled_courses_info_guest"> 
                                    <p>Guest Lecture ({individualCourseDetails.number_of_guest_lectures})</p>
                                    
                                </div>

                        {/* <div className='course_details_container'>
                            <h6>Guest Lecture (09)</h6>
                        </div> */}

                        {/* {courseComments.map((comment, index) => (
                            <div key={index} className='course_details_comment'>
                                <p className='course_comment_heading'>{comment.courseName} - <span>{comment.prof}</span></p>
                                <div className="enrolled_courses_info_paragraph" style={{ width: "98%", marginBottom: "15px" }}>
                                    <p>{comment.comment}</p>
                                </div>
                                <div className="course_details_time" style={{ width: "98%" }}>
                                    <p>{comment.daytime}</p>
                                </div>
                                <div style={{ width: "100%" }} >
                                    <hr style={{ borderTop: '1px solid #B4B4B4', margin: "20px 0" }} />
                                </div>
                            </div>
                        ))} */}

                    </div>
                </div>
            </div>

            <EnrollCourseModal visible={isEnrollModalVisible} onCancel={closeEnrollModal} />
        </div >

    );
};

export default CourseDetails;
