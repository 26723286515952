import React from 'react'
import ParticipantsTopbar from '../Components/Participants/ParticipantsTopbar'
import ParticipantsDetail from '../Components/Participants/ParticipantsDetail'
// import ParticipantsDetail from "../Components/Participants/ParticipantsTable"
import ParticipantView from '../Components/Participants/ParticipantView'
import { useSelector } from 'react-redux'
const Participants = () => {
  const participantView = useSelector(state => state.participants.participantView)
  return (
    <div className='participants'>
      <div className="participants__box">
        <ParticipantsTopbar />
        {
          participantView ? (<ParticipantView />) : (<ParticipantsDetail />)
        }
      </div>
    </div>
  )
}

export default Participants
