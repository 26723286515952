import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedInstitute, addSelectedLocation, clearFilters } from '../../features/Courses/Participants';
import { rcLocations, mstuLocations } from '../../data';
import EnrollableCoursesList from './EnrollableCoursesList';

import { CloseOutlined } from '@ant-design/icons';
import EnrollCoursesHeading from './EnrollCoursesHeading';


const courseOptions = [
  { value: 'MSTU', label: 'MSTU' },
  { value: 'RC', label: 'RC' },
  { value: 'NADT', label: 'NADT' },
  { value: 'others', label: 'Others' }
];

const EnrollCourses = () => {
  const dispatch = useDispatch();

  const [locations, setLocation] = useState("");
  const selectedInstitute = useSelector(state => state.participants.selectedInstitute);

  const handleSelectChange = event => {
    const value = event.target.value;
    dispatch(addSelectedInstitute({ data: value }));
    dispatch(addSelectedLocation(null));
    setLocation("");
  };

  const handleLocationChange = event => {
    const value = event.target.value;
    setLocation(value);
    dispatch(addSelectedLocation(value));
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
    setLocation("");
  };



  return (
    <div className="coursesList">
      <div className="coursesList__box">
        <div className="coursesList__box__elem1 enrolledCoursesHeadingMain">
          <EnrollCoursesHeading />

          <div className='enroll-in-course'>
            <div className='enroll-in-course__heading'>Please choose an institute to view the course list accordingly.</div>
            <div className='enroll-in-course__buttons'>
              <div className='enroll-in-course__buttons__filters'>
                <div className='enroll-in-course__buttons__filters__institute-container'>
                  <select
                    id="courseoptions"
                    className='enroll-in-course__buttons__filters__institute-container__filter'
                    value={selectedInstitute}
                    onChange={handleSelectChange}
                  >
                    <option value="">Choose Institute...</option>
                    {courseOptions.map(option => (
                      <option className='select-option' key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedInstitute === 'MSTU' || selectedInstitute === 'RC' ? (
                  <div className='enroll-in-course__buttons__filters__location-container'>
                    <select
                      id="courseoptions"
                      className='enroll-in-course__buttons__filters__institute-container__filter' value={locations}
                      onChange={handleLocationChange}
                    >
                      <option value="">Choose Location...</option>
                      {selectedInstitute === 'MSTU' && mstuLocations.map(location => (
                        <option className='select-option' key={location} value={location}>
                          {location}
                        </option>
                      ))}
                      {selectedInstitute === 'RC' && rcLocations.map(location => (
                        <option className='select-option' key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>

              <div className='enroll-in-course__buttons__clear' onClick={handleClearFilters}>
                <CloseOutlined style={{ color: "red" }} /> <span>Clear</span>
              </div>
            </div>

            <EnrollableCoursesList />
          </div>
        </div>
      </div>
    </div >

  );
};

export default EnrollCourses;
