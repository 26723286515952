import React from 'react'
import EmptyIcon from "../../../Assets/SVG/Logos/no-certificate.svg";

const AddedCertificateEmptyState = () => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={EmptyIcon} alt="No Certificate" style={{ width: '200px', marginBottom: '350px' }} />
      {/* <hr className='added-certificate-body__hr'></hr> */}
    </div>
    
    </div>
  )
}

export default AddedCertificateEmptyState
