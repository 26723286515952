import React, { useEffect, useState } from "react";
import { Button, Input, Image, message } from "antd";
import activateCourses from "../../Assets/SVG/courseslist/activateCourses.svg";
import educationIcon from "../../Assets/icons/fluent-mdl2_education.png";
import compentencies from "../../Assets/icons/mdi_education-outline-black.png";
import guestLectures from "../../Assets/icons/fluent_guest-12-regular.png";
import downloadIcon from "../../Assets/icons/ic_sharp-download.png";
import feedbackIcon from "../../Assets/icons/fluent_feed-24-filled.png";
import detailsIcon from "../../Assets/icons/material-symbols_view-list.png";
import feedbackPersonIcon from "../../Assets/icons/fluent_person-feedback-48-regular.png";

import AC from "../../Assets/icons/enrolled-courses.png";
import enrollInaCourseIcon from "../../Assets/icons/enroll-in-course.png";
import { addIndividualCoursedetails } from "../../features/Courses/Participants";

import { hostname } from "../../data";

import { useDispatch, useSelector } from "react-redux";
import { toggleStatus } from "../../features/Courses/Participants";

import { useNavigate } from 'react-router';
import FeedbackModal from "./FeedbackModal";
import Certificate from "../../Components/Modals/Certificate";

const EnrolledCourses = () => {
    const [enrolledCoursesData, setEnrolledCoursesData] = useState([]);
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
    const [isCertificateModalVisible, setIsCertificateModalVisible] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [certificateMessages, setCertificateMessages] = useState({}); // State for certificate messages
    const [currentCourseId, setCurrentCourseId] = useState(null); // Track current course ID
    const [feedbackGiven, setFeedbackGiven] = useState({}); // Track whether feedback is given for each course

    const enrolledCoursesStatus = useSelector(state => state.participants.enrolledCoursesStatus)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchKey, setSearchKey] = useState("");
    const userTypeFromStorage = localStorage.getItem('userRole');

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const token = localStorage.getItem("accessToken");
                const response = await fetch(`${hostname}api/participant/participant-courses/?search=${searchKey}`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setEnrolledCoursesData(data)
            } catch (error) {
                console.error(error);
            }
        };
        fetchCourses();
    }, [searchKey]);

    useEffect(() => {
        // Initialize feedbackGiven state based on enrolledCoursesData
        const feedbackState = {};
        enrolledCoursesData.forEach(course => {
            feedbackState[course.id] = course.feedback.length > 0;
        });
        setFeedbackGiven(feedbackState);
    }, [enrolledCoursesData]);

    const handleEnrollInCourse = (status) => {
        if (status !== enrolledCoursesStatus) {
            dispatch(toggleStatus());
        }
    }

    const handleCourseDetails = (courseData) => {
        dispatch(addIndividualCoursedetails(courseData))
        navigate(`/${userTypeFromStorage}/course-details`, { state: { fromEnrolledCourses: true } })
    }

    const openFeedbackModal = (courseData) => {
        const courseId = courseData.id;
        if (feedbackGiven[courseId]) {
            // Feedback already given, show message
            message.info("Thanks for your response. We have already submitted your feedback!");
        } else {
            dispatch(addIndividualCoursedetails(courseData))
            setIsFeedbackModalVisible(true);
        }
    };

    const closeFeedbackModal = () => {
        setIsFeedbackModalVisible(false);
    };

    const handleSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const handleCertificateModalOpen = (courseData) => {
        const courseId = courseData.id;
        setCurrentCourseId(courseId); // Set the current course ID
    
        if (courseData.is_certificate_issued === "Certificate not issued") {
            const message = courseData.feedback.length === 0
                ? "Feedback is not given and certificate is not issued"
                : "Certificate is not issued";
            setCertificateMessages({ ...certificateMessages, [courseId]: message });
        } else if (courseData.feedback.length === 0 && courseData.is_certificate_issued !== "Certificate not issued") {
            setCertificateMessages({ ...certificateMessages, [courseId]: "Feedback is not given but certificate is issued" });
        } else {
            dispatch(addIndividualCoursedetails(courseData));
            setIsCertificateModalVisible(true);
        }
    };
    const handleCertificateModalClose = () => {
        setIsCertificateModalVisible(false);
        setCurrentCourseId(null); // Reset current course ID when modal closes
    };

    // Function to get button style based on course status
    const getButtonStyle = (status) => {
        switch (status) {
            case 'Aborted':
                return {
                    backgroundColor: 'rgb(255, 46, 0)',
                    borderRadius: '8px',
                    color: 'white'
                };
            case 'Completed':
                return {
                    backgroundColor: '#2AC239',
                    borderRadius: '8px',
                    color: 'white',
                    cursor: 'pointer'
                };
            case 'Ongoing':
                return {
                    backgroundColor: 'rgb(255, 202, 33)',
                    borderRadius: '8px',
                    color: 'white',
                    cursor: 'pointer'
                };
            default:
                return {};
        }
    };

    return (
        <div className="coursesList">
            <div className="coursesList__box">
                <div className="coursesList__box__elem1 enrolledCoursesHeadingMain">
                    <div className="coursesList__box__elem1__info enrolledCoursesHeading">
                        <div className="enrolled_Courses_left_Container">
                            <div className="enrolledCoursesHeadingContainer">
                                <img src={activateCourses} alt="activate" className="coursesList__box__elem1__info__img" />
                                <span className="coursesList__box__elem1__info__span"> Enrolled Courses</span>
                            </div>

                            <div>
                                <p style={{ fontSize: "14px", fontWeight: "400" }}>Total Enrolled Courses : <span style={{ fontWeight: "600", color: "#3185FC" }}> {enrolledCoursesData.length}</span></p>
                            </div>

                            <div className="search_Enrolled_Courses_Container">
                                <Input onChange={handleSearch} value={searchKey} placeholder="Search here..." style={{ marginRight: '10px', background: "#ECF5FF" }} />
                            </div>
                        </div>
                        <div className="buttons_div">
                            <Button onClick={() => handleEnrollInCourse(true)} type="default" icon={<img src={AC} alt="AC" />} className="button-margin background_Blue">View Enrolled Courses</Button>
                            <Button onClick={() => handleEnrollInCourse(false)} type="default" icon={<img src={enrollInaCourseIcon} alt="Enroll" />} className="button-margin background_white">Enroll in a course</Button>
                        </div>
                    </div>

                    <div style={{ width: "100%" }} >
                        <hr style={{ borderTop: '1px solid #B4B4B4', margin: "20px 0" }} />
                    </div>
                    <div className="enrolled_courses_info_container_Main" >
                        {enrolledCoursesData?.map((course, index) => (
                            <div onClick={() => setSelectedCourse(index)} key={course.id} className="enrolled_courses_info_subcontainer" >
                                <div className="enrolled_courses_info_heading">
                                    <div className="enrolled_courses_info_heading_left">
                                        <img src={educationIcon} alt="Education" style={{ marginRight: "10px" }} />
                                        <p>{course.name}</p>
                                    </div>
                                    <div className="enrolled_courses_info_heading_rights">
                                        <p>{course.start_date} to {course.end_date}</p>
                                        <Button style={getButtonStyle(course.status)}>{course.status}</Button>
                                    </div>
                                </div>
                                <div className="enrolled_courses_info_paragraph">
                                    <p>{course.details.split(' ').slice(0, 30).join(' ')}{course.details.split(' ').length > 30 ? '...' : ''}</p>
                                </div>
                                <div className="enrolled_courses_info_data">
                                    <div><img src={compentencies} alt="Competencies" /><p> {course.number_of_competencies} Competencies</p></div>
                                </div>
        
                                <div className="enrolled_courses_info_heading">
                                    <div className="enrolled_courses_info_buttons_left">
                                        <Button
                                            onClick={() => handleCertificateModalOpen(course)}
                                            className="green_button"
                                            icon={<Image src={downloadIcon} preview={false} />}
                                        >
                                            Certificate
                                        </Button>
                                        {currentCourseId === course.id && certificateMessages[course.id] && (
                                            <p style={{ color: "red" }}>{certificateMessages[course.id]}</p>
                                        )}
                                        <Button
                                            onClick={() => openFeedbackModal(course)}
                                            className="blue_button"
                                            icon={<Image src={feedbackIcon} preview={false} />}
                                            disabled={course.status === "Completed" ? false : true}
                                            style={course.status === "Completed" ? {} : { backgroundColor: '#2AC239', color: '#fff' }}
                                        >
                                            Give Feedback
                                        </Button>
                                        <Button className="black_button" icon={<Image src={detailsIcon} preview={false} />} onClick={() => handleCourseDetails(course)}>View Course Details</Button>
                                    </div>
                                    <div className="enrolled_courses_info_heading_right enrolled_courses_info_buttons_right">
                                        <p>Remarks Given</p>
                                        <Button>{course.remarks ? course.remarks : "NA"}</Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <FeedbackModal visible={isFeedbackModalVisible} onCancel={closeFeedbackModal} />
            <Certificate
                course={enrolledCoursesData[selectedCourse]}
                visible={isCertificateModalVisible}
                onCancel={handleCertificateModalClose}
            />

        </div>
    );
};

export default EnrolledCourses;
