import React, { useState } from "react";
import LoginContainer from "../Container/logincontainer/LoginContainer";
import "../css/login.css"
// import { ReactComponent as Title } from "../../assets/images/Title.svg";
import { Button } from "antd";
import illustrator from "../Assets/Images/Illustrator.png"
import logo from "../Assets/Images/logo.png"
const LoginPage = () => {

console.log("LoginPage");

    return (
        <main>
            <div className="login">
                <div className="login-image">
                    {/* <Title style={{ position: "fixed", top: "10px", left: "20px" }} /> */}
                    <div className="login-navigation"><Button type="link" block>
                        Back to CBDT website
                    </Button></div>
                    <img src={illustrator} alt="login-background" className="carousel" />
                    <div className="login-subheading"><h3>Platform to capture training participation.</h3></div>
                </div>
                <div className="login-Content">
                    <div className="login-Content-logo">
                        <img src={logo} alt="login-background" className="carousel" />
                        <h3>Directorate Of Training, CBDT</h3>
                    </div>
                    <div className="login-Content-heading">
                        <h1>Admin Login</h1>
                        <Button type="link" block>
                            Please use provided credentials to login
                        </Button>
                    </div>
                    <LoginContainer />
                </div>
            </div>
        </main>
    );
};

export default LoginPage;
