import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input, Select, Upload, message } from 'antd';
import uploadFileLogo from "../../Assets/SVG/participants/uploadFileLogo.svg"
import { ReactComponent as Logo } from "../../Assets/SVG/Logos/contributions_logo.svg"
import contributeIcon from "../../Assets/icons/image.png";

import { useDispatch } from 'react-redux';
import { addNewSession } from '../../features/Courses/Courses';
import CheckTokenExpiration from '../TokenExpiryCheck/CheckTokenExpiration';

const { TextArea } = Input;
const { Option } = Select;

const ContributeModal = ({ setRefetchAPI }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            await handleAddContribution(values);
            setIsModalOpen(false);
            form.resetFields(); // Reset form fields on submit
            setFile(null); // Reset file input
        } catch (error) {
            // Validation failed, do nothing
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields(); // Reset form fields on cancel
        setFile(null); // Reset file input
        dispatch(addNewSession({}));
    };

    const cadreOfParticipantsArr = [
        "PR CCIT",
        "CCIT",
        "PR CIT",
        "CIT",
        "MTS",
        "Addl/Joint CIT",
        "DC/ACIT",
        "ITO",
        "AO",
        "ITI",
        "PS",
        "Sr TA",
        "TA",
        "Steno",
        "Notice Server",
    ];

    const handleAddContribution = async (values) => {
        try {
            const myHeaders = new Headers();
            let token = "";

            if (localStorage.getItem("accessToken") !== null) {
                token = localStorage.getItem("accessToken");
            }

            myHeaders.append("Authorization", `Bearer ${token}`);

            const formData = new FormData();
            formData.append("subject", values.subject);
            formData.append("cadre", values.cadreOfParticipants);  // Ensure this matches the Form.Item name
            formData.append("content_type", values.content_type);
            formData.append("description", values.description);
            formData.append("file", file);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
            };

            <CheckTokenExpiration />

            const url = `${process.env.REACT_APP_BE_URL}/api/participant/upload-contribution/`

            const data = await fetch(url, requestOptions);

            const response = await data.text();
            setRefetchAPI((prev) => !prev);

        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const props = {
        name: 'file',
        multiple: true,
        onChange(info) {
            const { status } = info.file;
            setFile(info.file.originFileObj);
        },
    };

    return (
        <>
            <Button type='default' icon={<img src={contributeIcon} alt="Enroll" />} className='button-margin background_Blue' onClick={showModal}>
                Contribute
            </Button>
            <Modal
                title={
                    <span className='contriLogo_span'>
                        <Logo className='contriLogo' />
                        <span style={{ marginTop: "3px" }}>Contribute</span>
                    </span>
                }
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={850}
                okText="Confirm"
                cancelButtonProps={{ style: { border: 'none' } }}
                centered
                closeIcon={<CloseOutlined style={{ color: 'black' }} />}
            >
                <Form layout="vertical" form={form}>
                    <Form.Item name="subject" label="Subject" className="custom-label" rules={[{ required: true, message: 'Please input subject!' }]}>
                        <Input placeholder="Typing..." style={{ backgroundColor: "#EBF5FF" }} />
                    </Form.Item>
                    <Form.Item
                        name="cadreOfParticipants"
                        label="Target Audience"
                        rules={[
                            {
                                required: true,
                                message: "Please choose an option!",
                            },
                        ]}
                    >
                        <Select placeholder="Pick an option" mode="multiple" allowClear >
                            {cadreOfParticipantsArr.map((elem, i) => (
                                <Option key={i} value={elem}>
                                    {elem}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="content_type" label="Content Type" className="custom-label" rules={[{ required: true, message: 'Please input type!' }]}>
                        <Input placeholder="Content type" style={{ backgroundColor: "#EBF5FF" }} />
                    </Form.Item>
                    <Form.Item name="description" label="Description" className="custom-label" rules={[{ required: true, message: 'Please input description!' }]}>
                        <TextArea style={{ backgroundColor: "#EBF5FF" }} rows={4} placeholder="description" maxLength={10000} />
                    </Form.Item>
                    <Form.Item name="file" className="custom-label" style={{ background: "#EBF5FF" }} rules={[{ required: true, message: 'Please add file' }]}>
                        <label className="custom-file-upload">
                            <input type="file" onChange={(e) => setFile(e.target.files[0])} style={{ display: 'none' }} />
                            <div className="upload-btn-wrapper">
                                <p style={{ "textAlign": "center" }} className="ant-upload-drag-icon">
                                    <img src={uploadFileLogo} alt="Upload" />
                                </p>
                                <p style={{ "textAlign": "center" }} className="ant-upload-text">drag & drop files or Browse</p>
                                <p style={{ "textAlign": "center" }} className="ant-upload-hint">
                                    Supported formats: JPEG, PNG, MP4, PDF, Word, PPT
                                </p>
                            </div>
                        </label>
                        {file && (
                            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                <p>Selected File: {file.name}</p>
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ContributeModal;
