import { makeRequest } from "../../api/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedBarGraph, addComments, addSelectedPieChart, addSessionFeedback } from "../../features/Feedback/Feedback";
import { ReactComponent as BargraphLogo } from "../../Assets/SVG/charts_logo.svg"
import { ReactComponent as ParticipantsLogo } from "../../Assets/SVG/participants_feedback.svg"
import { ReactComponent as PieChartLogo } from "../../Assets/icons/PieCharts.svg"
import { participantComments } from "../../data";

import PieChart from "./Charts/PieChart";
import BarGraph from "./Charts/BarGraph";
import Comments from "./Comments";
import SessionFeedback from "./Modal/SessionFeedback";
import CheckTokenExpiration from "../TokenExpiryCheck/CheckTokenExpiration";
const FeedbackContent = () => {

    const dispatch = useDispatch()

    const comments = useSelector(state => state.feedback.comments)
    const selectedCourse = useSelector(state => state.feedback.selectedCourse)
    const selectedPieChart = useSelector(state => state.feedback.selectedPieChart)
    const selectedBarGraph = useSelector(state => state.feedback.selectedBarGraph)
    const sessionFeedback = useSelector(state => state.feedback.sessionFeedback)


    console.log(sessionFeedback);

    const [activeTab, setActiveTab] = useState('participants'); // 'participants' or 'session'

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const fetchPieChart = async () => {
        try {
            <CheckTokenExpiration />
            let url = `/api/participant/course-feedback-stats/?course_id=${selectedCourse.id}`;
            const data = await makeRequest('get', url);
            const response = await data.json();
            if (data.ok) {
                dispatch(addSelectedPieChart(response))
            } else {
                dispatch(addSelectedPieChart({}))
            }
        } catch (error) {
            dispatch(addSelectedPieChart({}))
        }
    };

    const fetchBarGraph = async () => {
        try {
            <CheckTokenExpiration />
            let url = `/api/participant/course-rating-chart/?course_id=${selectedCourse.id}`;
            const data = await makeRequest('get', url);
            const response = await data.json();
            if (data.ok) {
                dispatch(addSelectedBarGraph(response))
            } else {
                dispatch(addSelectedBarGraph({}))
            }
        } catch (error) {
            dispatch(addSelectedBarGraph({}))
        }
    };

    const fetchComments = async () => {
        try {
            <CheckTokenExpiration />
            let url = `/api/participant/get-participantfeedback/?course_id=${selectedCourse.id}`;
            const data = await makeRequest('get', url);
            const response = await data.json();
            if (data.ok) {
                dispatch(addComments(response))
            } else {
                dispatch(addComments([]))
            }
        } catch (error) {
            console.log('Error: ', error);
            dispatch(addComments([]))
        }
    };

    const fetchSessionFeedback = async () => {
        try {
            <CheckTokenExpiration />
            let url = `/api/participant/session-feedback/?course_id=${selectedCourse.id}`;
            const data = await makeRequest('get', url);
            const response = await data.json();
            if (data.ok) {
                dispatch(addSessionFeedback(response))
            } else {
                dispatch(addSessionFeedback([]))
            }

        } catch (error) {
            console.log('Error: ', error);
            dispatch(addSessionFeedback([]))
        }

    }




    useEffect(() => {
        fetchPieChart();
        fetchBarGraph();
        fetchComments();
        fetchSessionFeedback();
    }, [selectedCourse]);

    return (
        <div className="feedbackcontent">
            <div className="feedbackcontent__charts">
                <div className='feedbackcontent__charts__header'>
                    <BargraphLogo />
                    <span>Feedback Stats</span>
                </div>
                <div className="feedbackcontent__charts__container">
                    {Object.keys(selectedBarGraph).length === 0 ? (
                        <p>No data available for Bar Graph</p>
                    ) : (
                        <BarGraph />
                    )}
                    {Object.keys(selectedPieChart).length === 0 ? (
                        <p>No data available for Pie Chart</p>
                    ) : (
                        <PieChart />
                    )}
                </div>
            </div>
            <div className="feedbackcontent__comments">
                <div className='feedbackcontent__comments__header'>
                    <div className={`feedback-tab ${activeTab === 'participants' ? 'active' : ''}`} onClick={() => handleTabClick('participants')}>
                        <ParticipantsLogo active={activeTab === 'participants'} />
                        <span>Participants Feedback</span>
                    </div>
                    <div className={`feedback-tab ${activeTab === 'session' ? 'active' : ''}`} onClick={() => handleTabClick('session')}>
                        <PieChartLogo active={activeTab === 'session'} />
                        <span>Session Feedbacks</span>
                    </div>
                </div>
                {activeTab === 'participants' ? (
                    comments && comments.length > 0 ? (
                        comments.map((comment) => (
                            <div key={comment.id}>
                                <Comments data={comment} />
                            </div>
                        ))
                    ) : (
                        <p>No comments available</p>
                    )
                ) : (
                    sessionFeedback ? (
                        // sessionFeedback["session_feedback_data"].map((session) => (

                        <SessionFeedback />

                        // ))
                    )
                        : (
                            <p>No session feedback available</p>
                        )
                )}
            </div>
        </div>
    )
}

export default FeedbackContent;
