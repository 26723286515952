import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { coursesData } from '../../data';

import EditCoursesHeader from '../../Components/EditCourses/EditCoursesHeader';
import EditCoursesDesc from '../../Components/EditCourses/EditCoursesDesc';
import EditButtons from '../../Components/EditCourses/EditButtons';
import AddedDisciplinaries from '../../Components/AddedDisciplinaries';
import { makeRequest } from '../../api/api';

import { useDispatch, useSelector } from "react-redux";
import { addSelectedCourse, addEditedCourse, addCompObj } from "../../features/Courses/Courses";
import CheckTokenExpiration from '../../Components/TokenExpiryCheck/CheckTokenExpiration';

const EditCourses = ({ courseData, setIsEditCourseActive }) => {
  const dispatch = useDispatch();
  const compObj = useSelector((state) => state.courses.compObj);
  const editedCourse = useSelector((state) => state.courses.editedCourse);
  const newSession = useSelector((state) => state.courses.newSession);

  const [formData, setFormData] = useState({});
  const [editFormData, setEditFormData] = useState({});
  const [isAborted, setIsAborted] = useState(false); // New state to track if the course is aborted

  const [guestLectureId, setGuestLectureId] = useState();
  const [addComp, setAddComp] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    if (isAborted) {
      message.warning('The course is aborted and cannot be edited.');
    } else {
      setIsModalOpen(true);
    }
  };

  const fetchCourseData = async () => {
    try {
      <CheckTokenExpiration />
      const data = await makeRequest(
        "get",
        `/api/courses/fetch-course/${courseData.key}/`,
      );
      const response = await data.json();
      if (data.ok) {
        setFormData(response)
        setEditFormData(response)

        dispatch(addSelectedCourse(response))
        dispatch(addEditedCourse(response))

        if (response.status === "Aborted") {
          setIsAborted(true);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  useEffect(() => {
    if (editedCourse.course_competencies) {
      const updatedCompObj = editedCourse.course_competencies.map((competency, index) => {
        return {
          id: index,
          data: competency
        };
      });
      dispatch(addCompObj(updatedCompObj));
    }
  }, [editedCourse.course_competencies]);

  return (
    <div className='edit-courses'>
      <div>
        <EditCoursesHeader setIsEditCourseActive={setIsEditCourseActive} />
        <hr style={{ margin: "20px 0" }}></hr>
      </div>
      <div className='edit-courses__body'>
        <EditCoursesDesc />
      </div>

      <div className='edit-courses__added-disciplinaries'>

        {editedCourse.session_details && editedCourse.session_details.map((sessions, index) => (
          <div key={index}>
            {
              <AddedDisciplinaries data={sessions} type={"guest"} />
            }
          </div>
        ))}

        {compObj && compObj.length > 0 && compObj.map((competency, index) => (
          <div key={index}>
            <AddedDisciplinaries isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} id={competency.id} data={competency.data} type={"competency"} />
          </div>
        ))}

      </div>

      <div>
        <hr></hr>
        <EditButtons
          guestLectureId={guestLectureId}
          showModal={showModal}
          setIsEditCourseActive={setIsEditCourseActive}
          editFormData={editFormData}
          courseKey={courseData.key}
          isAborted={isAborted} // Pass the isAborted state to EditButtons
        />
      </div>

    </div>
  );
};

export default EditCourses;
