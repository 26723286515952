import React from 'react'
import EnrollCourses from '../../Components/Participants/EnrollCourses'
import EnrolledCourses from './EnrolledCourses'
import { useSelector } from 'react-redux'
import CheckTokenExpiration from '../../Components/TokenExpiryCheck/CheckTokenExpiration'
const ParticipantCourses = () => {
  <CheckTokenExpiration />
  const enrolledCoursesStatus = useSelector(state => state.participants.enrolledCoursesStatus)

  return (
    <div>
      {enrolledCoursesStatus ? (
        <EnrolledCourses />
      ) : (
        <EnrollCourses />
      )}
    </div>
  )
}

export default ParticipantCourses
