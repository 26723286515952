import React from 'react'
import { GiGraduateCap } from "react-icons/gi";

const CoursesCard = ({ data }) => {
  return (
    <div className='coursescard'>
      <div className='coursescard__left'>
        <div className='coursescard__left__icon'>
          <GiGraduateCap />
        </div>
        <span>{data.name}</span>
      </div>
      <div className='coursescard__middle'>
        Remarks: <span>{data.feedback}</span>
      </div>
      <div className='coursescard__middle'>
        Enrolled On: <span>{data.enrolled_on}</span>
      </div>
      <div className='coursescard__middle'>
        <button className={data.is_certificate_issued ? 'button-issued' : 'button-issue'}>
          {data.is_certificate_issued ? "Issued" : "Issue Certificate"}
        </button>
      </div>
      <div className='coursescard__right'>
        Guest Lectures: <span>{data.num_guest_lectures}</span>
      </div>
    </div>
  )
}

export default CoursesCard
