import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { ReactComponent as PasswordLogo } from "../../../Assets/SVG/PasswordLogo.svg"

const SetPasswordModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button style={{ width: "17rem", position: "absolute", bottom: "15px", left: "15px", display: "flex", justifyContent: "center", alignItems: "center", padding: "18px" }} type="primary" onClick={showModal}>
        <span>Set/Change Password </span>
      </Button>
      <Modal 
     
    //    title="Change/Set Password" open={isModalOpen}
    title={
        <span className='passwordLogo_span'>
        <PasswordLogo className='passwordLogo' />
        <span style={{color: '#3185FC', marginTop:"3px"}}>Change/Set Password</span>

    </span>
    
    }
    open={isModalOpen}
        onOk={handleOk} 
       onCancel={handleCancel}
       cancelButtonProps={{ style: { border: 'none' } }}
       okText="Save Changes"
       width={800}
       style={{padding:"4rem"}}
       >
      <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                // onFinish={onFinish}
                size="large"
                layout="vertical"

            >
               
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password"
                        value={password}
                        style={{width:"660px"}}
                        onChange={(e) => { setPassword(e.target.value) }}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="confirm_password"
                    label="Confirm Password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Confirm Password"
                        value={confirmpassword}
                        style={{width:"660px"}}
                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                

                
            </Form>
        
      </Modal>
    </>
  );
};
export default SetPasswordModal;