import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from "./Home"
import Courses from "./Courses"
import Feedback from "./Feedback"
import CoursesList from "./courses/CoursesList"
import EnrolledCourses from './courses/EnrolledCourses';
import Participants from './Participants';
import ParticipantCourses from './courses/ParticipantCourses';
import CoursesParticipant from "./CoursesParticipant"
import { makeRequest } from '../api/api';
import { useSelector } from 'react-redux';
import Contributions from './Contributions';
import CourseDetails from '../Components/Participants/CourseDetails';
import ParticipantContributions from './ParticipantContributions'
import ParticipantsHome from '../pages/Participants/Home/ParticipantsHome';
import AdminHome from '../pages/AdminHome/AdminHome';
import ViewProfile from '../Components/ViewProfile/ViewProfile';
import CheckTokenExpiration from '../Components/TokenExpiryCheck/CheckTokenExpiration';
import AddedCertificateBody from './Participants/Home/AddedCertificateBody';
const Main = ({ selectedRole }) => {
  const userType = localStorage.getItem('userRole');
  if (!userType) {
    return <Navigate replace to="/" />;
  }
  const CoursesComponent = userType === 'participant' ? ParticipantCourses : Courses;
  const ContributionComponent = userType === 'participant' ? Contributions : ParticipantContributions;
  const HomeComponent = userType === 'participant' ? ParticipantsHome : AdminHome;
  return (
    <div>
      {/* <CheckTokenExpiration /> */}
      <Routes>
        {/* <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<HomeComponent selectedRole={selectedRole} />} />
        <Route path="/courses" element={<CoursesComponent selectedRole={selectedRole} />} />
        <Route path="/participants" element={<Participants />} />
        <Route path="/contributions" element={<ContributionComponent />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/courseslist" element={<CoursesList />} />
        <Route path="/enrolled-courses" element={<Participants />} />
        <Route path="/courses/participant" element={<CoursesParticipant />} />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route path="/view-profile" element={<ViewProfile role={userType} />} /> */}
        <Route path="/home" element={<Navigate replace to={`/${userType}/home`} />} />
        <Route path={`/${userType}/home`} element={<HomeComponent selectedRole={selectedRole} />} />
        <Route path={`/${userType}/courses`} element={<CoursesComponent selectedRole={selectedRole} />} />
        <Route path={`/${userType}/participants`} element={<Participants />} />
        <Route path={`/${userType}/contributions`} element={<ContributionComponent />} />
        <Route path={`/${userType}/feedback`} element={<Feedback />} />
        <Route path={`/${userType}/courseslist`} element={<CoursesList />} />
        <Route path={`/${userType}/enrolled-courses`} element={<Participants />} />
        <Route path={`/${userType}/course-details`} element={<CourseDetails />} />
        <Route path={`/${userType}/view-profile`} element={<ViewProfile role={userType} />} />
        <Route path={`/${userType}/added-certificate`} element={<AddedCertificateBody />} />
      </Routes>
    </div>
  );
}
export default Main;