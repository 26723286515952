import React from 'react'

const data = [
    {
        type: "Excellent",
        color: "#2AC239"
    },
    {
        type: "Good",
        color: "#95FFA0"
    },
    {
        type: "Satisfactory",
        color: "#FFCA21"
    },    {
        type: "Needs Improvement",
        color: "#FF8484"
    },
    {
        type: "Poor",
        color: "#FF4848"
    },    {
        type: "Remaining Feedbacks",
        color: "#B4B4B4"
    }

]


const ResponseStats = () => {
  return (
    <div className='responsestats'>
      <div className='responsestats__header'> Response Stats</div>
      {
        data.map((stat) => (
          <div key={stat.type} className='responsestats__stats'>
            <span className='responsestats__stats__color' style={{ backgroundColor: `${stat.color}` }}></span>
            <span>{stat.type}</span>
          </div>
        ))
      }
    </div>
  )
}

export default ResponseStats
