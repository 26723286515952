import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";


const userSlice = createSlice({
  name: 'user',
  initialState: {
    userType: null,
    },
  reducers: {
    addUserTpe: (state, action) => {
      state.userType = action.payload;
    },
  },

});

export const { addUserTpe } = userSlice.actions;
export default userSlice.reducer;
