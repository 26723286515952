import React, { useState , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchemployeeTableData , fetchEmployeeCodeData } from "../features/ParticipantsHome/EmployeeSlice";
import "../css/dashboard.css";

const groupDesignationOptions = {
  A: ["Pr.CCIT/Pr.DGIT","CCIT/DGIT","PCIT/PDIT","CIT/DIT","Addl.CIT/Addl.DIT","JCIT/JDIT","DCIT/DDIT","ACIT/ADIT"],
  B: ["ITO","AAD","AO","TRO","Sr. PS","PS"],
  C: ["ITI","OS","Sr. TA","Steno","TA","MTS","NS","Driver"],
};

const EmployeeTable = ({ totalEmployees }) => {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(""); // Notification message
  const [submitted, setSubmitted] = useState(false);
  const { employeeCodeData } = useSelector((state) => state.employeeTable);

  const { loading, error, success } = useSelector((state) => state.employeeTable); 

    useEffect(() => {
        const newRows = Array.from({ length: totalEmployees }, (_, i) => ({
          sno: i + 1,
          name: "",
          gender: "M",
          group: "A",
          designation: "Pr.CCIT/Pr.DGIT",
          posting: "",
          empCode: "",
          region: "NWR",
          hod: "",
          igotHours: { hour: "", minute: "" },
          nadtHours: { hour: "", minute: "" },
          otherHours: { hour: "", minute: "" },
          totalHours: { hour: "", minute: "" },
        }));
        setRows(newRows);
      }, [totalEmployees]);

      useEffect(() => {
        if (employeeCodeData.employee_code) {
          setNotification(`Entry for employee code ${employeeCodeData.employee_code} already exists`);
        }
      }, [employeeCodeData]);

      useEffect(() => {
        if (submitted) {
          const timer = setTimeout(() => setSubmitted(false), 5000); 
          return () => clearTimeout(timer); // Cleanup the timer
        }
      }, [submitted]);
      const handleChange = (index, field, value, subField) => {
        const updatedRows = [...rows];
      
        if (field === "hod" && index === 0) {
          // Update the HOD value for all rows when changed in the first row
          updatedRows.forEach((row) => {
            row[field] = value;
          });
        } else if (field === "region" && index === 0) {
          // Update the first row's region and propagate to all rows
          updatedRows.forEach((row) => {
            row[field] = value;
          });
        } else if (field === "empCode") {
          // Allow only 6-digit numbers
          if (/^\d{0,6}$/.test(value)) {
            updatedRows[index][field] = value;
            if (value.length === 6) {
              dispatch(fetchEmployeeCodeData(value)); // Dispatch API with employee code
            }
          } else {
            alert("Employee Code must be a 6-digit number");
          }
        } else if (subField) {
          // Handle sub-fields (hours and minutes)
          if (subField === "minute" && value && updatedRows[index][field].hour === "") {
            updatedRows[index][field].hour = 0; // Reset hours if minutes are selected
          }
      
          if (subField === "hour" && value && updatedRows[index][field].minute === "") {
            updatedRows[index][field].minute = 0; // Reset minutes if hours are selected
          }
      
          updatedRows[index][field][subField] = parseInt(value || 0);
      
          // Recalculate total hours
          const calculateTotal = (field) =>
            updatedRows[index][field].hour +
            updatedRows[index][field].minute / 60;
      
          const totalHours =
            calculateTotal("igotHours") +
            calculateTotal("nadtHours") +
            calculateTotal("otherHours");
      
          const totalHourPart = Math.floor(totalHours); // Hours
          const totalMinutePart = Math.round((totalHours - totalHourPart) * 60); // Minutes
      
          updatedRows[index].totalHours = {
            hour: totalHourPart,
            minute: totalMinutePart,
          };
        } else {
          updatedRows[index][field] = value || updatedRows[index][field];
        }
      
        setRows(updatedRows);
      };      

  const generateOptions = (range) =>
    [...Array(range)].map((_, i) => (
      <option key={i} value={i}>
        {i.toString().padStart(2, "0")}
      </option>
    ));
    const handleLogout = () => {
        localStorage.removeItem("userToken"); 
        sessionStorage.clear(); 
        window.location.href = "/login/participants";; 
      }
      const handleSubmit = () => {
        // Filter rows where all required fields are filled
        const validRows = rows.filter((row) => {
          const hasCompleteData =
            row.name.trim() !== "" &&
            row.gender.trim() !== "" &&
            row.group.trim() !== "" &&
            row.designation.trim() !== "" &&
            row.posting.trim() !== "" &&
            row.empCode.trim() !== "" &&
            row.region.trim() !== "" &&
            row.hod.trim() !== "" &&
            row.igotHours.hour !== "" &&
            row.igotHours.minute !== "" &&
            row.nadtHours.hour !== "" &&
            row.nadtHours.minute !== "" &&
            row.otherHours.hour !== "" &&
            row.otherHours.minute !== "";
      
          return hasCompleteData;
        });
      
        if (validRows.length === 0) {
          alert("Please fill in all fields for at least one row before submitting.");
          return;
        }
      
        // Format valid rows for submission
        const data = validRows.map((row) => ({
          name: row.name,
          gender: row.gender,
          group: row.group,
          designation: row.designation,
          place_of_posting: row.posting,
          employee_code: row.empCode,
          pr_ccit_region: row.region,
          hod: row.hod,
          learning_hours_igot: `${row.igotHours.hour}:${row.igotHours.minute}`,
          learning_hours_institute: `${row.nadtHours.hour}:${row.nadtHours.minute}`,
          other_learning_hours: `${row.otherHours.hour}:${row.otherHours.minute}`,
        }));
      
        console.log("Filtered Table Data", data);
      
        // Dispatch the valid data
        dispatch(fetchemployeeTableData(data));
      
        setSubmitted(true);
      
        // Reset rows after submission
        setRows([
          {
            sno: 1,
            name: "",
            gender: "M",
            group: "A",
            designation: "Pr.CCIT/Pr.DGIT",
            posting: "",
            empCode: "",
            region: "NWR",
            hod: "",
            igotHours: { hour: "", minute: "" },
            nadtHours: { hour: "", minute: "" },
            otherHours: { hour: "", minute: "" },
            totalHours: { hour: "", minute: "" },
          },
        ]);
      };      
    
  return (
    <div className="employee-table-container">
        {notification && (
      <div className="notification">
        <span>{notification}</span>
        <button
          className="close-button"
          onClick={() => setNotification("")} // Clear the notification
        >
          &times; {/* X symbol */}
        </button>
      </div>
    )}
      <table className="employee-table">
        <thead>
          <tr>
            <th>S No.</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Group</th>
            <th>Designation</th>
            <th>Place of Posting</th>
            <th style={{ width: "90px" }}>Employee Code</th>
            <th>Pr. CCIT (CCA) Region</th>
            <th>HOD (Pr. CIT/ Pr.DIT/ CIT/ DIT)</th>
            <th>Learning Hours on iGOT</th>
            <th style={{ width: "120px" }}>Learning Hours Completed in NADT/ RCs/ MSTUs/ MCTP</th>
            <th style={{ width: "120px" }}>Other Learning Hours Completed</th>
            <th>Total Learning Hours</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>{row.sno}</td>
              <td>
                <input
                  type="text"
                  value={row.name}
                  onChange={(e) => handleChange(index, "name", e.target.value)}
                  placeholder="Name"
                />
              </td>
              <td>
                <select
                  value={row.gender}
                  onChange={(e) => handleChange(index, "gender", e.target.value)}
                >
                  <option value="M">M</option>
                  <option value="F">F</option>
                </select>
              </td>
              <td>
                <select
                  value={row.group}
                  onChange={(e) => handleChange(index, "group", e.target.value)}
                >
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                </select>
              </td>
              <td>
                <select
                  value={row.designation}
                  onChange={(e) =>
                    handleChange(index, "designation", e.target.value)
                  }
                >
                  {groupDesignationOptions[row.group].map((designation, i) => (
                    <option key={i} value={designation}>
                      {designation}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  value={row.posting}
                  onChange={(e) =>
                    handleChange(index, "posting", e.target.value)
                  }
                  placeholder="Place of Posting"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.empCode}
                  onChange={(e) =>
                    handleChange(index, "empCode", e.target.value)
                  }
                  placeholder="Emp Code"
                />
              </td>
              <td>
                <select
                  value={row.region}
                  onChange={(e) => handleChange(index, "region", e.target.value)}
                >
                  <option value="NWR">NWR</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="TN">TN</option>
                  <option value="Kerala">Kerala</option>
                  <option value="AP & Telangana">AP & Telangana</option>
                  <option value="KN & Goa">KN & Goa</option>
                  <option value="Odisha">Odisha</option>
                  <option value="WB">WB</option>
                  <option value="NER">NER</option>
                  <option value="UP E">UP E</option>
                  <option value="UP W">UP W</option>
                  <option value="MP & Ch">MP & Ch</option>
                  <option value="Nagpur">Nagpur</option>
                  <option value="Pune">Pune</option>
                  <option value="Bihar & Jharkhand">Bihar & Jharkhand</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  value={row.hod}
                  onChange={(e) => handleChange(index, "hod", e.target.value)}
                  placeholder="HOD"
                />
              </td>
              <td>
                <select
                  value={row.igotHours.hour}
                  onChange={(e) =>
                    handleChange(index, "igotHours", e.target.value, "hour")
                  }
                  style={{ width: "45px" }}
                >
                  <option value="">HH</option>
                  {generateOptions(24)}
                </select>
                :
                <select
                  value={row.igotHours.minute}
                  onChange={(e) =>
                    handleChange(index, "igotHours", e.target.value, "minute")
                  }
                  style={{ width: "45px" }}
                >
                  <option value="">MM</option>
                  {generateOptions(60)}
                </select>
              </td>
              <td>
                <select
                  value={row.nadtHours.hour}
                  onChange={(e) =>
                    handleChange(index, "nadtHours", e.target.value, "hour")
                  }
                  style={{ width: "45px" }}
                >
                  <option value="">HH</option>
                  {generateOptions(24)}
                </select>
                :
                <select
                  value={row.nadtHours.minute}
                  onChange={(e) =>
                    handleChange(index, "nadtHours", e.target.value, "minute")
                  }
                  style={{ width: "45px" }}
                >
                  <option value="">MM</option>
                  {generateOptions(60)}
                </select>
              </td>
              <td>
                <select
                  value={row.otherHours.hour}
                  onChange={(e) =>
                    handleChange(index, "otherHours", e.target.value, "hour")
                  }
                  style={{ width: "45px" }}
                >
                  <option value="">HH</option>
                  {generateOptions(24)}
                </select>
                :
                <select
                  value={row.otherHours.minute}
                  onChange={(e) =>
                    handleChange(index, "otherHours", e.target.value, "minute")
                  }
                  style={{ width: "45px" }}
                >
                  <option value="">MM</option>
                  {generateOptions(60)}
                </select>
              </td>
              <td>
                <select
                  value={row.totalHours.hour}
                  disabled
                  style={{ width: "40px" }}
                >
                  <option>{row.totalHours.hour.toString().padStart(2, "0")}</option>
                </select>
                :
                <select
                  value={row.totalHours.minute}
                  disabled
                  style={{ width: "40px" }}
                >
                  <option>{row.totalHours.minute.toString().padStart(2, "0")}</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="button-container">
        <button className="logout-button" onClick={handleLogout}>
            Logout
        </button>
        <button className="submit-details" onClick={handleSubmit}>
            Submit Details
        </button>
        </div>
      {submitted && (
        <div className="popup">
          <div className="popup-content">
            <p>Details Submitted Successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeTable;
