import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Result, Checkbox } from "antd";
import { ConfigProvider } from "antd";
import { useState } from "react";
// import { signupUser } from "../../features/Login/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import '../../css/login.css';

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const SignupContainer = () => {

    const navigate = useNavigate()
    const [username, setUsername] = useState();
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [cpassword, setCPassword] = useState();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    // const dispatch = useDispatch()

    //   const { loading, error } = useSelector((state) => state.user)
    const loading = true
    const error = false

    const onFinish = () => {

        if (password !== cpassword) {
            validateConfirmPassword();
        } else {

            const userCredentials = {
                username: username,
                email: email,
                password: password,
                user_type: 'freemium',
                first_name: fname,
                last_name: lname
            }
            //   dispatch(signupUser(userCredentials)).then((result) => {
            //     console.log(result.type)

            //     if (result.type === "signup/fulfilled") {
            //       navigate("/")
            //     }
            //   })

            console.log(username, email, password, cpassword);
        }
    };

    const validateName = (_, value) => {
        const trimmedValue = value.trim();
        if (value && trimmedValue.length < 2) {
            return Promise.reject(new Error('Input valid name.'));
        }
        if (value && /\d/.test(trimmedValue)) {
            return Promise.reject(new Error('Name should not contain numbers.'));
        }
        return Promise.resolve();
    };

    const validateUsername = (_, value) => {
        if (value && value.length < 3) {
            return Promise.reject(new Error('Enter valid username.'));
        }
        if (value && /^\d+$/.test(value)) {
            return Promise.reject(new Error('Username must contain characters.'));
        }

        return Promise.resolve();
    };

    const validatePassword = (_, value) => {
        if (value && value.length < 8) {
            return Promise.reject(new Error('Password should be at least 8 characters long.'));
        }
        if (value && !/\d/.test(value)) {
            return Promise.reject(new Error('Password should include at least one number.'));
        }
        if (value && !/[^a-zA-Z0-9]/.test(value)) {
            return Promise.reject(new Error('Password should include at least one special character.'));
        }
        if (value && /\s/.test(value)) {
            return Promise.reject(new Error('Password should not include spaces.'));
        }
        return Promise.resolve();
    };


    const validateConfirmPassword = () => {
        if (cpassword && password !== cpassword) {
            return Promise.reject(new Error('The passwords do not match!'));
        }
        return Promise.resolve();
    };

    return (
        <ConfigProvider
            className="signupContainer"
            theme={{
                components: {
                    Button: {
                        colorPrimary: "#000",
                        defaultBg: "#000",
                        colorPrimaryHover: "#000"
                    },
                },
            }}
        >
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                size="large"
                layout="vertical"
                style={{
                    maxWidth: 600,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <Form.Item
                        name="fname"
                        label="First Name"
                        rules={[
                            { required: true, message: "Please input your first name!" },
                            { validator: validateName },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="First Name"
                            value={fname}
                            onChange={(e) => setFname(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="lname"
                        label="Last Name"
                        rules={[
                            { required: true, message: "Please input your last name!" },
                            { validator: validateName },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Last Name"
                            value={lname}
                            onChange={(e) => setLname(e.target.value)}
                        />
                    </Form.Item>
                </div>

                <Form.Item
                    name="username"
                    label="User Name"
                    rules={[
                        { required: true, message: "Please input your username!" },
                        { validator: validateUsername },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="User Name"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: "Please input your email!" },
                        { type: "email" },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        { required: true, message: "Please input your password!" },
                        { validator: validatePassword },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="cpassword"
                    label="Confirm Password"
                    rules={[{ required: true, message: "Please input your Password again!" },
                    { validator: validateConfirmPassword }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Confirm Password"
                        value={cpassword}
                        onChange={(e) => { setCPassword(e.target.value) }}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item className="termsandConditions"
                    name="terms"
                    valuePropName="checked"
                    rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('You need to accept the terms and conditions.') }]}
                >
                    <Checkbox>
                        I agree to all the terms and conditions
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                    >
                        {loading ? "Loading..." : "Sign Up"}
                    </Button>
                    {error != null ? error : ""}
                    Already have an account? <a href="/">Login</a>
                </Form.Item>
            </Form>
        </ConfigProvider>
    );
};

export default SignupContainer;
