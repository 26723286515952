import React, { useEffect, useState } from 'react'
import ViewProfileHeader from './ViewProfileHeader'
import ViewProfileFooter from './ViewProfileFooter'
import viewProfileHeader from "../../Assets/Images/viewProfileHeader.png"
import viewProfileIcon from "../../Assets/Images/image.png"
import { ReactComponent as CoursesEnrolled } from "../../Assets/SVG/ViewProfile/CoursesEnrolled.svg"
import { ReactComponent as ManHours } from "../../Assets/SVG/ViewProfile/ManHours.svg"
import { ReactComponent as ContributionsMade } from "../../Assets/SVG/ViewProfile/ContributionsMade.svg"
import CheckTokenExpiration from '../TokenExpiryCheck/CheckTokenExpiration'

const ViewProfile = ({
    role
}) => {

    const [userData, setUserData] = useState({});
    const userType = localStorage.getItem("userRole")
    let userrole;
    if(userType === "participant"){
        userrole="Participant"
    } else {
        userrole ="Admin"
    }
    const fetchParticipantData = async () => {
        const myHeaders = new Headers();
        let token = "";
        if (localStorage.getItem("accessToken") !== null) {
            token = localStorage.getItem("accessToken");
        }
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        <CheckTokenExpiration />
        fetch(`${process.env.REACT_APP_BE_URL}/api/participant/participant_details/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const data = result;
                const userDetails = {
                    username: data?.participant_details?.name || "",
                    id: data?.participant_details?.emp_id,
                    email: data?.participant_details?.email || "",
                    location: data?.place_of_posting || "",
                    role: userrole || "",
                    coverImage: data?.coverImage || viewProfileHeader,
                    profileImage: data?.profileImage || viewProfileIcon,
                    mobileNo: data?.participant_details?.contact_number || "",
                    date: data?.date || "",
                    participantData: [
                        {
                            title: "Total man hours",
                            value: data.total_men_hours,
                            icon: ManHours,

                        },
                        {
                            title: "Total Courses enrolled",
                            value: data.total_courses_enrolled,
                            icon: CoursesEnrolled,

                        },
                        {
                            title: "Total Contributions",
                            value: data.total_contributions,
                            icon: ContributionsMade,

                        }
                    ]
                }
                // console.log("user details are", userDetails);
                setUserData(userDetails);


            })
            .catch((error) => console.error(error));
    }

    console.log("user data is", userData);

    const fetchAdminData = async () => {
        const myHeaders = new Headers();
        let token = "";
        if (localStorage.getItem("accessToken") !== null) {
            token = localStorage.getItem("accessToken");
        }
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        <CheckTokenExpiration />

        fetch(`${process.env.REACT_APP_BE_URL}/api/user_profile/fetch-user-details/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const { data } = result;
                console.log("data of admin",data);
                const userDetails = {
                    username: data.username,
                    id: data.id,
                    email: data.username || "",
                    location: data.place_of_posting || "",
                    role: userrole,
                    coverImage: data?.coverImage || viewProfileHeader,
                    profileImage: data?.profileImage || viewProfileIcon,
                    mobileNo: data?.contact_details || "",
                    date: data?.date || ""
                }
                setUserData(userDetails);


            })
            .catch((error) => console.error(error));
    }



    useEffect(() => {
        if (role === 'participant') {

            // To Do (call participant api)
            fetchParticipantData();

        } else {
            // To Do (call admin api)
            fetchAdminData();
        }
    }, [])
    return (
        <div className='viewProfilediv1'>
            <div className='viewProfilediv2'>

                <ViewProfileHeader profileImage={userData?.profileImage} coverImage={userData?.coverImage} location={userData?.location} role={userrole} date={userData?.date} username={userData?.username} />

                <ViewProfileFooter role={userData?.role} participantData={userData?.participantData} mobileNo={userData?.mobileNo} email={userData?.email} id={userData?.id} />

            </div>
        </div>
    )
}

export default ViewProfile
