import React, { useState } from 'react'
// import Sidebar from '../Container/Sidebar'
import Main from '../Container/Main'

const Dashboard = () => {

  const [selectedRole, setSelectedRole] = useState('');

  // const handleRoleChange = (role) => {
  //   // alert("RoleChange")
  //   setSelectedRole(role);
  //   localStorage.setItem('selectedRole', role);
  // };
  return (
    <div className>
    <Main selectedRole={selectedRole} />
  </div>
    // // <div className='dashboard'>
    //   {/* <div className='dashboard__sidebar'>
    //     <Sidebar handleRoleChange={handleRoleChange} />
    //   </div> */}
    // {/* </div> */}
  )
}

export default Dashboard
