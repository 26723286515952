import React, { useEffect, useState } from 'react'
import { ReactComponent as Comp } from "../Assets/SVG/competency.svg"
import { ReactComponent as Guest } from "../Assets/SVG/guest.svg"
import EditDis from './Buttons/EditDis'
import CloseDis from './Buttons/CloseDis'
import AddCompetency from './Modals/AddCompetency'

import { useDispatch, useSelector } from 'react-redux'
import { addCompObj, addEditedCourse, deleteSession } from '../features/Courses/Courses'
import EditCompetency from './Modals/EditCompetency'
import EditGuestLecture from './Modals/EditGuestLecture'

const AddedDisciplinaries = ({ isModalOpen, setIsModalOpen, id, data, type }) => {
  const dispatch = useDispatch();
  const compObj = useSelector((state) => state.courses.compObj);
  const editedCourse = useSelector((state) => state.courses.editedCourse);

  const [isEditModal, setIsEditModal] = useState(false)

  const [isEditSessionModal, setIsEditSessionModal] = useState(false)


  const showModal = () => {
    if (type === "competency") {
      setIsEditModal(true)
    }

    if (type === "guest") {
      setIsEditSessionModal(true)
    }
  }
  const handleDelete = () => {
    if (type === "competency") {
      const updatedCompObj = compObj.filter(item => item.id !== id);
      dispatch(addCompObj(updatedCompObj));

      const newDataArray = updatedCompObj.map(item => item.data);

      const updatedEditedCourse = {
        ...editedCourse,
        course_competencies: newDataArray
      };

      dispatch(addEditedCourse(updatedEditedCourse));

    }

    if(type==="guest"){
      dispatch(deleteSession(data))

      const updatedSessionDetails = editedCourse.session_details.filter(session => session.id !== data.id);
      const updatedEditedCourse = {
        ...editedCourse,
        session_details: updatedSessionDetails
      };
      dispatch(addEditedCourse(updatedEditedCourse));
    
    }
  }


  return (
    <div className='added-disciplinaries'>

      <div className='added-disciplinaries__left'>
        {
          type === "guest" ? (
            <>
              <div className='added-disciplinaries__left__logo'><Guest /></div>
              <div className='added-disciplinaries__left__name'>Guest Lecture: {data.session}</div>
            </>
          ) : (
            <>
              <div className='added-disciplinaries__left__logo'><Comp /></div>
              <div className='added-disciplinaries__left__name'>{data}</div>
            </>
          )
        }

      </div>

      <div className='added-disciplinaries__middle'>
        {
          type === "guest" ? (
            <>
              <span>{data.speaker_name}</span>
              <span>{data.speaker_type}</span>
            </>
          ) : (
            <>
              <span>Competency</span>
            </>
          )
        }

      </div>

      <div className='added-disciplinaries__button'>
        <EditDis showModal={showModal} />
        <CloseDis handleDelete={handleDelete} />
      </div>

      {
        type === "guest" ? (
          <EditGuestLecture data={data} setIsEditModal={setIsEditSessionModal} isEditModal={isEditSessionModal} />
        ) : (
          <>
            <AddCompetency setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
            <EditCompetency data={data} id={id} setIsEditModal={setIsEditModal} isEditModal={isEditModal} />
          </>

        )
      }


    </div>
  )
}

export default AddedDisciplinaries
