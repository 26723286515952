import React, { useEffect, useState } from "react";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  message,
  Radio,
  Select,
  InputNumber
} from "antd";
import AC from "../../Assets/icons/AC.png";
import AGL from "../../Assets/icons/AGL.png";
import AddCourseModal from "./AddCourseModal";
import AddCompetencyModal from "./AddCompetencyModal";

import { functionalCompItems, behavioralCompItems } from "../../data";
import { hostname } from "../../data";

const NewCourseForm = ({
  disciplinariesList,
  setDisciplinariesList,
  editCompetency,
  setEditCompetency,
  setCoursesExpand,
  
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCompModalVisible, setIsCompModalVisible] = useState(false);
  const [isDataSubmitting, setIsDataSubmitting] = useState(false);
  const [primaryCompetencyValue, setPrimaryCompetencyValue] = useState(null);
  const [selectedFunctionalDetails, setSelectedFunctionalDetails] = useState([]);
  const [selectedBehavioralDetails, setSelectedBehavioralDetails] = useState([]);
  const [primaryCompetency, setPrimaryCompetency] = useState(primaryCompetencyValue || "");

  const primaryCompetencyOptions = {
    Functional: functionalCompItems,
    Behavioral: behavioralCompItems
  }[primaryCompetencyValue] || [];

  const handlePrimaryCompetencyChange = (value) => {
    setPrimaryCompetencyValue(value);
    setPrimaryCompetency(value);
  };


  const { Option } = Select;

  useEffect(() => {
    if (editCompetency !== null) {
      setIsCompModalVisible(true);
    }
  }, [editCompetency]);

  useEffect(() => {
    // Clear secondary competencies when primary competency changes
    if (primaryCompetency !== "Functional") {
      setSelectedFunctionalDetails([]);
    }
    if (primaryCompetency !== "Behavioural") {
      setSelectedBehavioralDetails([]);
    }
    if (primaryCompetency !== "Domain") {
      setDisciplinariesList([]);
    }
  }, [primaryCompetency]);

  const disciplinariesArrList = () => {
    const new_arr = [];
    disciplinariesList.forEach((element) => {
      new_arr.push(element["courseName"]);
    });
    return new_arr;
  };

  const onFinish = async (values) => {
    setIsDataSubmitting(true);

    console.log("Received values:", values);

    // Start & End Date
    let startDate = "";
    let endDate = "";
    if (values["startDate"] !== null) {
      startDate = `${values["startDate"]["$y"]}-${values["startDate"]["$M"] + 1
        }-${values["startDate"]["$D"]}`;
    }
    if (values["endDate"] !== null) {
      endDate = `${values["endDate"]["$y"]}-${values["endDate"]["$M"] + 1}-${values["endDate"]["$D"]
        }`;
    }

    // Check if Start Date and End Date are filled
    if (!startDate || !endDate) {
      message.error("Start Date and End Date are required!");
      setIsDataSubmitting(false);
      return;
    }

    let secondaryCompetencies;
    if (primaryCompetencyValue === "Functional") {
      secondaryCompetencies = selectedFunctionalDetails;
    } else if (primaryCompetencyValue === "Behavioral") {
      secondaryCompetencies = selectedBehavioralDetails;
    } else {
      secondaryCompetencies = disciplinariesArrList();
    }

    const inputData = {
      name: values["courseTitle"],
      details: values["courseDescription"],
      nature: values["courseNature"],
      cadre: values["cadreOfParticipants"],
      duration: values["courseDuration"],
      primary_competency: values["primaryCompetency"],
      secondary_competencies: secondaryCompetencies,
      course_coordinator: values["courseCoordinator"],
      start_date: startDate,
      end_date: endDate,
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("accessToken")}`
    );

    const raw = JSON.stringify(inputData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const data = await fetch(
        `${hostname}api/courses/create-course/`,
        requestOptions
      );
      const response = await data.json();
      if (data.ok) {
        setIsDataSubmitting(false);
        message.success("Course Created successfully!");
        setCoursesExpand(false);
      } else {
        setIsDataSubmitting(false);
        message.error("Error occurred!");
      }
    } catch (error) {
      console.log("Error: ", error);
      setIsDataSubmitting(false);
      message.error("Error occurred!");
    }
  };

  const showModal = () => {
    // setIsModalVisible(true);
  };
     


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showCompModal = () => {
    setIsCompModalVisible(true);
  };

  const handleCompOk = () => {
    setIsCompModalVisible(false);
  };

  const handleCompCancel = () => {
    setIsCompModalVisible(false);
  };

  //   Static Data
  const cadreOfParticipantsArr = [
    "PR CCIT",
    "CCIT",
    "PR CIT",
    "CIT",
    "MTS",
    "Addl/Joint CIT",
    "DC/ACIT",
    "ITO",
    "AO",
    "ITI",
    "PS",
    "Sr TA",
    "TA",
    "Steno",
    "Notice Server",
  ];
  const courseNatureArr = ["Orientation", "Induction", "In-Service"];
  // "Course Category" changed to "Primary Competency "
  const courseCategoryArr = ["Domain", "Functional", "Behavioural"];
  

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "#3185FC",
            labelRequiredMarkColor: "#3185FC",
          },
        },
      }}
    >
      <Form
        name="newCourseForm"
        onFinish={onFinish}
        initialValues={{
          courseTitle: "",
          courseDescription: "",
        }}
        layout="vertical"
      >
        <Form.Item
          label="Course Title"
          name="courseTitle"
          rules={[
            {
              required: true,
              message: "Please input the course title!",
            },
          ]}
        >
          <Input
            style={{ background: "#EBF5FF", height: "50px" }}
            placeholder="Write course Title..."
          />
        </Form.Item>

        <Form.Item
          name="cadreOfParticipants"
          label="Target Audience"
          rules={[
            {
              required: true,
              message: "Please choose an option!",
            },
          ]}
        >
          <Select placeholder="Pick an option" mode="multiple" allowClear>
            {cadreOfParticipantsArr.map((elem, i) => (
              <Option key={i} value={elem}>
                {elem}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="courseNature"
          label="Nature of the Course"
          rules={[
            {
              required: true,
              message: "Please select any one option!",
            },
          ]}
        >
          <Radio.Group>
            {courseNatureArr.map((elem, i) => (
              <Radio key={i} value={elem}>
                {elem}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="primaryCompetency"
          label="Primary Competency"
          rules={[
            {
              required: true,
              message: "Please select any one option!",
            },
          ]}
        >
          <Radio.Group onChange={(e) => handlePrimaryCompetencyChange(e.target.value)}>
            {courseCategoryArr.map((elem, i) => (
              <Radio key={i} value={elem}>
                {elem}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {primaryCompetencyValue === "Functional" && (
          <Form.Item
            label="Functional Competencies"
            name="functionalDetails"
            rules={[
              {
                required: true,
                message: "Please select at least one functional detail!",
              },
            ]}
          >
            <Select
              mode="multiple" 
              style={{ width: "50%" }}
              placeholder="Select functional details"
              value={selectedFunctionalDetails}
              onChange={(values) => setSelectedFunctionalDetails(values)}
            >
              {functionalCompItems.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {primaryCompetencyValue === "Behavioral" && (
          <Form.Item
            label="Behavioural Competencies"
            name="behavioralDetails"
            rules={[
              {
                required: true,
                message: "Please select at least one behavioral detail!",
              },
            ]}
          >
            <Select
              mode="multiple" 
              style={{ width: "50%" }}
              placeholder="Select behavioral details"
              value={selectedBehavioralDetails}
              onChange={(values) => setSelectedBehavioralDetails(values)}
            >
              {behavioralCompItems.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}


        <Form.Item
          label="Course Coordinator Name"
          name="courseCoordinator"
          rules={[
            {
              required: true,
              message: "Please input the course coordinator name!",
            },
          ]}
        >
          <Input
            style={{ background: "#EBF5FF", height: "50px" }}
            placeholder="Write course coordinator name..."
          />
        </Form.Item>

        <Form.Item
          label="Course Duration"
          name="courseDuration"
          rules={[
            {
              required: true,
              message: "Please input the course duration!",
            },
          ]}
        >

            <InputNumber min={1} defaultValue={1} />

        </Form.Item>

        <Form.Item
          label="Course Description"
          name="courseDescription"
          rules={[
            {
              required: true,
              message: "Please input the course description!",
            },
          ]}
        >
          <Input.TextArea
            style={{ background: "#EBF5FF", height: "150px" }}
            placeholder="Write course description..."
          />
        </Form.Item>

        <Row>
          <Col>
          <Form.Item
              label="Start"
              name="startDate"
              rules={[
                { required: true, message: "Please input the start date!" },
              ]}
              style={{ marginRight: 30 }}
            >
              <DatePicker
                style={{ background: "#EBF5FF" }}
                placeholder="Start Date"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="End"
              name="endDate"
              rules={[
                { required: true, message: "Please input the end date!" },
              ]}
            >
              <DatePicker
                style={{ background: "#EBF5FF" }}
                placeholder="End Date"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: 30 }}>
          <Row justify="space-between">
            <Col>
              {/* <Button style={{ background: "#EBF5FF", color: "#3185FC", padding: "10px", height: "auto", marginRight: "20px" }} type="primary" htmlType="submit" icon={<img style={{ height: "13px" }} src={AGL} alt="AGL Icon" />} >
                                Add Guest Lecture
                            </Button> */}

              {
                primaryCompetencyValue === "Functional" || primaryCompetencyValue === "Behavioural" ? null : (<Button
                  style={{
                    background: "#EBF5FF",
                    color: "#3185FC",
                    padding: "10px",
                    height: "auto",
                  }}
                  type="primary"
                  icon={<img style={{ width: "15px" }} src={AC} alt="AC Icon" />}
                  onClick={showCompModal}
                >
                  Add Secondary Competency
                </Button>)
              }

            </Col>
            <Col>
              <Button
                style={{
                  padding: "10px 30px",
                  height: "auto",
                  background: "#FFE60A",
                  color: "black",
                }}
                type="primary"
                loading={isDataSubmitting && true}
                htmlType="submit"
                onClick={showModal}
              >
                {isDataSubmitting ? "Adding Course" : "Add Course"}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <AddCourseModal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      />
      <AddCompetencyModal
        visible={isCompModalVisible}
        onOk={handleCompOk}
        onCancel={handleCompCancel}
        disciplinariesList={disciplinariesList}
        setDisciplinariesList={setDisciplinariesList}
        editCompetency={editCompetency}
        setEditCompetency={setEditCompetency}
        primaryCompetencyValue={primaryCompetencyValue}
      />
    </ConfigProvider>
  );
};

export default NewCourseForm;
