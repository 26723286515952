import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import { ReactComponent as CoursesLogo } from "../../Assets/SVG/Logos/courses_logo.svg"
import { ReactComponent as GuestLectureLogo } from "../../Assets/SVG/createcourse/guestLectureIcon.svg"
import { CloseOutlined } from '@ant-design/icons';
import { GiGraduateCap } from "react-icons/gi";

const AddedCourses = ({ disciplinariesList, setDisciplinariesList, editCompetency, setEditCompetency }) => {
    const dummyCoursesList = [
        {
            courseName: "Beginners Programming in java using Intellij",
            status: "Competency",
            author: null,
            type: null,
            time: null
        },
        {
            courseName: "Guest Lecture: Basics of Intellij",
            status: null,
            author: "Dr XYZ ABC",
            type: "Inhouse",
            time: "10-12-24 | 12:00 AM"
        },
        {
            courseName: "Beginners Programming in java using Intellij",
            status: "Competency",
            author: null,
            type: null,
            time: null
        },
        {
            courseName: "Guest Lecture: Basics of Intellij",
            status: null,
            author: "Dr XYZ ABC",
            type: "Inhouse",
            time: "10-12-24 | 12:00 AM"
        },
        {
            courseName: "Beginners Programming in java using Intellij",
            status: "Competency",
            author: null,
            type: null,
            time: null
        },
        {
            courseName: "Guest Lecture: Basics of Intellij",
            status: null,
            author: "Dr XYZ ABC",
            type: "Inhouse",
            time: "10-12-24 | 12:00 AM"
        },
    ]

    const editButtonClickHandler = (competency_id) => {
        setEditCompetency(competency_id)
    }

    const deleteClickHandler = (competency_id) => {
        // Delete the value from the array
        setDisciplinariesList(prevList => {
            return prevList.filter((competency, index) => index !== competency_id);
        });
    }


    return (
        <div>
            <h2 style={{ marginBottom: "20px" }}>Added Disciplinaries</h2>
            <hr style={{ marginBottom: "20px" }}></hr>
            <div className='coursesListContainer'>
                {disciplinariesList.map((course, index) => (
                    <Card key={index} className='coursesRow' style={{ marginBottom: 16 }}>
                        <Row align="middle" >
                            <Col span={1}>
                                {course.status === "Competency" ?
                                    <GiGraduateCap size={28} color="#545454" />
                                    :
                                    <GuestLectureLogo width={30} height={20} />
                                }

                            </Col>
                            <Col span={8}>
                                <p>
                                    {course.courseName}
                                </p>
                            </Col>
                            <Col span={10}>

                                {course.status ? <p style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>{course.status}</p> : <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}><p>{course.author} </p><p>{course.type} </p><p> {course.time}</p></div>}

                            </Col>
                            <Col className='coursesButtons' span={2} style={{ textAlign: 'right' }}>
                            </Col>
                            <Col className='coursesButtons' span={3} style={{ textAlign: 'right' }}>
                                <Button style={{ padding: "5px 20px", cursor: "pointer" }} type="primary"
                                    onClick={() => editButtonClickHandler(index)}
                                >
                                    Edit
                                </Button>
                                <CloseOutlined style={{ color: 'red', fontSize: 20, marginLeft: 8 }}
                                    onClick={() => deleteClickHandler(index)}
                                />
                            </Col>
                        </Row>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default AddedCourses;