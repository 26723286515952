import React from 'react';
import { ReactComponent as CommentLogo } from "../../Assets/SVG/comments.svg";

const Comments = ({ data }) => {
  // Function to get the text corresponding to the rating
  const getRatingText = () => {
    switch (data.rating) {
      case 1:
        return <span >Rating:1/5 <strong> Poor</strong></span>;
      case 2:
        return <span>Rating:2/5  <strong>Need Improvement</strong></span>;
      case 3:
        return <span>Rating:3/5 <strong> Satisfactory</strong></span>;
      case 4:
        return <span>Rating:4/5 <strong> Good</strong></span>;
      case 5:
        return <span>Rating:5/5 <strong> Excellent</strong></span>;
      default:
        return <span>Rating: {data.rating}/5</span>;
    }
  };

  return (
    <div className='comments'>
      <div className='comments__top'>
        <div className='comments__top__header'>
          <div className='comments__top__header__title'>
            <CommentLogo style={{ width: "1vw" }} />
            <span>{data.participant?.name}</span>
          </div>
          <span>P_ID#{data.participant?.emp_id}</span>
        </div>
        <div className='comments__ratings'>
          <div>{getRatingText()}</div>
          <div className={`comments__ratings__status ${data.status}`}>{data.status}</div>
        </div>
      </div>
      {data.details !== "" && (
        <div className='comments__bottom'>
          Comments:
          <div className='comments__bottom__desc'>{data.comments}</div>
        </div>
      )}
    </div>
  );
};

export default Comments;
