// ADMIN
import React from "react";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Checkbox, message } from "antd";
import { ConfigProvider } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addUserTpe } from "../../features/UserSlice/Userslice";
// import { loginUser, profile } from "../../features/Login/UserSlice";
// import { useDispatch, useSelector } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { hostname } from "../../data";

const LoginContainer = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [remember, setRemember] = useState(false);
    // const history = useHistory(); // Initialize useHistory hook
    const navigate = useNavigate();

    const dispatch = useDispatch();
    // const dispatch = useDispatch()

    //   const { loading, error } = useSelector((state) => state.user)
    // const loading = true
    // const error = false

    // const onFinish = (values) => {
    //     console.log(values)

    //     // const userCredentials = values
    //     // dispatch(loginUser(userCredentials)).then((result) => {
    //     //   if (result.payload) {
    //     //     dispatch(profile())
    //     //   }
    //     //   else {
    //     //     console.error("Login Failed")
    //     //   }
    //     // })
    // };


    // const onFinish = async (values) => {
    //     try {
    //         const response = await fetch(`${hostname}api/user_profile/login_user/`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify(values)
    //         });

    //         // Rest of your code...
    //     } catch (error) {
    //         message.error(error.message || "An error occurred during login");
    //     }
    // };

    const onFinish = async (values) => {
        try {
            const response = await fetch(`${hostname}api/user_profile/login_user/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(values)
            });

            if (response.status !== 200) {
                const error = await response.json();
                const errorMessage = error.non_field_errors ? error.non_field_errors[0] : error.message || response.statusText || "Login failed";
                throw new Error(errorMessage);
            }

            const data = await response.json();
            const accessToken = data.access;

            localStorage.setItem("adminAccessToken", accessToken);
            console.log(accessToken);       

            try {
                const userDetailsResponse = await fetch(`${hostname}api/user_profile/fetch-user-details/`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                if (!userDetailsResponse.ok) {
                    throw new Error('Failed to fetch user details');
                }

                const userDetails = await userDetailsResponse.json();
                dispatch(addUserTpe(userDetails.data.role));
                localStorage.setItem('userRole', userDetails.data.role);
                localStorage.setItem('userName', userDetails.data.display_name);
                localStorage.setItem('selectedRole', "");
            } catch (error) {
                console.error('Error fetching user details:', error);
            }

            navigate("/home");
            message.success("Login successful!");
        } catch (error) {
            message.error(error.message || "An error occurred during login");
        }
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: "#FFE60A",
                        defaultBg: "#FFE60A",
                        colorPrimaryHover: "#dfcb21",
                    },
                },
            }}
        >
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                size="large"
                layout="vertical"

            >
                <Form.Item
                    name="username"
                    label="Username / e-mail"
                    rules={[{ required: true, message: "Please input your Username!" }]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Username"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item>
                    <Checkbox
                        checked={remember}
                        onChange={(e) => setRemember(e.target.checked)}
                    >
                        Keep me logged in
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                        style={{ color: "black" }}
                    >
                        {/* {loading ? "Loading..." :  */}
                        Login
                        {/* } */}
                    </Button>
                    {/* {error != null ? error : ""} */}
                    {/* Don’t have an account? <a href="/signup">Sign up </a> */}
                </Form.Item>
            </Form>
        </ConfigProvider>
    );
};

export default LoginContainer;
