import React, { useState } from "react";
import SignupContainer from "../Container/signupcontainer/SignupContainer";

const SignPage = () => {



    return (
        <main>
            <div className="login">
                {/* <div className="login-image">
                    <Title style={{ position: "fixed", top: "10px", left: "20px" }} />
                    <img src={signup_background_img_url} alt="login-background" className="carousel" />
                </div> */}
                <div className="login-Content">
                    <div className="signup_container">
                        <h1>Sign up Heading</h1>
                        <SignupContainer />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default SignPage;
