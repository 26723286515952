import React, { useState } from "react";
import EmployeeTable from "../../../Container/EmployeeTable";
import Form from "../../../Container/Form";
import "../../../css/dashboard.css";

const ParticipantsHome = () => {
  const [totalEmployees, setTotalEmployees] = useState(1); // Default to 1 

    return (
      <div className="dashboard-main">
        <Form setTotalEmployees={setTotalEmployees} />
        <EmployeeTable totalEmployees={totalEmployees} />
      </div>
    );
  };

export default ParticipantsHome;
