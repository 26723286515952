// api.js
const host = `${process.env.REACT_APP_BE_URL}`
const makeRequest = async (method, url, body, redirect) => {
  try {
        const myHeaders = new Headers();
        let token = "";
        if (localStorage.getItem("accessToken") !== null) {
          token = localStorage.getItem("accessToken");
        }
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: method,
            headers: myHeaders,
            body: body,
            redirect: redirect
        }
        const data_url = host+url;
        const response = await fetch(
            data_url,
            requestOptions
        )
        return response;
  } catch (error) {
    console.error('API request failed:', error.response.data);
    if (error.response.data.detail === "Given token not valid for any token type") {
      localStorage.removeItem("Authorization");
      window.location.reload();
    }
    throw error;
  }
};

export { makeRequest };
