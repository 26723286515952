import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    coursesData: [],
    selectedCourse: {},
    selectedPieChart: {},
    selectedBarGraph: {},
    comments: [],
    sessionFeedback: [],
    },
  reducers: {
    addCourse: (state, action) => {
      state.coursesData = action.payload.data;
    },
    addSelectedCourse: (state, action) => {
        state.selectedCourse = action.payload;
    },
    addSelectedPieChart: (state, action) => {
      state.selectedPieChart = action.payload;
    },
    addSelectedBarGraph: (state, action) => {
      state.selectedBarGraph = action.payload;
    },
    addComments: (state, action) => {
      state.comments = action.payload;
    },
    addSessionFeedback: (state, action) => {
      state.sessionFeedback = action.payload;
    }

  },

});

export const { addCourse, addSelectedCourse, addSelectedPieChart, addSelectedBarGraph, addComments, addSessionFeedback } = feedbackSlice.actions;
export default feedbackSlice.reducer;
