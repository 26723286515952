import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Button, Pagination } from "antd";
import CheckTokenExpiration from "../../Components/TokenExpiryCheck/CheckTokenExpiration"
import { addParticipantDetails, setParticipantView } from "../../features/Courses/Participants";
import {useDispatch} from "react-redux";
// Icons
import { GiGraduateCap } from "react-icons/gi";
import { DownOutlined } from "@ant-design/icons";
import { CiUser } from "react-icons/ci";

const ParticipantsTable = ({participantTableData, 
  currentPagination, 
  setCurrentPagination,
  pageSize, setPageSize,
  totalPagination, setTotalPagination
}) => {

  const dispatch = useDispatch()

  const handelButtonClick = (record) => {
    // setIsEditCourseActive(true);
    // setCourseData(record);
    console.log(record);
  };
  const fetchParticipantData = async (id) => {
    try {
        const myHeaders = new Headers();
        let token = "";
        if (localStorage.getItem("accessToken") !== null) {
            token = localStorage.getItem("accessToken");
        }
        myHeaders.append("Authorization", `Bearer ${token}`);
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };
        <CheckTokenExpiration />

        const url = `${process.env.REACT_APP_BE_URL}/api/participant/participant-details/?participant_id=${id}`;
        const data = await fetch(url, requestOptions);
        const response = await data.json();
        dispatch(addParticipantDetails(response))
    } catch {
    }
}

  
  const handleParticipantView = (id) => {
    fetchParticipantData(id)
    dispatch(setParticipantView(true));
}

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "center",
      align: "center",
      //   width: 350,
      render: (text, elems) => {
        let color = "#2AC239";
        if (elems["issueCertificate"].toLowerCase() === "issue certificate") {
          color = "#3185FC";
        } else if (
          elems["issueCertificate"].toLowerCase() === "certificate issued"
        ) {
          color = "#2AC239";
        }
        return (
          <div className="table__name__col" style={{ color: color, cursor: "pointer" }} onClick={()=>handleParticipantView(elems.ID)}>
            <CiUser size={28}  />
            {text}
          </div>
        );
      },
    },
    {
      title: "Employee ID",
      dataIndex: "participantID",
      fixed: "center",
      align: "center",
      key: "participantID",
      render: (text, elems) => {
        return <div className="table__participant__col">{text}</div>;
      },
    },
    {
      title: "Registered at",
      dataIndex: "enrolledOn",
      fixed: "left",
      align: "center",
      key: "enrolledOn",
      render: (text, elems) => {
        return <div className="table__enrolled__col">{text}</div>;
      },
    },
    {
      title: "Place Of Posting",
      key: "Place Of Posting",
      fixed: "left",
      align: "center",
      dataIndex: "Place Of Posting",
      render: (_, { remark }) => {
        let color = "#fff";
        // if (remark.toLowerCase() === "outstanding") {
        //   color = "#2AC239";
        // } else if (remark.toLowerCase() === "excellent") {
        //   color = "#3185FC";
        // } else if (remark.toLowerCase() === "good") {
        //   color = "#06B8A6";
        // }
        return (
          <Tag color={color} className="table__remark__col">
            {remark}
          </Tag>
        );
      },
    },

    {
      title: "Carde",
      key: "issueCertificate",
      fixed: "left",
      align: "center",
      dataIndex: "issueCertificate",
      render: (_, { issueCertificate }) => {
        let color = "#fff";
        // if (issueCertificate.toLowerCase() === "issue certificate") {
        //   color = "#3185FC";
        // } else if (issueCertificate.toLowerCase() === "certificate issued") {
        //   color = "#2AC239";
        // }
        return (
          <Tag color={color} className="table__issueCertificate__col">
            {issueCertificate}
          </Tag>
        );
      },
    },
  ];


  const onChange = (page) => {
    setCurrentPagination(page);
  };

  // console.log("currentPagination: ", currentPagination)
  // console.log("pageSize: ", pageSize)
  // console.log("totalPagination: ", totalPagination)

  const pageSizeChangeHandler = (current, size) => {
    // console.log("current, size: ", current, size)
    setPageSize(size)
  }


  return (
    <div className="participants__table__container">
      <div className="participants__table__box">
        <Table
          columns={columns}
          dataSource={participantTableData}
          pagination={false}
          sticky
        />
      </div>
      <div className="participants__table__pagination">
        <Pagination 
        current={currentPagination} 
        onChange={onChange} 
        pageSize={pageSize}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        showSizeChanger={true}
        onShowSizeChange={pageSizeChangeHandler}
        total={totalPagination} 
        />
        <div className="participants__table__pagination__text">
            {/* <span className="participants__table__pagination__text__completing__date">Completing on : 15-12-2024</span> */}
            <Tag color="#2AC239" style={{marginLeft: 4}}>Completed</Tag>
            <span className="participants__table__pagination__text__completing__msg">*Issue certificates and choose remarks for participants</span>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsTable;
