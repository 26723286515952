import React, { useEffect, useState } from "react";
import { Button, Input, Dropdown, Menu, Space, Spin, Tooltip } from "antd";
import { DownOutlined } from "@ant-design/icons";
import contributiontitle from "../Assets/SVG/participants/contributiontitle.svg";
import contributionLogo from "../Assets/SVG/courseslist/contributionLogo.svg";
import noContributionIcon from "../Assets/SVG/noContributionIcon.svg";
import contributeIcon from "../Assets/icons/image.png";
import { hostname } from "../data";
import ContributeModal from "../Components/Modals/ContributeModal";

const Contributions = () => {
  const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState(0);
  const [contributions, setContributions] = useState([]);
  const [filteredContributions, setFilteredContributions] = useState([]);
  const [refetchAPI, setRefetchAPI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dropDownItems = [
    { key: 1, title: "Newest First" },
    { key: 2, title: "Oldest First" },
    { key: 0, title: "Clear" },
  ];

  const handleItemClick = (item) => {
    setCurrentSelectedDropdown(item.key);
  };

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("accessToken");
        const response = await fetch(
          `${hostname}api/participant/participant-contributions/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.message && data.message === "No data is present for participant level.") {
          setContributions([]);
          setFilteredContributions([]);
        } else {
          setContributions(data);
          setFilteredContributions(data);
        }
      } catch (error) {
        console.error("Error fetching contributions: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchContributions();
  }, [refetchAPI]);

  useEffect(() => {
    let sortedContributions = [...contributions];
    if (currentSelectedDropdown === 1) {
      sortedContributions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (currentSelectedDropdown === 2) {
      sortedContributions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    }
    setFilteredContributions(sortedContributions);
  }, [currentSelectedDropdown, contributions]);

  useEffect(() => {
    const filtered = contributions.filter(contribution =>
      contribution.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contribution.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredContributions(filtered);
  }, [searchTerm, contributions]);

  const menu = (
    <Menu>
      {dropDownItems.map((item) => (
        <Menu.Item key={item.key} onClick={() => handleItemClick(item)} className="search-select-option">
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleFileDownload = (url) => {
    window.open(url, "_blank");
  };

  const truncateDescription = (description) => {
    const words = description.split(" ");
    if (words.length > 55) {
      return words.slice(0, 55).join(" ") + "...";
    }
    return description;
  };

  return (
    <div className="coursesList">
      <div className="coursesList__box">
        <div className="coursesList__box__elem1 enrolledCoursesHeadingMain">
          <div className="coursesList__box__elem1__info enrolledCoursesHeading">
            <div style={{ width: "100%" }} className="enrolled_Courses_left_Container">
              <div className="enrolledCoursesHeadingContainer">
                <img src={contributionLogo} alt="activate" className="coursesList__box__elem1__info__img" />
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <span className="coursesList__box__elem1__info__span"> My Contributions</span>
                  <span>
                    <div className="buttons_div">
                      <ContributeModal setRefetchAPI={setRefetchAPI} />
                    </div>
                  </span>
                </div>
              </div>

              <div>
                <p style={{ fontSize: "14px", fontWeight: "400" }}>Total contributions : <span style={{ fontWeight: "600", color: "#3185FC" }}>{contributions.length}</span></p>
              </div>
              <div style={{ display: "flex", justifyContent: "end", marginTop: "-20px" }}>
                <div className="search_Enrolled_Courses_Container">
                  <Input
                    placeholder="Search here..."
                    style={{ marginLeft: '16rem', background: "#ECF5FF", width: "29rem" }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />

                  <Dropdown
                    className='search-select'
                    style={{ width: 120, marginLeft: 16 }}
                    overlay={menu}
                    trigger={["click"]}
                  >
                    <Space>
                      {currentSelectedDropdown === 0 ? "Sort" : currentSelectedDropdown === 1 ? "Newest First" : "Oldest First"}
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </div>
              </div>

            </div>
          </div>
          {isLoading ? (
            <Spin tip="Loading..." />
          ) : filteredContributions.length === 0 ? (
            <>
              <div style={{ width: "100%" }}>
                <hr style={{ borderTop: "1px solid #B4B4B4", margin: "20px 0" }} />
              </div>
              <div className="enrolled_courses_info_container_Main">
                <div
                  className="enrolled_course"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={noContributionIcon}
                    alt="nocontribution"
                    className="enrolled_course__img"
                  />
                  <p className="enrolled_course_para">No contributions Found</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "100%" }} >
                <hr style={{ borderTop: '1px solid #B4B4B4', margin: "20px 0" }} />
              </div>
              <div className="enrolled_courses_info_container_Main" >
                {filteredContributions.map(contribution => (
                  <div key={contribution.id} className="enrolled_courses_info_subcontainer" >
                    <div className="enrolled_courses_info_heading">
                      <div className="enrolled_courses_info_heading_left">
                        <img src={contributiontitle} alt="Education" style={{ marginRight: "10px" }} />
                        <p>{contribution.subject}</p>
                      </div>
                      <div className="enrolled_courses_info_heading_right">
                        <Button onClick={() => handleFileDownload(contribution.content_url)}>File Download</Button>
                      </div>
                    </div>
                    <div className="enrolled_courses_info_paragraph">
                      <Tooltip title={contribution.description} overlayStyle={{ width: '600px' }}>
                        <p>{truncateDescription(contribution.description)}</p>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contributions;
