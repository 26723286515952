import React, { useState, useEffect } from "react";
import participantsTableIcon from "../../Assets/SVG/participants/participantsTableIcon.svg";
import EnrollParticipantsTable from "./EnrollParticipantsTable";
import ParticipantsTable from "./ParticipantsTable";
import { useSelector } from "react-redux";
import { Input, Dropdown, Space, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import CheckTokenExpiration from "../TokenExpiryCheck/CheckTokenExpiration";

const ParticipantsDetail = () => {
  const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState(0);
  const { Search } = Input;
  const [userSearchInput, setUserSearchInput] = useState("");

  const selectedCourseP = useSelector(state => state.participants.selectedCourseP)
  const [participantTableData, setParticipantTableData] = useState([]);
  //   Pagination
  const [currentPagination, setCurrentPagination] = useState(1);
  const [totalPagination, setTotalPagination] = useState()
  const [pageSize, setPageSize] = useState(8)

  // Table Data
  const data = [
    {
      key: "1",
      name: "Akash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "good",
      issueCertificate: "Certificate Issued",
    },
    {
      key: "2",
      name: "Prakash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "outstanding",
      issueCertificate: "Issue Certificate",
    },
    {
      key: "3",
      name: "Akash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "excellent",
      issueCertificate: "Certificate Issued",
    },
    {
      key: "4",
      name: "Prakash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "outstanding",
      issueCertificate: "Issue Certificate",
    },
    {
      key: "5",
      name: "Akash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "good",
      issueCertificate: "Certificate Issued",
    },
    {
      key: "6",
      name: "Prakash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "outstanding",
      issueCertificate: "Issue Certificate",
    },
    {
      key: "7",
      name: "Akash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "na",
      issueCertificate: "Certificate Issued",
    },
    {
      key: "8",
      name: "Prakash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "outstanding",
      issueCertificate: "Issue Certificate",
    },

    {
      key: "9",
      name: "Prakash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "outstanding",
      issueCertificate: "Issue Certificate",
    },
    {
      key: "10",
      name: "Akash",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "na",
      issueCertificate: "Certificate Issued",
    },
    {
      key: "11",
      name: "Prakash Srivastav",
      participantID: "P_ID#1223",
      enrolledOn: "12-12-2024  05:23 PM",
      remark: "outstanding",
      issueCertificate: "Issue Certificate",
    },
  ];

  // On Page Load
  useEffect(() => {
     //setParticipantTableData(data);
    fetchTableData();
  }, []);

  // fetch Table Data on Page size change & jumping to another page
  useEffect(() => {
    fetchTableData()
  }, [currentPagination, pageSize])


  // Dropdown Handler
  useEffect(() => {
    //fetchTableData();
  }, [currentSelectedDropdown])

  const fetchTableData = async () => {
    try {
      const myHeaders = new Headers();
      let token = "";
      if (localStorage.getItem("accessToken") !== null) {
        token = localStorage.getItem("accessToken");
      }
      myHeaders.append("Authorization", `Bearer ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      const url = apiBuilder();
      const data = await fetch(url, requestOptions);
      const response = await data.json();
      if (data.ok) {
        const table_data = extractTableData(response.data);
        setParticipantTableData(table_data);
        if (response.total_items) {
          setTotalPagination(response["total_items"])
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      setParticipantTableData([]);
    }
  };

  const apiBuilder = () => {
    <CheckTokenExpiration />
    let url = `${process.env.REACT_APP_BE_URL}/api/participant/fetch-participants/?page=${currentPagination}&page_size=${pageSize}`;

    // Newest First
    if (currentSelectedDropdown === 1) {
      url += "&sort_by=desc";
    }
    // Oldest First
    if (currentSelectedDropdown === 2) {
      url += "&sort_by=asc";
    }

    if (userSearchInput.length > 2) {
      if (currentSelectedDropdown === 0) {
        url += `&search=${userSearchInput}`;
      } else {
        url += `&search=${userSearchInput}`;
      }
    }

    return url;
  };

  const extractTableData = (input_arr) => {
    const new_arr = [];
      
    input_arr.forEach((elem, i) => {
      console.log(elem)
      const formatted_datetime = extractDateTime(elem["registered_at"]);
      new_arr.push({
        key: i,
        name: elem["name"],
        ID: elem["id"],
        participantID: elem["emp_id"],
        enrolledOn: elem["registered_at"],
        remark: elem["place_of_posting"],
        issueCertificate:
          elem["cadre"]
      });
    });
    return new_arr;
  };
  

  const extractDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    let hours = date.getHours();
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const minutes = ("0" + date.getMinutes()).slice(-2);

    const formattedTime = `${hours}:${minutes} ${meridiem}`;
    const complete_dt = formattedDate.toString() + "  " + formattedTime.toString();

    return complete_dt
  };

  useEffect(() => {
    if (userSearchInput.length > 2) {
      fetchTableData();
    }
    if (userSearchInput.length === 2) {
      fetchTableData();
    }
  }, [userSearchInput]);

  const handleItemClick = (item) => {
    setCurrentSelectedDropdown(item.key);
  };

  //   Dropdown Items
  const dropDownItems = [
    { key: 1, title: "Newest First" },
    { key: 2, title: "Oldest First" },
    { key: 0, title: "Clear" },
  ];
  const menu = (
    <Menu>
      {dropDownItems.map((item) => (
        <Menu.Item key={item.key} onClick={() => handleItemClick(item)}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="participantstable">
      <div className="participantstable__topbar">
        <div className="participantstable__topbar__elem1">
          <img
            className="participantstable__topbar__elem1__icon"
            src={participantsTableIcon}
            alt="participants"
          />
          <span className="participantstable__topbar__elem1__text">
            Participants
          </span>
        </div>
        <div className="participantstable__topbar__elem2">
          <Search
            placeholder="search here..."
            onChange={(e) => setUserSearchInput(e.target.value)}
            style={{ width: "100%", height: "100%" }}
            className="activeCoursesTable__box__heading__elem2__searchbar__antd"
          />

          <div className="activeCoursesTable__box__heading__elem2__dropdown">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Space>
                {currentSelectedDropdown === 0
                  ? "Sort"
                  : currentSelectedDropdown === 1
                    ? "Newest First"
                    : "Oldest First"}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="participantstable__table">
        {
          selectedCourseP.id ? (<EnrollParticipantsTable />) : (<ParticipantsTable
            participantTableData={participantTableData}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            totalPagination={totalPagination}
            setTotalPagination={setTotalPagination}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />)
        }
      </div>
    </div>
  );
};

export default ParticipantsDetail;
