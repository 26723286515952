import React, { useState } from 'react';
import { Modal, Checkbox, message } from 'antd';
import { ReactComponent as Logo } from "../../Assets/SVG/deactivate.svg";
import { makeRequest } from '../../api/api';

const Deactivate = ({ setIsEditCourseActive, courseId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const onClickHandler = async () => {
    setIsModalOpen(false); // Close the modal first

    try {
      const url = `/api/courses/deactivate-course/${courseId}/`;
      const response = await makeRequest('PATCH', url);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "Course is already deactivated") {
          message.info('Course is already deactivated');
        } else {
          message.success('Deactivated successfully!');
        }
      } else {
        message.error('Failed to deactivate.');
      }
    } catch (error) {
      console.log('Error: ', error);
      message.error('Failed to deactivate.');
    }
  };

  return (
    <>
      <div className='deactivate-btn' onClick={showModal}>
        Deactivate
      </div>
      <Modal
        okText="Continue"
        okButtonProps={{ style: { textAlign: "center", width: "110px", height: "40px", backgroundColor: "#FF4848", fontSize: "1.1vw", borderRadius: "10px", fontWeight: "500", color: "white", marginTop: "0.5rem" } }}
        cancelButtonProps={{ style: { border: "none", textAlign: "center", width: "110px", height: "50px", fontSize: "large", fontWeight: "550", color: "Black", marginTop: "0.5rem" } }}
        title={
          <span className='exitLogo_span'>
            <Logo className='exitLogo' />
            <span style={{ color: 'red', marginTop: "3px" }}>De-Activate the course</span>
          </span>
        }
        visible={isModalOpen}
        onOk={onClickHandler}
        onCancel={handleCancel}
      >
        <p>This course will not be active and participants will no longer be able to contribute to the course.</p>
        <Checkbox style={{ color: 'red', marginTop: "2.5rem" }} onChange={onChange}>Remove the course </Checkbox>
      </Modal>
    </>
  );
};

export default Deactivate;
