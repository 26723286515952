import React, { useState } from 'react'
import NewCourseForm from './NewCourseForm/NewCourseForm'
import AddedCourses from './AddedCourses/AddedCourses'

const AddNewCoursesMenu = ({setCoursesExpand}) => {
  const [disciplinariesList, setDisciplinariesList] = useState([])
  const [editCompetency, setEditCompetency] = useState(null)
  const [primaryCompetencyValue, setPrimaryCompetencyValue] = useState(null); // Track primary competency value

  return (
    <>
      <NewCourseForm 
        setCoursesExpand={setCoursesExpand} 
        disciplinariesList={disciplinariesList} 
        setDisciplinariesList={setDisciplinariesList} 
        editCompetency={editCompetency} 
        setEditCompetency={setEditCompetency}
        primaryCompetencyValue={primaryCompetencyValue} // Pass primary competency value
      />
      {
        disciplinariesList.length>0 && primaryCompetencyValue !== "Functional" && primaryCompetencyValue !== "Behavioral" && (
          <AddedCourses 
            disciplinariesList={disciplinariesList} 
            setDisciplinariesList={setDisciplinariesList} 
            editCompetency={editCompetency} 
            setEditCompetency={setEditCompetency}
          />
        )
      }
    </>
  )
}

export default AddNewCoursesMenu
