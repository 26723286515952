import React from 'react'
import ExitWOSave from "../Modals/ExitWOSave"

const ExitWOSaveBtn = ({setIsEditCourseActive}) => {
  return (
    <div className='exit-wo-save-btn'>
      {/* Exit Without Saving */}
      <ExitWOSave setIsEditCourseActive={setIsEditCourseActive}/>
    </div>
  )
}

export default ExitWOSaveBtn
