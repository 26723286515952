import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/UserSlice/Userslice"
import coursesReducer from "../features/Courses/Courses"
import participantsReducer from "../features/Courses/Participants"
import feedbackReducer from "../features/Feedback/Feedback"
import participantsHomeReducer from "../features/ParticipantsHome/ParticipantsHomeSlice"
import adminHomeReducer from "../features/AdminHome/AdminHomeSlice"
import employeeTableReducer from "../features/ParticipantsHome/EmployeeSlice"

const store = configureStore({
    reducer: {
        user: userReducer,
        courses: coursesReducer,
        participants: participantsReducer,
        feedback: feedbackReducer,
        participantsHome: participantsHomeReducer,
        adminHome: adminHomeReducer,
        employeeTable: employeeTableReducer,
    }
});

export default store;
