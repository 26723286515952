import React, { useEffect, useState } from 'react';
import ButtonIcon from '../../../Assets/SVG/Logos/carbon_certificate.svg';
import ButtonIcon2 from '../../../Assets/SVG/Logos/carbon_certificate2.svg';
import AddCertificate from '../../../Components/Modals/AddCertificate';
import SearchBar from '../../../Assets/icons/searchBar.svg';
import DropIcon from '../../../Assets/icons/dropIcon.svg';
import { Menu, Dropdown } from 'antd';
import AddedCertificateHeader from './AddedCertificateHeader';
import { makeRequest } from "../../../api/api";
import { useNavigate } from 'react-router';

const AddedCertificateBody = () => {
    const [modalVisible, setModalVisible] = useState(false);
    
    const [certificateData, setCertificateData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const navigate = useNavigate(); // Use the useNavigate hook from react-


    const handleAddedCertificatesClick = () => {
        navigate('/home');
      };

    
        const fetchData = async () => {

            try {
                const url = `/api/participant/get-external-certificate/`;
                const response = await makeRequest('get', url); // Pass requestOptions
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); // Parse response body
                setCertificateData(data); // Set certificate data
            } catch (error) {
                console.error(error);
            }
        };
        // useEffect(() => {
        //     if(courseAddedStatus){
        //     // fetchData();
        //     setCourseAddedStatus(false);
        //   }
        // }, [courseAddedStatus]);
    
    
useEffect(() => {
        fetchData();
    }, [deleteSuccess]);

    const handleOpenModal = () => {
        setModalVisible(true);
      };
    
      // Function to close modal
      const handleCloseModal = () => {
        setModalVisible(false);
      };

   
      const menu = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" >Newest First</a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" >Oldest First</a>
          </Menu.Item>
        </Menu>
      );

      const handleSearchChange = (value) => {
        setSearchQuery(value);
        // Here you can also perform any search functionality as the user types
    };

  return (
    <div className='added-certificate-body'>
        <div className='added-certificate-body__header'>
            <div className='added-certificate-body__header__elem1'>
       <div className="added-certificate-body__header__elem1__eleme1">
      <button className="added-certificate-body__header__elem1__eleme1__button1" onClick={handleAddedCertificatesClick}>Back</button>
      
      <div className="added-certificate-body__header__elem1__elem2">
        <img src={ButtonIcon2} alt="Icon" className="icon" />
        <h1 className='added-certificate-body__header__elem1__elem2__title'>Added Certificate</h1>
      </div>
      </div>
      <div className='added-certificate-body__header__elem3__button-container2'>
          <button className='added-certificate-body__header__elem3__button2' onClick={handleOpenModal}>
            <img src={ButtonIcon} alt="Add Certificate Icon" /> Add Certificate
          </button>
        </div>
   </div>
   {/* <div className='added-certificate-body__SecondComponent'>
    <div className='added-certificate-body__SecondComponent__SearchBars'>
    <input
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Search Here.."
        className="added-certificate-body__SecondComponent__SearchBar"
        style={{ backgroundImage: `url(${SearchBar})`, backgroundRepeat: 'no-repeat', backgroundPosition: '10px center', paddingLeft: '35px', border: 'none', outline: 'none', backgroundSize: '20px 20px' }}
    />
</div>

    <div className='added-certificate-body__SecondComponent__Dropdown'>
    <Dropdown overlay={menu}>
    <a className="ant-dropdown-link" href="#">
    Newest first  <img  className ='Dropdown__icon' src={DropIcon} alt="" />
    </a>
  </Dropdown>
  </div>

</div> */}
<hr className='added-certificate-body__hr'></hr>

<div className='added-certificate-body__table'>
    <AddedCertificateHeader   data={certificateData}  deleteSuccess={deleteSuccess} setDeleteSuccess={setDeleteSuccess}/>
</div>
        </div>
        
        <AddCertificate visible={modalVisible} onClose={handleCloseModal} deleteSuccess={deleteSuccess} setDeleteSuccess={setDeleteSuccess} />
      
    </div>
  )
}

export default AddedCertificateBody
