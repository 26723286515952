import React, { useState , useEffect } from 'react';
import '../css/dashboard.css';

const Form = ({ setTotalEmployees }) => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    designation: '',
    totalEmployees: '',  
  });
    // Set initial value for email from localStorage when the component mounts
    useEffect(() => {
        const storedEmail = localStorage.getItem('userEmail');
        const storedName = localStorage.getItem('userName');
        const storedDesignation = localStorage.getItem('userCadre');

        if (storedEmail) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              email: storedEmail,
            }));
          }
          
          if (storedName) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              name: storedName,
            }));
          }
          
          if (storedDesignation) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              designation: storedDesignation,
            }));
          }
        }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const employees = parseInt(formData.totalEmployees, 10) || 1; 
    setTotalEmployees(employees);

    console.log('Form submitted:', formData);
    console.log('Total Employees:', formData.totalEmployees);

      // Clear the totalEmployees field after submission
    setFormData((prevFormData) => ({
      ...prevFormData,
      totalEmployees: '', // Reset the totalEmployees field to an empty string
    }));
  };

  return (
    <div className="form-container">
      <h2 className="form-heading">Training Ledger Form</h2>
      <form className="charge-filing-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email"
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name of PCIT/PDIT/CIT/DIT Charge filing the Form:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Enter the name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="designation">Designation:</label>
          <input
            type="text"
            id="designation"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            required
            placeholder="Enter your designation"
          />
        </div>
        <div className="form-group">
          <label htmlFor="totalEmployees">Total Employees under your Charge:</label>
          <input
            type="number"
            id="totalEmployees"
            name="totalEmployees"
            value={formData.totalEmployees}
            onChange={handleChange}
            required
            placeholder="Enter the number of employees"
          />
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default Form;
