import React, { useEffect, useState } from "react";
import { ConfigProvider, Form, Input, Button, Row, Col, DatePicker, message, Radio, Select, InputNumber } from "antd";
import dayjs from "dayjs"; 
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";

import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";



import tick from "../../Assets/icons/mdi_tick.png";
import { useDispatch, useSelector } from "react-redux";
import { addEditedCourse } from "../../features/Courses/Courses";

const EditCoursesOtherDetail = ({ course }) => {
  // React Redux
  const editedCourse = useSelector((state) => state.courses.editedCourse);
  // console.log(editedCourse)
  const dispatch = useDispatch();

  const [courseDurationDisable, setCourseDurationDisable] = useState(true);
  const [courseCoordinatorNameDisable, setCourseCoordinatorNameDisable] =
    useState(true);
  const [startDateDisable, setStartDateDisable] = useState(true);
  const [endDateDisable, setEndDateDisable] = useState(true);

  const [courseDurationVal, setCourseDurationVal] = useState();
  const [courseCoordinatorNameVal, setCourseCoordinatorNameVal] = useState();
  const [startDateVal, setStartDateVal] = useState(null);
  const [endDateVal, setEndDateVal] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
const [cadres, setCadres] = useState(editedCourse?.cadre || []);
const [selectedCadres, setSelectedCadres] = useState([]);

  const [courseDurationErrorStatus, setCourseDurationErrorStatus] =
    useState(false);
  const [
    courseCoordinatorNameErrorStatus,
    setCourseCoordinatorNameErrorStatus,
  ] = useState(false);
  const [startDateErrorStatus, setStartDateErrorStatus] = useState(false);
  const [endDateErrorStatus, setEndDateErrorStatus] = useState(false);


  const errorMsg = "Invalid Value!!"

  const dateFormat = "YYYY-MM-DD";

  // console.log("editedCourse: ", editedCourse, editedCourse.duration);

  useEffect(() => {
    setCourseDurationVal(editedCourse.duration);
    setCourseCoordinatorNameVal(editedCourse.course_coordinator);
    setStartDateVal(editedCourse.start_date);
    setEndDateVal(editedCourse.end_date);
  }, [editedCourse]);


  const handleEditClick = () => {
    setIsEditing(true);
    setSelectedCadres(cadres);  // Pre-select current cadres
  };
  
  const handleSaveClick = () => {
    setIsEditing(false);
    setCadres(selectedCadres);
    const formData = {
      ...editedCourse,
      cadre: selectedCadres,
    };
    dispatch(addEditedCourse(formData));
  };
  
  const handleCadreChange = (values) => {
    setSelectedCadres(values);
  };


  const onFinish = (values) => {
    console.log(values);
  };

  const dateClickHandler = (value, flag) => {
    if (value !== null) {
      const date = `${value["$y"]}-${value["$M"] + 1}-${value["$D"]}`
      console.log("date: ", date)
      if (flag === "startDate") {
        setStartDateVal(date)
      }
      else if (flag === "endDate") {
        setEndDateVal(date)
      }
    }
    else {
      setStartDateVal(null)
      setEndDateVal(null)
    }
  }

  const btnClickHandler = (flag, elem) => {
    if (elem === "courseDuration") {
      if (flag === "edit") {
        setCourseDurationDisable(!courseDurationDisable);
      }
      else if (flag === "cancel") {
        setCourseDurationVal(editedCourse.duration);
        setCourseDurationDisable(!courseDurationDisable);
        setCourseDurationErrorStatus(false)
      }
      else {
        if (courseDurationVal === null || courseDurationVal === 0) {
          setCourseDurationErrorStatus(true);
        }
        else {
          const formData = {
            ...editedCourse,
            duration: courseDurationVal,
          };
          dispatch(addEditedCourse(formData));

          setCourseDurationDisable(!courseDurationDisable);
          setCourseDurationErrorStatus(false)
        }
      }
    }
    else if (elem === "courseCoordinatorName") {
      if (flag === "edit") {
        setCourseCoordinatorNameDisable(!courseCoordinatorNameDisable);
      }
      else if (flag === "cancel") {
        setCourseCoordinatorNameVal(editedCourse.course_coordinator);
        setCourseCoordinatorNameDisable(!courseCoordinatorNameDisable);
        setCourseCoordinatorNameErrorStatus(false)
      }
      else {
        console.log("courseCoordinatorNameVal: ", courseCoordinatorNameVal, typeof (courseCoordinatorNameVal), courseCoordinatorNameVal.length)
        if (courseCoordinatorNameVal.length === 0) {
          setCourseCoordinatorNameErrorStatus(true);
        }
        else {
          const formData = {
            ...editedCourse,
            course_coordinator: courseCoordinatorNameVal,
          };

          dispatch(addEditedCourse(formData));

          setCourseCoordinatorNameDisable(!courseCoordinatorNameDisable);
          setCourseCoordinatorNameErrorStatus(false)
        }
      }
    }
    else if (elem === "startDate") {
      if (flag === "edit") {
        setStartDateDisable(!startDateDisable);
      }
      else if (flag === "cancel") {
        setStartDateVal(editedCourse.start_date);
        setStartDateDisable(!startDateDisable);
        setStartDateErrorStatus(false)
      }
      else {
        if (startDateVal === null) {
          setStartDateErrorStatus(true);
        }
        else {
          const formData = {
            ...editedCourse,
            start_date: startDateVal,
          };

          dispatch(addEditedCourse(formData));


          setStartDateDisable(!startDateDisable);
          setStartDateErrorStatus(false)
        }
      }
    }
    else if (elem === "endDate") {
      if (flag === "edit") {
        setEndDateDisable(!endDateDisable);
      }
      else if (flag === "cancel") {
        setEndDateVal(editedCourse.end_date);
        setEndDateDisable(!endDateDisable);
        setEndDateErrorStatus(false)
      }
      else {
        console.log("endDateVal: ", endDateVal)
        if (endDateVal === null) {
          setEndDateErrorStatus(true);
        }
        else {
          const formData = {
            ...editedCourse,
            end_date: endDateVal,
          };

          dispatch(addEditedCourse(formData));

          setEndDateDisable(!endDateDisable);
          setEndDateErrorStatus(false)
        }
      }
    }
  };


  const handleChange = (index, event) => {
    const newCadres = [...cadres];
    newCadres[index] = event.target.value;
    setCadres(newCadres);
  };
  const cadreOfParticipantsArr = [
    "PR CCIT", "CCIT", "PR CIT", "CIT", "MTS", "Addl/Joint CIT",
    "DC/ACIT", "ITO", "AO", "ITI", "PS", "Sr TA", "TA", "Steno", "Notice Server",
];

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              //   labelColor: "#3185FC",
              labelRequiredMarkColor: "#3185FC",
            },
          },
        }}
      >
        <Form
          name="newCourseForm"
          onFinish={onFinish}
          initialValues={{
            courseTitle: "",
            courseDescription: "",
          }}
        //   layout="vertical"
        >
          <Form.Item
            label="Course Duration"
            name="courseDuration"
          >
            <div className="edit__courses__edit__btn">
              <div className="edit__courses__error__msg" >
                <InputNumber
                  min={0}
                  value={courseDurationVal}
                  onChange={(e) => setCourseDurationVal(e)}
                  disabled={courseDurationDisable}
                  status={courseDurationErrorStatus ? "error" : ""}
                />
                {courseDurationErrorStatus && errorMsg}
              </div>
              {/* {courseDurationDisable ? (
                <button
                  className="edit__courses__no__btn"
                  onClick={() => btnClickHandler("edit", "courseDuration")}
                >
                  <CiEdit size={25} />
                </button>
              ) : (
                <div className="edit__courses__edit__cancel__btn">
                  <button
                    className="edit__courses__no__btn"
                    onClick={() => btnClickHandler("submit", "courseDuration")}
                  >
                    <GiCheckMark size={20} color={"green"} />
                  </button>

                  <button
                    className="edit__courses__no__btn"
                    onClick={() => btnClickHandler("cancel", "courseDuration")}
                  >
                    <RxCross2 size={20} color={"red"} />
                  </button>
                </div>
              )} */}
            </div>
          </Form.Item>

          <Form.Item
            label="Course Coordinator Name"
            name="courseCoordinator"
          >
            <div className="edit__courses__edit__btn">
              <div className="edit__courses__error__msg" >
                <Input
                  style={{
                    width: "350px",
                    background: "#EBF5FF",
                    height: "50px",
                    marginTop:"-10px"
                  }}
                  value={courseCoordinatorNameVal}
                  status={courseCoordinatorNameErrorStatus ? "error" : ""}
                  placeholder="Write course coordinator name..."
                  disabled={courseCoordinatorNameDisable}
                  onChange={(e) => setCourseCoordinatorNameVal(e.target.value)}
                />
                {courseCoordinatorNameErrorStatus && errorMsg}
              </div>

              {/* {courseCoordinatorNameDisable ? (
                <button
                  className="edit__courses__no__btn"
                  onClick={() =>
                    btnClickHandler("edit", "courseCoordinatorName")
                  }
                >
                  <CiEdit size={25} />
                </button>
              ) : (
                <div className="edit__courses__edit__cancel__btn">
                  <button
                    className="edit__courses__no__btn"
                    onClick={() =>
                      btnClickHandler("submit", "courseCoordinatorName")
                    }
                  >
                    <GiCheckMark size={20} color={"green"} />
                  </button>

                  <button
                    className="edit__courses__no__btn"
                    onClick={() =>
                      btnClickHandler("cancel", "courseCoordinatorName")
                    }
                  >
                    <RxCross2 size={20} color={"red"} />
                  </button>
                </div>
              )} */}
            </div>
          </Form.Item>

          <Row>
            <Col>
              <Form.Item
                label="Start"
                name="startDate"
                style={{ marginRight: 80 }}
              >
                <div className="edit__courses__edit__btn">
                  <div className="edit__courses__error__msg" >
                    <DatePicker
                      style={{ background: "#EBF5FF" }}
                      // placeholder="Start Date"
                      disabled={startDateDisable}
                      defaultValue={
                        startDateVal !== null ? dayjs(startDateVal, dateFormat) : undefined
                      }
                      // defaultValue={dayjs("2024-06-11", dateFormat)}
                      // value={dayjs("2024-06-11", dateFormat)}
                      // value={dayjs(startDateVal, dateFormat)}

                      // defaultValue={dayjs(`${startDateVal}`, dateFormat)}
                      onChange={(value) => dateClickHandler(value, "startDate")}
                    />
                    {startDateErrorStatus && errorMsg}
                  </div>
                  {/* {startDateDisable ? (
                    <button
                      className="edit__courses__no__btn"
                      onClick={() => btnClickHandler("edit", "startDate")}
                    >
                      <CiEdit size={25} />
                    </button>
                  ) : (
                    <div className="edit__courses__edit__cancel__btn">
                      <button
                        className="edit__courses__no__btn"
                        onClick={() => btnClickHandler("submit", "startDate")}
                      >
                        <GiCheckMark size={20} color={"green"} />
                      </button>

                      <button
                        className="edit__courses__no__btn"
                        onClick={() => btnClickHandler("cancel", "startDate")}
                      >
                        <RxCross2 size={20} color={"red"} />
                      </button>
                    </div>
                  )} */}
                </div>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="End"
                name="endDate"
              >
                <div className="edit__courses__edit__btn">
                  <div className="edit__courses__error__msg" >
                    <DatePicker
                      style={{ background: "#EBF5FF" }}
                      placeholder="End Date"
                      disabled={endDateDisable}
                      defaultValue={
                        endDateVal !== null ? dayjs(endDateVal, dateFormat) : undefined
                      }
                      onChange={(value) => dateClickHandler(value, "endDate")}
                    />
                    {endDateErrorStatus && errorMsg}
                  </div>
                  {/* {endDateDisable ? (
                    <button
                      className="edit__courses__no__btn"
                      onClick={() => btnClickHandler("edit", "endDate")}
                    >
                      <CiEdit size={25} />
                    </button>
                  ) : (
                    <div className="edit__courses__edit__cancel__btn">
                      <button
                        className="edit__courses__no__btn"
                        onClick={() => btnClickHandler("submit", "endDate")}
                      >
                        <GiCheckMark size={20} color={"green"} />
                      </button>

                      <button
                        className="edit__courses__no__btn"
                        onClick={() => btnClickHandler("cancel", "endDate")}
                      >
                        <RxCross2 size={20} color={"red"} />
                      </button>
                    </div>
                  )} */}
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className='content__footer__left'>
  <div className='content__footer__left__cadre' style={{ color: "black", display:"flex", gap:"10px" }}>
    Cadre Details:
    {isEditing ? (
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Select cadres"
        value={selectedCadres}
        onChange={handleCadreChange}
        allowClear
      >
        {cadreOfParticipantsArr.map((elem, i) => (
          <Select.Option key={i} value={elem}>
            {elem}
          </Select.Option>
        ))}
      </Select>
    ) : (
      cadres.map((cadre, index) => (
        <div key={index} className='content__footer__left__cadre-chip'>
          {cadre}
        </div>
      ))
    )}
    {!isEditing ? (
      <Button onClick={handleEditClick} style={{ marginLeft: "10px", height: "20px" }} icon={<CiEdit />}>
        {/* Unicode character for a pencil */}
      </Button>
    ) : (
      <Button onClick={handleSaveClick} style={{ marginLeft: "10px" }}>
        Save
      </Button>
    )}
  </div>
</div>


        <div className='course_details_container '>
          <div className='content__footer__left__cadre' style={{ color: "black", fontSize: "12px" }}>Competencies ({editedCourse.course_competencies_count})</div>
          <div className="enrolled_courses_compentencies">
            {
              editedCourse?.secondary_competencies?.map((course, index) => (
                index % 4 === 0 ?
                  <div key={index} className='enrolled_courses_compentencies_group'>
                    <div className='enrolled_courses_compentencies_item'>
                      <img src={tick} alt="Education" style={{ marginRight: "10px" }} />
                      <p>{course}</p>
                    </div>
                  </div>
                  :
                  <div key={index} className='enrolled_courses_compentencies_item'>
                    <img src={tick} alt="Education" style={{ marginRight: "10px" }} />
                    <p>{course}</p>
                  </div>
              )).reduce((acc, curr, index, array) => {
                // Group items into sets of four
                if (index % 4 === 0) {
                  acc.push(array.slice(index, index + 4));
                }
                return acc;
              }, []).map((group, index) => (
                <div key={index} className='enrolled_courses_compentencies_group'>
                  {group}
                </div>
              ))
            }
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default EditCoursesOtherDetail;
