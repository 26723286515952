import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CheckTokenExpiration from "../../Components/TokenExpiryCheck/CheckTokenExpiration";
// import {bearerToken as userToken} from "../../Utility/Token"



export const enrollmentAnalysisAPI = createAsyncThunk('institutecourseanalysis/fetch', async (input_data) => {
    let userToken = ""
    if (localStorage.getItem("accessToken") != null) {
        userToken = localStorage.getItem("accessToken")
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `Bearer ${userToken}` },
        redirect: "follow"
    };
    <CheckTokenExpiration />
    const res = await fetch(`${process.env.REACT_APP_BE_URL}/api/participant/enrollment-analysis/?year=${input_data.year}&role_name=${input_data.selectedRole}`, requestOptions);
    const data = await res.json();
    return data;
});

export const courseAnalysisAPI = createAsyncThunk('courseanalysis/fetch', async (input_data) => {
    let userToken = ""
    if (localStorage.getItem("accessToken") != null) {
        userToken = localStorage.getItem("accessToken")
    }

    const requestOptions = {
        method: "GET",
        headers: { Authorization: `Bearer ${userToken}` },
        redirect: "follow"
    };
    <CheckTokenExpiration />
    const res = await fetch(`${process.env.REACT_APP_BE_URL}/api/institutes/institute-wise-course-analysis/?role_name=${input_data.selectedRole}`, requestOptions);
    const data = await res.json();
    return data;
});

const adminHomeSlice = createSlice({
    name: 'adminHome',
    initialState: {
        // Enrollment Analysis
        enrollmentAnalysisAPIStatus: "idle",
        enrollmentAnalysisXAxisData: [],
        enrollmentAnalysisYAxisData: [],

        // Course Analysis
        courseAnalysisAPIStatus: "idle",
        courseAnalysisData: {}
    },
    extraReducers: (builder) => {
        builder
            // Enrollment Analysis
            .addCase(enrollmentAnalysisAPI.pending, (state) => {
                state.enrollmentAnalysisAPIStatus = 'pending';
                state.enrollmentAnalysisXAxisData = [];
                state.enrollmentAnalysisYAxisData = [];
            })
            .addCase(enrollmentAnalysisAPI.fulfilled, (state, action) => {
                state.enrollmentAnalysisAPIStatus = 'fulfilled';
                const xAxis = []
                const yAxis = []
                action.payload.forEach((elem) => {
                    if (elem.hasOwnProperty("month")) {
                        xAxis.push(elem["month"])
                    }
                    if (elem.hasOwnProperty("total_participants")) {
                        yAxis.push(elem["total_participants"])
                    }
                })
                state.enrollmentAnalysisXAxisData = xAxis
                state.enrollmentAnalysisYAxisData = yAxis

            })
            .addCase(enrollmentAnalysisAPI.rejected, (state) => {
                state.enrollmentAnalysisAPIStatus = 'rejected';
                state.enrollmentAnalysisXAxisData = [];
                state.enrollmentAnalysisYAxisData = [];
            })

            // Course Analysis
            .addCase(courseAnalysisAPI.pending, (state) => {
                state.courseAnalysisAPIStatus = 'pending';
                state.courseAnalysisData = {}
            })
            .addCase(courseAnalysisAPI.fulfilled, (state, action) => {
                state.courseAnalysisAPIStatus = 'fulfilled';
                state.courseAnalysisData = action.payload

            })
            .addCase(courseAnalysisAPI.rejected, (state) => {
                state.courseAnalysisAPIStatus = 'rejected';
                state.courseAnalysisData = {}
            })
    }
})

export default adminHomeSlice.reducer