import React, { useState } from 'react';
import { Modal, Button, Tooltip } from 'antd';
import ButtonIcon2 from '../../../Assets/SVG/Logos/carbon_certificate3.svg';
import Institute from '../../../Assets/SVG/Logos/institution.svg';
import Timer from '../../../Assets/SVG/Logos/split.svg';
import Cross from '../../../Assets/SVG/Logos/cross.svg';
import { makeRequest } from "../../../api/api";
import AddedCertificateEmptyState from './AddedCertificateEmptyState';

const AddedCertificateHeader = ({ data, deleteSuccess, setDeleteSuccess }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const formatDuration = (hours) => {
        const formattedHours = String(hours).padStart(2, '0');
        return `${formattedHours} Hours`;
    };

    const showModal = (certificate) => {
        setSelectedCertificate(certificate);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        if (selectedCertificate) {
            await handleDelete(selectedCertificate.id);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedCertificate(null);
    };

    const handleDelete = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE1ODU4MzM4LCJpYXQiOjE3MTU4NTUzMzgsImp0aSI6ImE4YjI5NWRkMDZjYTQ4MThhMTYyNTIxMTIxZDlmY2E4IiwidXNlcl9pZCI6MTE5fQ.Mx6P8K2gS1skbVRQSgWihz-ioU6ICk-IVMGxf9sB704");
        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow"
        };
        console.log(id);

        try {
            const response = await makeRequest("DELETE", `/api/participant/delete-external-certificate/?id=${id}`, myHeaders, "follow");
            const result = await response.json();
            console.log(result);
            setDeleteSuccess(!deleteSuccess);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            handleCancel();
        }
    };

    if (!data || data.length === 0) {
        return <AddedCertificateEmptyState />;
    }

    return (
        <div className='Scroll-top'>
            {data.map((certificate, index) => (
                <div key={index} className='added-certificate-header'>
                    <div className='added-certificate-header__elem1'>
                        <div className="added-certificate-header__elem1__elem2">
                            <img src={ButtonIcon2} alt="Icon" className="AddedCertificateHeader__icon" />
                            <h1 className='added-certificate-header__elem1__elem2__title'>{certificate?.course_name}</h1>
                        </div>
                        <div className='added-certificate-header__elem1__elem3'>
                            <button className='added-certificate-header__elem1__button' onClick={() => showModal(certificate)}>
                                <img src={Cross} alt="Cross Icon" className="cross-icon" style={{ width: '20px', height: '20px' }} />
                                <span>Remove</span>
                            </button>
                        </div>
                    </div>
                    <div className='added-certificate-header__elem1__elem4'>
                        <Tooltip
                            title={certificate?.course_description}
                            placement="top"
                            overlayStyle={{ maxWidth: '400px' }} // Adjust the width as per your requirement
                        >
                            <p className='added-certificate-header__elem1__elem4__para'>
                                {certificate?.course_description.split(' ').slice(0, 30).join(' ')}{certificate?.course_description.split(' ').length > 30 ? '...' : ''}
                            </p>
                        </Tooltip>
                    </div>
                    <div className='added-certificate-header__elem1__elem5'>
                        <div className='added-certificate-header__elem1__elem5__elem1'>
                            <img src={Timer} alt="Certificate Icon" className="added-certificate-header__elem1__elem5__elem1__icon" />
                            <h3 className='added-certificate-header__elem1__elem5__elem1__title'>{formatDuration(certificate?.duration)}</h3>
                        </div>
                        <div className='added-certificate-header__elem1__elem5__elem2'>
                            <img src={Institute} alt="Certificate Icon" className="added-certificate-header__elem1__elem5__elem1__icon" />
                            <h3 className='added-certificate-header__elem1__elem5__elem2__title'>{certificate?.institute_name}</h3>
                        </div>
                    </div>
                </div>
            ))}
            <Modal
                title={<span className="custom-modal-title">Remove this Certificate</span>}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Remove"
                cancelText="Cancel"
                okButtonProps={{ className: 'custom-remove-button' }}
            >
                <p>Do you want to remove this certificate? It will reduce your man hours as per the duration of the removed course certificate. "{selectedCertificate?.course_name}"?</p>
            </Modal>
        </div>
    );
};

export default AddedCertificateHeader;
