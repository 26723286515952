import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Input } from 'antd';
import { FiEdit } from "react-icons/fi";

import { useDispatch, useSelector } from 'react-redux';
import { addEditedCourse } from '../../features/Courses/Courses';

const EditTitle = () => {
  const dispatch = useDispatch()
  const editedCourse = useSelector((state) => state.courses.editedCourse);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editTitle, seteditTitle] = useState(selectedCourse.name);

  useEffect(() => {
    // Set initial value of editTitle when selectedCourse changes
    seteditTitle(selectedCourse.name);
  }, [selectedCourse]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);

    const formData = {
      ...editedCourse,
      name: editTitle,
    };

    dispatch(addEditedCourse(formData))
  };


  const handleCancel = () => {
    setIsModalOpen(false);
    seteditTitle(editedCourse.name)
    const formData = {
      ...editedCourse,
      name: selectedCourse.name,
    };
    dispatch(addEditedCourse(formData))
  };

  const handleInputChange = (e) => {
    seteditTitle(e.target.value);
  };

  return (
    <div className=''>
      <>
        <Button type="primary" onClick={showModal}>
          <FiEdit />
        </Button>
        <Modal
          styles={{ padding: "1rem", marginLeft: "-1rem" }}
          title="Edit Title"
          open={isModalOpen}
          onOk={handleOk}
          okText="Save Changes"
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { textAlign: "center", width: "10vw", height: "6vh", backgroundColor: "#FFE60A", fontSize: "1.1vw", borderRadius: "0.3vw", fontWeight: "500", color: "black", marginTop: "0.5rem" } }}
          closeIcon={<CloseOutlined style={{ color: 'red' }} />}

          width={"70rem"}>
          <Input.TextArea style={{ backgroundColor: "#EBF5FF" }} value={editTitle} onChange={handleInputChange} />
        </Modal>
      </>
    </div>
  );
};

export default EditTitle;