import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Dashboard from './pages/Dashboard';
import './css/index.css';
import './App.css';

import ParticipantSignupPage from './pages/Participants/SignupPage';
import ParticipantsLoginPage from './pages/Participants/LoginPage';
import CheckTokenExpiration from './Components/TokenExpiryCheck/CheckTokenExpiration';


function App() {
  return (
    <div className="App">
      <Router>
        <CheckTokenExpiration />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="*" element={<Dashboard />} />
          <Route path="/signup/participants" element={<ParticipantSignupPage />} />
          <Route path="/login/participants" element={<ParticipantsLoginPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
