import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk to send data to the API
export const fetchemployeeTableData = createAsyncThunk(
  'employeeTable/submitData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetch('https://income-tax-backend-1089483309308.asia-south1.run.app/api/courses/employee-learning-data/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to submit employee learning data');
      }

      return await response.json(); // Assuming API responds with the result
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch employee learning data from the new API
export const fetchEmployeeLearningData = createAsyncThunk(
  'employeeTable/fetchEmployeeLearningData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://income-tax-backend-1089483309308.asia-south1.run.app/api/courses/get-employee-learning-data/', {
        method: 'GET', // Assuming the method is GET
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch employee learning data');
      }

      return await response.json(); // Assuming API responds with the result
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch employee data by employee code
export const fetchEmployeeCodeData = createAsyncThunk(
  'employeeTable/fetchEmployeeCodeData',
  async (employee_code, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://income-tax-backend-1089483309308.asia-south1.run.app/api/courses/employeecodelookup/?employee_code=${employee_code}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch employee data by code');
      }

      return await response.json(); // Assuming API responds with the result
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
  loading: false,
  error: null,
  success: false,
  data: [],
  employeeLearningData: [],
  employeeCodeData:{},
};

// Redux slice
const employeeTableSlice = createSlice({
  name: 'employeeTable',
  initialState,
  reducers: {
    setEmployeeCodeData: (state, action) => {
      state.employeeCodeData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchemployeeTableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchemployeeTableData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload; 
      })
      .addCase(fetchemployeeTableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEmployeeLearningData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeLearningData.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeLearningData = action.payload; 
      })
      .addCase(fetchEmployeeLearningData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
       // Handle fetching employee data by code
       .addCase(fetchEmployeeCodeData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeCodeData.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeCodeData = action.payload; 
      })
      .addCase(fetchEmployeeCodeData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default employeeTableSlice.reducer;
