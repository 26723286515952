// import React, { useState } from 'react';
// import { Button, Modal,  } from 'antd';


// const AddCompetencyModal = ({ visible, onOk, onCancel }) => {
//     return (
//         <Modal
//             className='coursesAddModal'
//             title="Add Competency"
//             visible={visible}
//             onOk={onOk}
//             onCancel={onCancel}
//             footer={[
//                 <Button style={{ padding: "10px 30px", height: "auto", background: "#FFE60A", color: "black" }} key="submit" type="primary" onClick={onOk}>
//                     Save Competency
//                 </Button>,
//             ]}
//         >
//             <div className='coursesAddModal__divContainer'>
//                 <div className='coursesAddModal__divContainer__header'>Competency name</div>
//                 <input className='coursesAddModal__divContainer__custom-input' placeholder="Search" />
//             </div>
//         </Modal>
//     );
// }

// export default AddCompetencyModal



import React, { useEffect, useState } from 'react';
import { Button, Modal,  } from 'antd';


const AddCompetencyModal = ({ visible, onOk, onCancel, disciplinariesList, setDisciplinariesList, editCompetency, setEditCompetency }) => {
    const [userInput, setUserInput] = useState("")
    const [messageErrorStatus, setMessageErrorStatus] = useState("")

    useEffect(() => {
        if(messageErrorStatus.length>1){
            setTimeout(() => {
                setMessageErrorStatus("")
            }, [4000])
        }
    }, [messageErrorStatus])


    const onOkClick= () => {
        if(userInput.length>2){

            if(editCompetency !== null){
                // Update the Competency value in the array
                setDisciplinariesList(prevList => {
                    const newList = [...prevList]; 
                    newList[editCompetency]["courseName"] = userInput; 
                    return newList; 
                });

                setEditCompetency(null)
            }
            else{
                const newList = {
                    courseName: userInput,
                    status: "Competency",
                    author: null,
                    type: null,
                    time: null
                }
                // Add New Competency
                setDisciplinariesList((prevList) => 
                    [...prevList, newList]
                )
            }
            setUserInput("")
            onOk()
        }
        else{
            setMessageErrorStatus("Please Enter Competency name!")
        }
    }

    const onCancelClickHandler = () => {
        if(editCompetency !== null){
            setEditCompetency(null)
        }
        setMessageErrorStatus("")
        setUserInput("")
        onCancel()
    }

    useEffect(() => {
        if(editCompetency !== null){
            setUserInput(disciplinariesList[editCompetency]["courseName"])
        }
    }, [editCompetency])

    return (
        <Modal
            className='coursesAddModal'
            title="Add Competency"
            visible={visible}
            // onOk={onOk}
            onCancel={onCancelClickHandler}
            footer={[
                <Button style={{ padding: "10px 30px", height: "auto", background: "#FFE60A", color: "black" }} key="submit" type="primary" 
                onClick={onOkClick}
                >
                    Save Competency
                </Button>,
            ]}
        >
            <div className='coursesAddModal__divContainer'>
                <div  style={{fontSize: 16}}className='coursesAddModal__divContainer__header'>Competency name</div>
                <input className='coursesAddModal__divContainer__custom-input' placeholder="Enter competency name..." 
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                />
                <div style={{color: "red", fontSize: 13}}>{messageErrorStatus}</div>
            </div>
        </Modal>
    );
}

export default AddCompetencyModal



