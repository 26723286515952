import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import from 'react-router-dom'

const CheckTokenExpiration = () => {
    console.log("Checking Token")
    const navigate = useNavigate();
    useEffect(() => {
        const isTokenExpired = (token) => {
            if (!token) return true; // If no token provided, consider it expired
            const tokenPayload = parseJwt(token);
            const currentTime = Math.floor(Date.now() / 1000);
            console.log(tokenPayload.exp < currentTime)
            if (tokenPayload.exp < currentTime) {
                const userRole = localStorage.getItem("userRole");
                if (userRole === "participant") {
                   navigate("/login/participants");
                } else {
                    navigate("/");
                }
            };
        };


        const parseJwt = (token) => {
            if (!token) return null;
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        };

        const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage

        if (true) {
            // Redirect to login page
            isTokenExpired(accessToken)
        }
    }, [navigate]); // Include navigate in the dependency array

    return null;
};

export default CheckTokenExpiration;
