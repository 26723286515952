import React from 'react'

const EditDis = ({ showModal }) => {
  return (
    <div onClick={showModal} className='edit-dis'>
      Edit
    </div>
  )
}

export default EditDis
