import React from 'react'
import Icon from '../../../Assets/icons/PieIcon.svg'
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { useSelector } from 'react-redux';
const SessionPieChart = () => {
  const sessionFeedback = useSelector(state => state.feedback.sessionFeedback)
  const colorMap = {
    5: "#2AC239", // excellent
    4: "#95FFA0", // good
    3: "#FFCA21", // satisfactory
    2: "#FF8484", // improvement
    1: "#FF4848", // poor
    "not given": "#B4B4B4",
  };

  const data = [
    {
      type: "Excellent",
      color: "#2AC239",
    },
    {
      type: "Good",
      color: "#95FFA0",
    },
    {
      type: "Satisfactory",
      color: "#FFCA21",
    },
    {
      type: "Needs Improvement",
      color: "#FF8484",
    },
    {
      type: "Poor",
      color: "#FF4848",
    },
    {
      type: "Remaining ",
      color: "#B4B4B4",
    },
  ];

  const renderTooltipContent = (data) => {
    const { payload } = data;
    if (payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            color: "#414D55",
            boxShadow: "0px 2px 40px rgba(0, 0, 0, 0.10)",
            borderRadius: 8,
          }}
        >
          <p style={{ margin: 0 }}>{`${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <div style={{ display: "flex", gap: "1.5vw", flexWrap: "wrap",alignItems:"center" }}>

      {sessionFeedback["session_feedback_data"].map((session) => (


        <div className="sessionpiechart">
          <div className="sessionpiechart__header">
            <img className="Dropdown__icon" src={Icon} alt=""></img>
            {session.session_name}
          </div>
          <div className="sessionpiechart__secondHeading">{session.speaker_name}</div>

          <div className="sessionpiechart__pie__info">
            <div
              className="sessionpiechart__pie__info__ratings"
            >
              <div className="sessionpiechart__pie__info__ratings__title">
                Total Responses
              </div>
              <div className='sessionpiechart__pie__info__ratings__value'>
                <>
                  <span style={{ fontSize: "20px" }}>{session.total_feedback}</span>
                </>
                <span style={{ color: "#B4B4B4", fontSize: "20px" }}>/</span>
                <span style={{ color: "#B4B4B4", fontSize: "20px" }}>{sessionFeedback.total_participants}</span>
              </div>
              <div className="sessionpiechart__pie__info__line" >
                <span className='sessionpiechart__pie__info__line__span1'>Majority</span>
                <span className='sessionpiechart__pie__info__line__span'>
                  {session.pie_chart_data
                    .filter(entry => entry.type !== "not given")
                    .sort((a, b) => b.percentage - a.percentage)
                    .slice(0, 2) // Select top two percentages
                    .map((entry, index) => (
                      <span key={`majority-${index}`}>
                        <span
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            display: "inline-block",
                            backgroundColor: colorMap[entry.type],
                            marginRight: "5px",
                          }}
                        />
                        {data.find(item => item.color === colorMap[entry.type])?.type}
                        {index < 1 && ", "}
                      </span>
                    ))}
                </span>
              </div>
            </div>


            <div className="sessionpiechart__pie__info__Piecharts">
              <PieChart width={200} height={150}>
                <Pie
                  data={session.pie_chart_data}
                  dataKey="percentage"
                  nameKey="type"
                  cx="60%"
                  cy="40%"
                  outerRadius={50}
                  innerRadius={25}
                  label={false}
                  labelLine={false}
                >
                  {session.pie_chart_data?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorMap[entry.type]} />
                  ))}
                </Pie>
                <Tooltip content={renderTooltipContent} />
              </PieChart>
            </div>
          </div>

        </div>
      ))}
    </div>
  );
};

export default SessionPieChart 
