import React, { useState } from 'react';
import { SlGraduation } from "react-icons/sl";
import EditTitle from "../Modals/EditTitle";
import DeactivateBtn from '../Buttons/DeactivateBtn';

import { useSelector } from 'react-redux';
const EditCoursesHeader = ({ setIsEditCourseActive }) => {
    const editedCourse = useSelector((state) => state.courses.editedCourse);

    return (
        <div className='editcoursesheader'>
            <div className='editcoursesheader__heading'>
                <SlGraduation />
                <span>{editedCourse.name}</span>
                <div className='editcoursesheader__heading__edit'>
                    <EditTitle />
                </div>
            </div>
            <DeactivateBtn setIsEditCourseActive={setIsEditCourseActive} courseId={editedCourse.id}/>
        </div>
    );
}

export default EditCoursesHeader;
