import { useState } from "react";
import { Input } from "antd";
import { ReactComponent as FeedbackLogo } from "../../Assets/SVG/feedback_logo.svg"
import ChooseCourseModal from "./ChooseCourseModal";

import { useSelector } from 'react-redux';

const { Search } = Input;

const FeedbackHeader = () => {
    const [modalVisible, setModalVisible] = useState(false);

    const selectedCourse = useSelector(state => state.feedback.selectedCourse);

    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const placeholder = selectedCourse.hasOwnProperty("name") ? selectedCourse.name : "Select Course...";

    return (
        <div className="feedback-header">
            <div className="feedback-header__title">
                <FeedbackLogo />
                <span>Feedback</span>
            </div>
            <div className="feedback-header__button">
                <div className="participants__topbar__elem2">
                    <Input
                        style={{ width: "20vw" }}
                        placeholder={placeholder}
                        size="large"
                    />
                </div>
                <button onClick={handleOpenModal}>Choose Course</button>
            </div>
            <ChooseCourseModal type={"feedback"} visible={modalVisible} setModalVisible={setModalVisible} />
        </div>
    );
}

export default FeedbackHeader;
