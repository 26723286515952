import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Dot } from 'recharts';
import { useSelector } from 'react-redux';

import { ReactComponent as ScaleRatingScale } from "../../../Assets/SVG/charts/scaleratingstats.svg"
import { ReactComponent as Info } from "../../../Assets/SVG/charts/info.svg"

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div 
        className="custom-tooltip"
        style={{
          display:'block',
          width: 80,
          height: 46,
          background: 'rgba(49, 133, 252, 1)',
          boxShadow: '0px 2px 40px rgba(0, 0, 0, 0.10)',
          borderRadius: 8,
          padding:'8px 12px',
          color:'white',
        }}
      >
        <p>{`Participant Count: ${data?.participant_count}`}</p>
      </div>
    );
  }
  return null;
};

const LineGraph = () => {
  const selectedBarGraph = useSelector(state => state.feedback.selectedBarGraph);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handlePointHover = (index) => {
    setHoveredIndex(index);
  };

  return (
    <div className='linegraph'>
      <div className='linegraph__header'>
        <div className='linegraph__header__logo'>
          <ScaleRatingScale />
          <span>Scale Rating Stats</span>
        </div>
        <Info/>
      </div>
      <div className='linegraph__feedback'>
        Feedbacks
      </div>
      <div 
        className='linegraph__graph'
        style={{ position: 'relative' }}
      >
        <LineChart
          width={600}
          height={280}
          data={selectedBarGraph}
          //margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
        >
          <XAxis 
  dataKey="rating" 
  axisLine={false} 
  tickLine={false} 
  tick={{ fill: '#3185FC' }} 
  domain={[-0.5, 6]}  // Adjusted domain to include space between 0 and 1
/>
          <YAxis axisLine={false} tickLine={false} tick={{ fill: '#3185FC' }} />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          <Line 
            type="monotone" 
            dataKey="participant_count" 
            strokeWidth={"2px"} 
            stroke="#FFCA21" 
          >
            {
              selectedBarGraph.map((entry, index) => (
                <Dot
                  key={`dot-${index}`}
                  cx={index}
                  cy={entry?.participant_count}
                  r={hoveredIndex === index ? 6 : 4} // Increase size when hovered
                  fill="#FFCA21"
                  onMouseEnter={() => handlePointHover(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                />
              ))
            }
          </Line>
        </LineChart>
        {hoveredIndex !== null && (
          <div 
            className="hover-gradient" 
            style={{ 
              position: 'absolute', 
              left: (hoveredIndex / (selectedBarGraph?.length - 1)) * 500,
              width: 40, 
              height: 178, 
              background: 'linear-gradient(180deg, rgba(114.14, 172.43, 255, 0) 0%, #72ACFF 100%)' 
            }} 
          />
        )}
      </div>
      <div className='linegraph__legend'>
        <div className='linegraph__legend__rating'>
          Rating Scale
        </div>
      </div>
    </div>
  );
};

export default LineGraph;
