import React, { useState } from 'react';
import { Modal } from 'antd';
import { ReactComponent as ExitLogo } from "../../Assets/SVG/exit.svg"

const ExitWOSave = ({setIsEditCourseActive}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditCourseActive(false)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className='exit-wo-save-btn' onClick={showModal}>
      Exit Without Saving
      </div>
      <Modal  
      okText="Continue"
      okButtonProps={{ style: { textAlign: "center", width: "110px", height: "40px", backgroundColor: "#FF4848", fontSize: "1.1vw", borderRadius: "10px", fontWeight: "500", color:"white", marginTop:"0.5rem"} }}
      cancelButtonProps={{ style: { border:"none",textAlign: "center", width: "110px", height: "50px", fontSize: "large", fontWeight: "550", color:"Black", marginTop:"0.5rem"} }}
      title={
        <span className='exitLogo_span'>
          <ExitLogo className='exitLogo' />
          <span style={{color: 'red', marginTop:"3px"}}>Exit Without Saving Changes?</span>
      </span>
      } open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Seems like you’ve made some changes to the course. Do you still want to exit without saving changes?</p>
        
      </Modal>
    </>
  );
};
export default ExitWOSave;