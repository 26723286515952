import React from 'react'

const ProgressCard = ({type, data, icon}) => {
  return (
    <div className='progresscard'>
      <div className='progresscard__icon'>
        {icon}
      </div>
      <div classname='progresscard__details' style={{fontSize: "0.5vw", fontWeight: "600", color: "#737373"}}>
        {type}
        <div classname='progresscard__details__data' style={{fontSize: "1.1vw", fontWeight: "600", color: "#242325"}}>
            {data}
        </div>
      </div>
    </div>
  )
}

export default ProgressCard
