import React, { useEffect } from 'react';
import FeedbackEmpty from './FeedbackEmpty';
import FeedbackContent from './FeedbackContent';
import { useDispatch, useSelector } from 'react-redux';

const FeedbackContainer = () => {
    const selectedCourse = useSelector((state) => state.feedback.selectedCourse);
    return (
        <div className='feedbackcontainer'>
            {selectedCourse && Object.keys(selectedCourse).length === 0 ? (
                <FeedbackEmpty />
            ) : (
                <FeedbackContent />
            )}
        </div>
    );
};

export default FeedbackContainer;
