// import React, { useEffect } from 'react'
// import CourseDetails from '../../Container/adminhome/CourseDetails'
// import ManHourAnalysis from '../../Container/Participants/Home/ManHourAnalysis'
// import CourseAnalysis from '../../Container/adminhome/CourseAnalysis'

// // Redux
// import { enrollmentAnalysisAPI } from '../../features/AdminHome/AdminHomeSlice'
// import { useSelector, useDispatch } from 'react-redux'

// function AdminHome({ selectedRole }) {
//   // Redux
//   const dispatch = useDispatch()
//   const { enrollmentAnalysisAPIStatus, enrollmentAnalysisXAxisData, enrollmentAnalysisYAxisData } = useSelector((state) => state.adminHome)

//   // On Page Laod
//   useEffect(() => {
//     if (enrollmentAnalysisAPIStatus === 'idle') {
//       dispatch(enrollmentAnalysisAPI({ year: 2024, selectedRole: selectedRole }))
//     }

//   }, [])
//   useEffect(() => {
//     dispatch(enrollmentAnalysisAPI({ year: 2024, selectedRole: selectedRole }))

//   }, [selectedRole])



//   const dateClickHandler = (date) => {
//     if (date !== null) {
//       dispatch(enrollmentAnalysisAPI({ year: date["$y"] }))
//     }
//   }


//   return (
//     <div className="adminhome">
//       <div className="adminhome__container">
//         <div className="adminhome__container__elem1">
//           <ManHourAnalysis title={"Enrollment Analysis"}
//             dateClickHandler={dateClickHandler}
//             xAxisData={enrollmentAnalysisXAxisData}
//             yAxisData={enrollmentAnalysisYAxisData}
//             yAxisTitle={"Course Enrollment"}
//           />
//         </div>
//         <div className="adminhome__container__elem2">
//           <div className="adminhome__container__elem2__box1">
//             <CourseDetails selectedRole={selectedRole} />
//           </div>
//           <div className="adminhome__container__elem2__box2">
//             <CourseAnalysis selectedRole={selectedRole} />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default AdminHome

import React, { useState, useEffect } from "react";
import { Button, message, Table } from "antd"; // Import Ant Design's Table
import { useDispatch } from "react-redux";
import { fetchEmployeeLearningData } from "../../features/ParticipantsHome/EmployeeSlice";
import * as XLSX from "xlsx";
import "../../css/dashboard.css";

const DownloadButton = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("Admin"); // Default to "Admin"
  const [tableData, setTableData] = useState([]); // State for table data
  const [loading, setLoading] = useState(false); // State for loading
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const pageSize = 50; // Number of rows per page

  const headerMapping = {
    name: "Name",
    gender: "Gender",
    group: "Group",
    designation: "Designation",
    place_of_posting: "Place of Posting",
    employee_code: "Employee Code",
    pr_ccit_region: "Pr. CCIT (CCA) Region",
    hod: "HOD (Pr. CIT/ Pr.DIT/ CIT/ DIT)",
    learning_hours_igot: "Learning Hours Completed on iGOT (Hours & minutes)",
    learning_hours_institute:
      "Learning Hours Completed in NADT/ RCs/ MSTUs/ MCTP (Hours & minutes)",
    other_learning_hours: "Other Learning Hours Completed (Hours & minutes)",
    total_learning_hours: "Total Learning Hours",
  };

  // Columns for the table
  const columns = [
    {
      title: "Sno.",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) =>
        (currentPage - 1) * pageSize + index + 1, // Calculate Sno. based on pagination
    },
    ...Object.entries(headerMapping).map(([key, title]) => ({
      title,
      dataIndex: key,
      key,
    })),
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await dispatch(fetchEmployeeLearningData());
      if (data.error) {
        console.error("Error fetching data:", data.error);
        message.error("Failed to fetch data. Please try again.");
        setLoading(false);
        return;
      }

      const jsonData = data.payload;
      const transformedData = jsonData.map((item, index) => ({
        key: index, // Add a unique key for table rows
        ...item,
      }));
      setTableData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

    const handleDownload = async () => {
      try {
        const jsonData = tableData; // Use existing table data for the download
        const transformedData = jsonData.map((item) => {
          const transformedItem = {};
          for (const key in headerMapping) {
            transformedItem[headerMapping[key]] = item[key] || "";
          }
          return transformedItem;
        });
    
        const ws = XLSX.utils.json_to_sheet(transformedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Employee Data");
        XLSX.writeFile(wb, "employee_data.xlsx");
        message.success("Downloaded successfully!");
      } catch (error) {
        console.error("Error during download:", error);
        message.error("An error occurred while downloading. Please try again.");
      }
    };
    
  const handleLogout = () => {
    localStorage.removeItem("userToken");
    sessionStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  return (
    <div className="admin-container">
      <div className="table-container">
        <Table
          className="employee-table-admin"
          columns={columns}
          dataSource={tableData}
          loading={loading}
          pagination={{
            pageSize: pageSize,
            onChange: (page) => setCurrentPage(page), // Update current page on change
            showSizeChanger: false,
          }}
        />
      </div>

      {/* Footer */}
      <div className="footer-container">
        <div className="button-group">
          <button className="logout-button-admin" onClick={handleLogout}>
            Logout
          </button>

          <Button
            className="download-button"
            onClick={handleDownload}
            type="primary"
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadButton;
