import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleStatus } from '../../features/Courses/Participants';

import { Button } from "antd";
import activateCourses from "../../Assets/SVG/courseslist/activateCourses.svg";

import AC from "../../Assets/icons/mdi_education-outline.png";
import enrollInaCourseIcon from "../../Assets/icons/Vector.png";



const EnrollCoursesHeading = () => {
    const dispatch = useDispatch();

    const enrolledCoursesStatus = useSelector(state => state.participants.enrolledCoursesStatus)


    const handleEnrollInCourse = (status) => {
        if (status !== enrolledCoursesStatus) {
            dispatch(toggleStatus());
        }
    }

    return (
        <div className="coursesList__box__elem1__info enrolledCoursesHeading">
            <div className="enrolled_Courses_left_Container">
                <div className="enrolledCoursesHeadingContainer">
                    <img src={activateCourses} alt="activate" className="coursesList__box__elem1__info__img" />
                    <span className="coursesList__box__elem1__info__span">Enroll in a Course</span>
                </div>

            </div>
            <div className="buttons_div">
                <Button onClick={() => handleEnrollInCourse(true)} type="default" icon={<img src={AC} alt="AC" />} className={`button-margin ${enrolledCoursesStatus ? 'background_Blue' : 'background_white'}`}
                >View Enrolled Courses</Button>
                <Button onClick={() => handleEnrollInCourse(false)} type="default" icon={<img src={enrollInaCourseIcon} alt="Enroll" style={{ color: "blue" }} />} className={`button-margin ${enrolledCoursesStatus ? 'background_white' : 'background_Blue'}`}>Enroll in a course</Button>
            </div>

        </div>

    );
};

export default EnrollCoursesHeading;
