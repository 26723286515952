import React from 'react'
import { Button } from 'antd';
import viewProfileHeader from "../../Assets/Images/viewProfileHeader.png"
import viewProfileIcon from "../../Assets/Images/image.png"

import EditBtn from '../Buttons/EditBtn';

const ViewProfileHeader = ({
    username,
    date,
    profileImage,
    coverImage,
    role,
    location
}) => {
    return (
        <div className='viewProfileHeader'>
            <div style={{ height: "50%" }}>
                <img style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "bottom" }} src={coverImage} alt="Profile Header" />
            </div>
            <div className='ProfileSection' style={{ display: "flex", flexDirection: "column", position: "absolute", top: "40%", left: "45%", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <div style={{ height: "60px", marginBottom: "10px", width: "60px", backgroundColor: "white", borderRadius: "50%", padding: "4px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                    <img style={{ height: "100%", width: "100%" }} src={profileImage} />
                </div>
                <h2 style={{ fontSize: "16px" }} >{username}</h2>
                <p style={{ fontSize: "14px", fontWeight: "300" }}> <span style={{ color: "blue" }} >{role === "Participant" ? (
                    <>
                        Participant
                    </>
                ) : (
                    <>
                        Admin
                    </>
                )}</span>
{/* 
                    | <span style={{ color: "blue" }}>{location}</span> */}
                </p>

            </div>
            {/* <Button className='EditDescBtn' style={{ position: "absolute", right: "10px", top: "60%", transform: "translateY(-50%)" }}>
                <EditBtn />
                <span>Edit</span>
            </Button> */}


        </div>
    )
}

export default ViewProfileHeader
