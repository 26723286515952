import React from 'react'
import { FiEdit } from "react-icons/fi";

const EditBtn = () => {
  return (
    <div className='edit-button'>
      <FiEdit style={{ color: "#3185FC" }} />
    </div>
  )
}

export default EditBtn
