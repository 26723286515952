import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setParticipantView } from '../../features/Courses/Participants'

import { IoChevronBack } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { CgSandClock } from "react-icons/cg";
import { PiCertificate } from "react-icons/pi";

import ProgressCard from './ParticipantView/ProgressCard';
import CoursesCard from './ParticipantView/CoursesCard';
const ParticipantView = () => {
    const dispatch = useDispatch();

    const participantDetails = useSelector(state => state.participants.participantDetails)
    const handleParticipantView = () => {
        dispatch(setParticipantView(false))
    }
    console.log(participantDetails)
    return (
        <div className='participantview-container'>
            {
                participantDetails ? (
                    <>
                        <div className='participantview-container__header'>
                            <div className='participantview-container__header__back'>
                                <IoChevronBack onClick={handleParticipantView} />
                            </div>
                            <div className='participantview-container__header__icon'>
                                <CiUser />
                            </div>
                            <div className='participantview-container__header__name'>
                            {participantDetails.participant_details?.name || 'No Name Available'}                            </div>
                        </div>
                        <hr></hr>
                        <div className='participantview-container__body'>

                        <div className='participantview-container__body__details'>
                            Participant Details
                            <div className='participantview-container__body__details__id'>
                                PID#_{participantDetails.participant_details?.id || 'No ID Available'}
                            </div>
                        </div>
                        <div className='participantview-container__body__progress'>
                            Progress Details
                            <div className='participantview-container__body__progress__cards'>
                                <ProgressCard type={"This Month"} data={participantDetails?.current_month_duration || 0} icon={<CgSandClock />} />
                                <ProgressCard type={"Overall"} data={participantDetails?.total_duration || 0} icon={<CgSandClock />} />
                                <ProgressCard type={"Added Certificates"} data={participantDetails?.total_certificates || 0} icon={<PiCertificate />} />
                            </div>
                        </div>
                        <div className='participantview-container__body__courses'>
                            Courses Enrolled
                            <hr style={{margin: "2vh 0"}}></hr>
                            <div className='participantview-container__body__courses__cards'>
                            {
                                        participantDetails?.courses_enrolled && Array.isArray(participantDetails.courses_enrolled) ? (
                                            participantDetails.courses_enrolled.map(course => (
                                                <div key={course.id}>
                                                    <CoursesCard data={course} />
                                                </div>
                                            ))
                                        ) : (
                                            <div>No Courses Enrolled</div>
                                        )
                                    }
                            </div>
                        </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <div className='participantview-container__header__back'>
                            <IoChevronBack onClick={handleParticipantView} />
                        </div>
                        <span>No data</span>
                    </div>
                )
            }

        </div>
    )
}

export default ParticipantView
