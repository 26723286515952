import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"; // Import useSelector from react-redux
import { Modal ,Button,Image} from 'antd'; // Import Modal component from antd
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Bottom from '../../Assets/Images/bottom.png';
import Logos from '../../Assets/Images/Logos.png';
import Top from '../../Assets/Images/top.png';
import downloadIcon from "../../Assets/icons/ic_sharp-download.png";
import { hostname } from "../../data";

function Certificate({ course, onCancel, visible })  {
  // Assuming individualCourseDetails is available as props or from Redux state


  // Function to handle downloading the certificate
  const handleDownloadCertificate = () => {
    const capture = document.querySelector('.certificate');
    html2canvas(capture).then((canvas) =>{
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('l', 'in', 'letter');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData,'PNG',0,0, componentWidth, componentHeight);
      doc.save('Certificate.pdf');
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={1000}
      footer={[
        <Button className="green_button" onClick={handleDownloadCertificate}>
          <Image src={downloadIcon} />
          Download
        </Button>
      ]}
    >
      {/* Your certificate content goes here */}
    
        <div id="certificate-content" className="certificate" style={{ height:"80vh" }}>
          <img
            className="group-30"
            src={Top}
            alt="group-30"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
              width: "90rem",
              height: "16.3rem",
              top: "3.5rem"
            }}
          />
          <img
            className="group-31"
            src={Bottom}
            alt="group-31"
            style={{
              position: "absolute",
              left: "5rem",
              top: "52.4rem",
              width: "90.8rem",
              height: "9.6rem",
              margin: "0rem 0rem 20rem 0rem"
            }}
          />
          <div className="frame-4" style={{ position: "relative", marginBottom: "3.6rem", display: "flex", flexDirection: "column", alignItems: "center", width: "66.8rem", boxSizing: "border-box", top:"3rem" }}>
            <div className="logos-1" style={{ background:`url(${Logos}) 50% / cover no-repeat`, margin: "7rem 6.4rem 3.8rem 35rem", width: "53.9rem", height: "7.4rem" }}></div>
            <div className="certificate-of-completion" style={{ marginBottom: "4.4rem",marginLeft: "20rem", display: "inline-block", overflowWrap: "break-word", fontFamily: "'Freehand521 BT', 'Roboto Condensed'", fontWeight: 400, fontSize: "3.8rem", textTransform: "capitalize", color: "#284592" }}>
              Certificate of Completion
            </div>
            <div className="frame-3" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "66.8rem", boxSizing: "border-box",margin: "-3rem 0rem 1rem 26rem" }}>
              <div className="karmayogi" style={{ marginBottom: "1.3rem", display: "inline-block", overflowWrap: "break-word", fontFamily: "'Times New Roman', 'Roboto Condensed'", fontWeight: 700, fontSize: "2.5rem", textTransform: "capitalize", color: "#EF8F03" }}>
                {course?.remarks  ? course.remarks : "NA"}
              </div>
              <div className="frame-2" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "66.8rem", boxSizing: "border-box" }}>
                <div className="full-name" style={{ marginBottom: "0.1rem", display: "inline-block", overflowWrap: "break-word", fontFamily: "'Times New Roman', 'Roboto Condensed'", fontWeight: 500, fontSize: "2.4rem", textTransform: "capitalize", color: "#EF8F03" }}>
                  {course?.participant_name}
                </div>
                <div className="vector-40" style={{ background: "#B9B9B9", width: "66.8rem", height: "0.2rem" }}></div>
              </div>
            </div>
          </div>
          <div className="frame-7" style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center",  boxSizing: "border-box", top:"0rem" }}>
            <div className="frame-5" style={{ marginBottom: "2.5rem", display: "flex", flexDirection: "column", alignItems: "center", width: "fit-content", boxSizing: "border-box" }}>
              <div className="has-successfully-completed" style={{ marginBottom: "0.5rem", display: "inline-block", overflowWrap: "break-word", fontFamily: "'Times New Roman', 'Roboto Condensed'", fontStyle: "italic", fontWeight: 400, fontSize: "1.6rem", color: "#000000" }}>
                has successfully completed
              </div>
              <span className="introduction-to-emerging-technologies" style={{ overflowWrap: "break-word", fontFamily: "'Times New Roman', 'Roboto Condensed'", fontStyle: "italic", fontWeight: 700, fontSize: "1.6rem", color: "#000000" }}>
            {course?.name}
              </span>
            </div>
            <div className="frame-6" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "fit-content", boxSizing: "border-box" }}>
              <div className="provided-by" style={{ marginBottom: "0.5rem", display: "inline-block", overflowWrap: "break-word", fontFamily: "'Times New Roman', 'Roboto Condensed'", fontStyle: "italic", fontWeight: 400, fontSize: "1.6rem", color: "#000000" }}>
                Provided by
              </div>
              <span className="capacity-building-commision" style={{ overflowWrap: "break-word", fontFamily: "'Times New Roman', 'Roboto Condensed'", fontStyle: "italic", fontWeight: 700, fontSize: "1.6rem", color: "#000000" }}>
                 {course?.institute_name}
              </span>
            </div>
          </div>
        </div>
    
    </Modal>
  );
}

export default Certificate;
