import React from 'react'
import { ReactComponent as ChooseCourseLogo } from "../../../Assets/SVG/chooseCourse.svg"

const Content = ({ data }) => {
  return (
    <div className='content'>

      <div className='content__header'>

        <div className='content__header__title'>
          <div className='content__header__title__icon'><ChooseCourseLogo style={{ height: "5vh", fill: "#3185FC" }} /></div>
          <span>{data.name}</span>
        </div>

        <div className='content__header__time-status'>
          <div className='content__header__time-status__duration'>
            <span>{data.start_date}</span>
            <span> to </span>
            <span>{data.end_date}</span>
          </div>

          <div className={`content__header__time-status__status ${data.status}`}>
            {data.status}
          </div>

        </div>

      </div>
      <div className='content__body'>
        <div className='content__body__desc'>{data.details}</div>
        <div className='content__body__comp-guest-id'>
          <div className='content__body__comp-guest-id__totalcomp'>
            <ChooseCourseLogo style={{ width: "3vh" }} />
            <span>{data.course_competencies_count} Competencies</span>
          </div>
          <span>C#_ID{data.id}</span>
        </div>
      </div>

      <div className='content__footer'>
        <div className='content__footer__left'>
          <div className='content__footer__left__cadre'>Cadre Details :</div>
          {/* <div className='content__footer__left__course'>Course category</div>
          <div className='content__footer__left__feedback'>12 Feedbacks</div> */}

          {data.cadre.map((cadre, index) => (
            <div key={index} className='content__footer__left__cadre-chip'>
              {cadre}
            </div>
          ))}
        </div>
        <div className='content__footer__right'>
          <div className='content__footer__right__nature'>{data.nature}</div>
        </div>
      </div>
    </div>
  )
}

export default Content
