import React from 'react'

import SearchBar from '../../../Assets/icons/searchBar.svg';
import DropIcon from '../../../Assets/icons/dropIcon.svg';
import { Menu, Dropdown } from 'antd';
import SessionPieChart from './SessionPieChart';


const Colordata = [
    {
        type: "Excellent",
        color: "#2AC239"
    },
    {
        type: "Good",
        color: "#95FFA0"
    },
    {
        type: "Satisfactory",
        color: "#FFCA21"
    },    {
        type: "Needs Improvement",
        color: "#FF8484"
    },
    {
        type: "Poor",
        color: "#FF4848"
    },    {
        type: "Remaining Feedbacks",
        color: "#B4B4B4"
    }

]


const SessionFeedback = () => {
  
    const menu = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" >Newest First</a>
          </Menu.Item>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" >Oldest First</a>
          </Menu.Item>
        </Menu>
      );
  return (
       <div>
      <div className='SessionFeedback'>
      <div className=' SessionFeedback__header'> Response Stats</div>
      {
        Colordata.map((stat) => (
          <div key={stat.type} className='responsestats__stats'>
            <span className='responsestats__stats__color' style={{ backgroundColor: `${stat.color}` }}></span>
            <span>{stat.type}</span>
          </div>
        ))
      }

      
    </div>
    <div className='added-certificate-body__SecondComponent'>
    <div className='added-certificate-body__SecondComponent__SearchBars'>
    <input
        type="text"
       // value={searchQuery}
       // onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Search Here.."
        className="added-certificate-body__SecondComponent__SearchBar"
        style={{ backgroundImage: `url(${SearchBar})`, backgroundRepeat: 'no-repeat', backgroundPosition: '10px center', paddingLeft: '35px', border: 'none', outline: 'none', backgroundSize: '20px 20px' }}
    />
</div>

    <div className='added-certificate-body__SecondComponent__Dropdown'>
    <Dropdown overlay={menu}>
    <a className="ant-dropdown-link" href="#">
    Newest first  <img  className ='Dropdown__icon' src={DropIcon} alt="" />
    </a>
  </Dropdown>
  </div>

</div>
<div className='SessionFeedback__ThirdComponent'>
      <SessionPieChart
       // data={data}
      />
      </div>
    </div>
    
  )
}

export default SessionFeedback
