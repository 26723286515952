import React, { useState } from 'react';
import { ConfigProvider, Form, Input, Button, Row, Col, Modal, DatePicker } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import tickDecagram from "../../Assets/icons/tickDecagram.png"

const AddCourseModal = ({ visible, onOk, onCancel }) => {
    return (
        <Modal
            className='coursesAddModal'
            title="Course Activation"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            footer={[
                <Button style={{ outline: "none", border: "none" }} key="back" onClick={onCancel}>
                    Keep this Course in list
                </Button>,
                <Button style={{ padding: "10px 30px", height: "auto", background: "#FFE60A", color: "black" }} key="submit" type="primary" onClick={onOk}>
                    Activate Course
                </Button>,
            ]}
        >
            <div className='divContainer'>
                {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: '2AC239' }}>
                <CheckCircleOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
                <strong>Course added to the list successfully!</strong>
            </div> */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: '#2AC239' }}>
                    <img src={tickDecagram} alt="Check mark" style={{ width: '20px', marginRight: '10px' }} />
                    <strong>Course added to the list successfully!</strong>
                </div>
                <p>If you need to activate this course right now, then add course timeline.<br />(if not activated the course will be saved in the list without information of added guest lectures.)</p>
                <Form layout="inline">
                    <Row>
                        <Col>
                            <Form.Item label="Start" name="start">
                                <DatePicker style={{ background: "#EBF5FF" }} showTime />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="End" name="end">
                                <DatePicker style={{ background: "#EBF5FF" }} showTime />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
};

export default AddCourseModal;
