import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Radio, Input, InputNumber } from 'antd';
import AC from "../../Assets/icons/AC.png"

import { useDispatch, useSelector } from 'react-redux';
import { addNewSession, addEditedCourse } from '../../features/Courses/Courses';

const AddGuestLecture = () => {

    const editedCourse = useSelector((state) => state.courses.editedCourse);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const showModal = () => {
        form.resetFields(); // Reset form fields when modal is opened
        setIsModalOpen(true);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                values.competencies = ["IOT"];
                values.course_id = editedCourse.id;
                dispatch(addNewSession(values));

                let lastId = 0;
                if (editedCourse.session_details.length > 0) {
                    lastId = editedCourse.session_details[editedCourse.session_details.length - 1].id;
                }

                const newId = lastId + 1;

                const newSession = {
                    speaker_name: values.speaker_name,
                    session: values.session,
                    speaker_type: values.speaker_type,
                    course_id: values.course_id,
                    competencies: ["IoT"],
                };

                const updatedSessionDetails = [...editedCourse.session_details, newSession];

                dispatch(addEditedCourse({ session_details: updatedSessionDetails }));

                setIsModalOpen(false);

            })
            .catch(errorInfo => {
                console.error('Form validation failed:', errorInfo);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        dispatch(addNewSession({}));
    };

    return (
        <>
            <Button style={{ background: "#EBF5FF", color: "#3185FC", padding: "10px", height: "6vh", border: "none" }} onClick={showModal} icon={<img style={{ width: "15px" }} src={AC} alt="AC Icon" />}>
                Add Guest Lecture
            </Button>
            <Modal
                title="Add Guest Lecture"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Add Lecture"
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { textAlign: "center", width: "10vw", height: "6vh", backgroundColor: "#FFE60A", fontSize: "1.1vw", borderRadius: "0.3vw", fontWeight: "500", color: "black" } }}
                centered
                closeIcon={<CloseOutlined style={{ color: 'red' }} />}
            >
                <Form layout="vertical" form={form}>
                    <Form.Item name="speaker_name" label="Speaker Name" className="custom-label" rules={[{ required: true, message: 'Please input guest name!' }]}>
                        <Input placeholder="Typing..." style={{ backgroundColor: "#EBF5FF" }} />
                    </Form.Item>
                    <Form.Item name="session" label="Session" className="custom-label" rules={[{ required: true, message: 'Please input lecture title!' }]}>
                        <Input placeholder="Guest Lecture : Basics of IntelliJ" style={{ backgroundColor: "#EBF5FF" }} />
                    </Form.Item>
                    <Form.Item name="duration" label="Lecture Duration" className="custom-label" rules={[{ required: true, message: 'Please input Course Duration!' }]}>
                        <InputNumber placeholder="Lecture Duration" style={{ backgroundColor: "#EBF5FF" }} />
                    </Form.Item>
                    <Form.Item name="speaker_type" label="Select Guest type" className="custom-label" rules={[{ required: true, message: 'Please select guest type!' }]}>
                        <Radio.Group>
                            <Radio className='custon-radio' value="Inhouse" style={{ fontWeight: "bold" }}> Inhouse </Radio>
                            <Radio className='custon-radio' value="External" style={{ fontWeight: "bold" }}> External </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddGuestLecture;
