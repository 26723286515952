import { React, useState } from "react";
import { ReactComponent as Add } from "../Assets/SVG/Logos/add_courses.svg";
import { ReactComponent as Minimize } from "../Assets/SVG/minimize.svg";
import { ReactComponent as Expand } from "../Assets/SVG/add.svg";

import AddCoursesBtn from "../Components/Buttons/AddCourses";
import ExistingCoursesMenu from "../Components/ExistingCoursesMenu";
import AddNewCoursesMenu from "../Components/AddNewCoursesMenu";
import ActiveCoursesTable from "./courses/ActiveCoursesTable";
import EditCourses from "./courses/EditCourses";
import CheckTokenExpiration from "../Components/TokenExpiryCheck/CheckTokenExpiration";

const Courses = ({ selectedRole }) => {
  // <CheckTokenExpiration />
  const [coursesExpand, setCoursesExpand] = useState(false);
  const [isEditCourseActive, setIsEditCourseActive] = useState(false);
  const [courseData, setCourseData] = useState();
  // const [buttonState, setButtonState] = useState(false)
  const Icon = coursesExpand ? Minimize : Expand;

  const toggleCourses = () => {
    setCoursesExpand(!coursesExpand);
  };



  return (
    <div className="courses">
      <div className="courses__activate-courses">
        <div className="courses__activate-courses__header">
          <div className="courses__activate-courses__header__heading">
            <Add />
            <span>Create Courses</span>
          </div>

          <div
            className="courses__activate-courses__header__actions"
            onClick={toggleCourses}
          >
            <Icon />
          </div>
        </div>
        {coursesExpand && (
          <div className="courses__activate-courses__types">
            {/* <div className='courses__activate-courses__types__btn1' onClick={toggleButton}>            
              <AddCoursesBtn type={'Activate Existing Course'} state={!buttonState} onClick={toggleButton} />
            </div>*/}
            {/* <div className='courses__activate-courses__types__btn2' onClick={toggleButton}>            
              <AddCoursesBtn type={'Create New Course'} state={buttonState} onClick={toggleButton} />
            </div> */}
            <div className="courses__activate-courses__types__btn2">
              <AddCoursesBtn type={"Create New Course"} state={true} />
            </div>
          </div>
        )}
        {coursesExpand && (
          <div>
            {/* {buttonState && <AddNewCoursesMenu/>}
            {!buttonState && <ExistingCoursesMenu/>} */}
            <AddNewCoursesMenu setCoursesExpand={setCoursesExpand} />
          </div>
        )}
      </div>

      {!coursesExpand && (
        <div className="courses__active__courses">
          {isEditCourseActive ? (
            <EditCourses
              courseData={courseData}
              setIsEditCourseActive={setIsEditCourseActive}
            />
          ) : (
            <ActiveCoursesTable
              setIsEditCourseActive={setIsEditCourseActive}
              setCourseData={setCourseData}
              selectedRole={selectedRole}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Courses;
