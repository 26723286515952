import React from 'react'

const AddCoursesBtn = ({type, state}) => {
const addCoursesClass = `add-courses-btn ${state}`
  return (
    <div className={addCoursesClass}>
      {type}
      {state}
    </div>
  )
}

export default AddCoursesBtn
