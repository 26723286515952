import { ReactComponent as HomeLogo } from "./Assets/SVG/Logos/home_logo.svg"
import { ReactComponent as CoursesLogo } from "./Assets/SVG/Logos/courses_logo.svg"
import { ReactComponent as ParticipantsLogo } from "./Assets/SVG/Logos/participants_logo.svg"
import { ReactComponent as FeedbackLogo } from "./Assets/SVG/Logos/feedback_logo.svg"
import { ReactComponent as ContributionLogo } from "./Assets/SVG/Logos/contributions_logo.svg"



// const hostname = "https://income-tax-backend-abffvqxuza-el.a.run.app/"
const hostname = "https://income-tax-backend-1089483309308.asia-south1.run.app/"


export const sidebarComponents = [
  {
    id: 0,
    title: "Home",
    link: `/home`,
    icon: <HomeLogo />,
    state: "inactive"
  },
  {
    id: 1,
    title: "Courses",
    link: `/courses`,
    icon: <CoursesLogo />,
    state: "inactive"
  },
  {
    id: 2,
    title: "Participants",
    link: `/participants`,
    icon: <ParticipantsLogo />,
    state: "inactive"
  },
  {
    id: 3,
    title: "Feedback",
    link: `/feedback`,
    icon: <FeedbackLogo />,
    state: "inactive"
  },
  {
    id: 4,
    title: "Contributions",
    link: `/contributions`,
    icon: <ContributionLogo />,
    state: "inactive"
  }
];

export const partcipantSideBarComponents = [
  {
    id: 0,
    title: "Home",
    link: "/home",
    icon: <HomeLogo />,
    state: "inactive"
  },
  {
    id: 1,
    title: "Courses",
    link: "/courses",
    icon: <CoursesLogo />,
    state: "inactive"
  },
  {
    id: 2,
    title: "Contributions",
    link: "/contributions",
    icon: <ContributionLogo />,
    state: "inactive"
  }
];

export const coursesData = [
  {
    id: 0,
    title: "The Complete 2024 Web Development using React JS, Graph QL, Redux & Typescript",
    course_id: "CID#_213234234",
    status: "Ongoing",
    description: "Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost. Step into the world of programming with our Java Programming Masterclass, meticulously updated for 2024. Since its inception in 2016, this course has evolved continuously, ensuring you receive the latest and most relevant Java education. Purchase once and gain access to a lifetime of updates without any additional cost.",
    competencies: ["IntelliJ Basics", "Java Syntax", "Java - Data Structures", "Collection Framework", "Object Oriented Paradigm "]
  }
]

export const mstuLocations = [
  "ahmedabad",
  "allahabad",
  "amritsar",
  "bareilly",
  "baroda",
  "bengaluru",
  "bhopal",
  "chandigarh",
  "chennai",
  "coimbatore",
  "dehradun",
  "delhi",
  "ghaziabad",
  "guwahati",
  "hazaribagh",
  "hyderabad",
  "indore",
  "jaipur",
  "jodhpur",
  "kanpur",
  "kochi",
  "kolkata",
  "lucknow",
  "ludhiana",
  "madurai",
  "mumbai",
  "nagpur",
  "nashik",
  "panaji",
  "panchkula",
  "patna",
  "pune",
  "puri",
  "raipur",
  "rajkot",
  "shillong",
  "shimla",
  "surat",
  "thane",
  "trichy",
  "trivandrum",
  "udaipur",
  "vizag"
]

export const rcLocations = [
  "ahmedabad",
  "bengaluru",
  "bhopal",
  "chandigarh",
  "chennai",
  "delhi",
  "hazaribagh",
  "kolkata",
  "lucknow",
  "mumbai"
]

export const participantComments = [
  {
    id: 1223,
    name: "Parth Dwivedi",
    details: "Attending the Java Web Technologies course was a game-changer for me! The instructor's expertise and clear explanations helped me grasp complex concepts with ease. Now, I feel confident in my ability to develop dynamic and interactive web applications using Java. Highly recommend this course to anyone looking to level up their skills in web development!",
    rating: 5,
    status: "Excellent"
  },
  {
    id: 1224,
    name: "Parth Dwivedi",
    details: "",
    rating: 4,
    status: "Good"
  },
  {
    id: 1225,
    name: "Parth Dwivedi",
    details: "Attending the Java Web Technologies course was a game-changer for me! The instructor's expertise and clear explanations helped me grasp complex concepts with ease. Now, I feel confident in my ability to develop dynamic and interactive web applications using Java. Highly recommend this course to anyone looking to level up their skills in web development!",
    rating: 5,
    status: "Good"
  }
]

export const functionalCompItems = [
  "Self Confidence",
  "Temperament (Patience & Tolerance)",
  "Spontaneity",
  "Ability to Work under Pressure",
  "Motivator and Role Model",
  "Courtesy & Politeness",
  "Empathy",
  "Emotional Maturity",
  "Listening Ability",
  "Promptness",
  "Responsiveness",
  "Team Spirit",
  "Leadership and Team Building",
  "Mediation & Negotiation Abilities",
  "Willingness to Engage with Trainees/ Trainers",
  "Enabling Approach",
  "Liasioning Skills",
  "Consensus Building Ability",
  "Clarity Of Thought",
  "Knowledge Driven",
  "Critical Thinking",
  "Drive & Desire to Excel",
  "Facilitating Change & Evolution",
  "Openness, Innovation & Enterprise",
  "Ability to Enquire & Research",
  "Willingness to Explore & Experiment",
  "Alertness",
  "Commitment",
  "Disciplined",
  "Enthusiasm & Energy",
  "Initiative",
  "Organised and Orderly Working",
  "Problem Solving Approach",
  "Result Oriented Approach",
  "Decisiveness",
  "Professionalism",
  "Punctuality",
  "Attention to Detail & Accuracy",
  "Fairness",
  "Integrity",
  "Ethical Conduct",
  "Gender Sensitivity",
  "Values Driven Conduct",
  "Accountability",
  "Attention to details",
  "Commitment to Organization",
  "Communication skills",
  "Consultation & Consensus building",
  "Desire for knowledge",
  "Developing Others",
  "Initiative & Drive",
  "Innovative Thinking",
  "Leadership Skills",
  "People First",
  "Planning & coordination",
  "Problem solving & Team working",
  "Result Orientation",
  "Strategic & Conceptual Thinking",
  "Taking accountability",
  "Team Working",
  "Patience",
  "Visionary",
  "Motivation",
  "Courtesy",
  "Listening Ability",
  "Responsibility",
  "Responsiveness",
  "Negotiation and Tactfulness",
  "Mentoring",
  "Inquisitiveness",
  "Knowledge Driven",
  "Critical & logical thinking",
  "Desire for knowledge & Learning",
  "Acceptance of Change & Evolution",
  "Innovation & Enterprise",
  "Mental Alertness",
  "Dedication & Commitment",
  "Discipline",
  "Enthusiasm",
  "Meticulousness",
  "Problem solving and Result Oriented Approach",
  "Professionalism",
  "Transparency, Openness and Fairness",
  "Maintaining confidentiality"
]

export const behavioralCompItems = [
  "Emotional Intelligence",
  "Leadership & Influence",
  "Strategic Thinking & Innovation",
  "Professionalism & Ethical Conduct",
  "Communication Skills",
  "Negotiation/Mediation",
  "Cognitive Effectiveness",
  "Personal Effectiveness",
  "Attention to Detail",
  "Resilience",
  "Problem Solving",
  "Decision Making",
  "Organisation & Operational Management",
  "Citizen Centricity",
  "Gender Sensitivity"
]

export const participantData = {
  id: 1,
  name: "Joh Doe",
  institute: "MSTU Mumbai",
  progress: {
    total: 51,
    month: 9,
    certificates: 8,
  },
  courses: [
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
    {name: "The Complete 2024 Web Development using MENR Tech Stack", enrolled_on: "12-12-2024", guest_lectures: 8},
  ]
}

export { hostname }