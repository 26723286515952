import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Upload, message } from 'antd';
import AddCertificateIcon from '../../Assets/SVG/Logos/AddCertificateIcons.svg';
import ButtonIcon from '../../Assets/SVG/Logos/uploadIcon.svg';
import { makeRequest } from "../../api/api";

const { TextArea } = Input;

const AddCertificate = ({ visible, onClose, deleteSuccess, setDeleteSuccess }) => {
    const initialFieldsState = {
        courseTitle: 'Enter Title...',
        courseDescription: 'Description...',
        instituteName: 'Enter Institute...',
        fileList: [],
        duration: '', // Initial value for duration
    };

    const [fields, setFields] = useState(initialFieldsState);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!visible) {
            // Reset fields when modal is closed
            setFields(initialFieldsState);
            setErrors({});
        }
    }, [visible]);

    const validateFields = () => {
        const newErrors = {};
        if (!fields.courseTitle || fields.courseTitle === initialFieldsState.courseTitle) {
            newErrors.courseTitle = 'Course title is required';
        }
        if (!fields.courseDescription || fields.courseDescription === initialFieldsState.courseDescription) {
            newErrors.courseDescription = 'Course description is required';
        }
        if (!fields.instituteName || fields.instituteName === initialFieldsState.instituteName) {
            newErrors.instituteName = 'Institute name is required';
        }
        if (fields.fileList.length === 0) {
            newErrors.fileList = 'At least one file is required';
        }
        if (!fields.duration || isNaN(fields.duration) || parseFloat(fields.duration) <= 0) {
            newErrors.duration = 'Valid duration in hours is required';
        }
        return newErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            message.error('Please fill all the required fields');
            return;
        }

        try {
            const formdata = new FormData();
            formdata.append("course_name", fields.courseTitle);
            formdata.append("course_description", fields.courseDescription);
            formdata.append("duration", parseFloat(fields.duration)); // Duration in hours
            formdata.append("institute_name", fields.instituteName);
            formdata.append("primary_competency", "AI");
            fields.fileList.forEach(file => {
                formdata.append("certificate", file.originFileObj);
            });

            const url = "/api/participant/upload-certificate/";
            const response = await makeRequest('POST', url, formdata, "follow");

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }

            const result = await response.text();
            message.success('Certificate saved successfully!');
            setDeleteSuccess(!deleteSuccess);
            onClose();
        } catch (error) {
            console.error(error);
            message.error('Failed to save certificate.');
        }
    };

    const handleUploadChange = ({ fileList }) => {
        setFields({ ...fields, fileList });
    };

    const handleDurationChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]+(\.[0-9]*)?$/.test(value)) { // Allow only numeric input
            setFields({ ...fields, duration: value });
        }
    };

    const handleFocus = (fieldName) => {
        if (fields[fieldName] === initialFieldsState[fieldName]) {
            setFields({ ...fields, [fieldName]: '' });
        }
    };

    const handleBlur = (fieldName) => {
        if (fields[fieldName].trim() === '') {
            setFields({ ...fields, [fieldName]: initialFieldsState[fieldName] });
        }
    };

    return (
        <Modal
            visible={visible}
            title={
                <div style={{ display: 'flex', alignItems: 'center', color: '#3185FC', gap: 10 }}>
                    <img src={AddCertificateIcon} style={{ width: 30, height: 30, background: '#DCEDFF', borderRadius: 9999, padding: 6 }} />
                    <span>Add Certificate</span>
                </div>
            }
            onCancel={onClose}
            footer={[
                <Button key="save" type="primary" onClick={handleSave}>Save Certificate</Button>,
            ]}
            style={{ height: '50vh' }}
            bodyStyle={{ padding: '20px 40px', overflowY: 'auto' }}
        >
            <div style={{ marginBottom: '20px' }}>
                <p style={{ color: '#3185FC', marginBottom: '5px' }}>Course Title</p>
                <Input
                    value={fields.courseTitle}
                    onChange={e => setFields({ ...fields, courseTitle: e.target.value })}
                    onFocus={() => handleFocus('courseTitle')}
                    onBlur={() => handleBlur('courseTitle')}
                    placeholder={initialFieldsState.courseTitle}
                />
                {errors.courseTitle && <p style={{ color: 'red' }}>{errors.courseTitle}</p>}
            </div>
            <div style={{ marginBottom: '20px' }}>
                <p style={{ color: '#3185FC', marginBottom: '5px' }}>Course Description</p>
                <TextArea
                    value={fields.courseDescription}
                    onChange={e => setFields({ ...fields, courseDescription: e.target.value })}
                    onFocus={() => handleFocus('courseDescription')}
                    onBlur={() => handleBlur('courseDescription')}
                    rows={4}
                    placeholder={initialFieldsState.courseDescription}
                />
                {errors.courseDescription && <p style={{ color: 'red' }}>{errors.courseDescription}</p>}
            </div>
            <div style={{ marginBottom: '20px' }}>
                <p style={{ color: '#3185FC', marginBottom: '5px' }}>Institute</p>
                <Input
                    value={fields.instituteName}
                    onChange={e => setFields({ ...fields, instituteName: e.target.value })}
                    onFocus={() => handleFocus('instituteName')}
                    onBlur={() => handleBlur('instituteName')}
                    placeholder={initialFieldsState.instituteName}
                />
                {errors.instituteName && <p style={{ color: 'red' }}>{errors.instituteName}</p>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ marginRight: '20px' }}>
                    <p style={{ color: '#3185FC', marginBottom: '5px' }}>Duration (hours)</p>
                    <Input
                        value={fields.duration}
                        onChange={handleDurationChange}
                        style={{ width: 120 }}
                        placeholder="Enter hours"
                    />
                    {errors.duration && <p style={{ color: 'red' }}>{errors.duration}</p>}
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <p style={{ color: '#3185FC', marginBottom: '5px' }}>Upload</p>
                    <Upload
                        fileList={fields.fileList}
                        onChange={handleUploadChange}
                        beforeUpload={() => false}
                    >
                        <Button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 170,
                                height: 30,
                                background: '#DCEDFF',
                                borderRadius: 10,
                                border: '1px #72ACFF dotted',
                                color: '#3185FC',
                                fontSize: 12,
                                fontFamily: 'Noto Sans',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                                padding: '0 10px',
                                marginLeft: 10,
                            }}
                        >
                            <img src={ButtonIcon} alt="Add Certificate Icon" style={{ marginRight: 8 }} />
                            Upload here
                        </Button>
                    </Upload>
                    {errors.fileList && <p style={{ color: 'red' }}>{errors.fileList}</p>}
                </div>
            </div>
        </Modal>
    );
};

export default AddCertificate;
