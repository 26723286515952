import React, { useEffect, useState } from "react";
import { GiGraduateCap } from "react-icons/gi";
import { DownOutlined } from "@ant-design/icons";
import { Input, Dropdown, Space, Table, Tag, Button, Menu, Pagination, Tooltip } from "antd";
import { makeRequest } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { addCourse } from "../../features/Courses/Courses";
import CheckTokenExpiration from "../../Components/TokenExpiryCheck/CheckTokenExpiration";

const ActiveCoursesTable = ({ setIsEditCourseActive, setCourseData, selectedRole }) => {
  const [tableData, setTableData] = useState(null);
  const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState(0);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 8;
  const dispatch = useDispatch();
  const { Search } = Input;

  const handelButtonClick = (record) => {
    setIsEditCourseActive(true);
    setCourseData(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (text, record) => (
        <Tooltip title={text}>
    <div style={{ display: "flex", alignItems: "center", gap: "8px", color: "#3185FC", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
      <GiGraduateCap size={30} color="#3185FC" />
      {text.length > 12 ? `${text.slice(0, 12)}...` : text}
    </div>
  </Tooltip>
               ),
    },
    {
      title: "Time Line",
      dataIndex: "timeLine",
      fixed: "center",
      align: "center",
      key: "timeLine",
    },
    {
      title: "Institute Name",
      dataIndex: "instituteName",
      fixed: "center",
      align: "center",
      key: "instituteName",
    },
    {
      title: "Guest Lectures",
      dataIndex: "guestLectures",
      fixed: "left",
      align: "center",
      key: "guestLectures",
    },
    {
      title: "Status",
      key: "status",
      fixed: "left",
      align: "center",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status.map((status) => {
            let color = "geekblue";
            if (status.toLowerCase() === "ongoing") {
              color = "#FFCA21";
            } else if (status.toLowerCase() === "completed") {
              color = "#2AC239";
            } else if (status.toLowerCase() === "aborted") {
              color = "#FF2E00";
            } else if (status.toLowerCase() === "upcoming") {
              color = "#9747FF";
            }
            return (
              <Tag color={color} key={status}>
                {status}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Students Enrolled",
      dataIndex: "studentsEnrolled",
      fixed: "left",
      align: "center",
      key: "studentsEnrolled",
    },
    {
      title: "",
      fixed: "left",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Button
          type="primary"
          size="small"
          className="activeCoursesTable__box__tableBox__antd__btn"
          onClick={() => handelButtonClick(record)}
        >
          {record["action"]}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchTableData();
  }, [selectedRole, currentPage, currentSelectedDropdown, userSearchInput]);

  const fetchTableData = async () => {
    <CheckTokenExpiration />;
    try {
      const url = apiBuilder(currentPage);
      const data = await makeRequest("get", url);
      const response = await data.json();
      if (data.ok) {
        const table_data = extractTableData(response.data);
        setTableData(table_data);
        setTotalItems(response.total_items);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.log("Error: ", error);
      setTableData([]);
    }
  };

  const apiBuilder = (page) => {
    let url = `/api/courses/fetch-courses/?page=${page}&page_size=${pageSize}&role_name=${selectedRole}`;

    if (currentSelectedDropdown === 1) {
      url += "&sort_by=desc";
    } else if (currentSelectedDropdown === 2) {
      url += "&sort_by=asc";
    }

    if (userSearchInput.length > 2) {
      url += `&search=${userSearchInput}`;
    }

    return url;
  };

  const extractTableData = (input_arr) => {
    const new_arr = [];
    input_arr.forEach((elem) => {
      new_arr.push({
        key: elem["id"],
        name: elem["name"],
        timeLine: `${elem["start_date"]} to ${elem["end_date"]}`,
        guestLectures: elem["guest_lecture_count"],
        status: [elem["status"]],
        studentsEnrolled: elem["students_enrolled"],
        action: !elem["is_writable"] ? "View" : "View/Edit",
        instituteName: elem["institute_name"],
      });
    });
    return new_arr;
  };

  const dropDownItems = [
    { key: 1, title: "Newest First" },
    { key: 2, title: "Oldest First" },
    { key: 0, title: "Clear" },
  ];

  const handleItemClick = (item) => {
    setCurrentSelectedDropdown(item.key);
  };

  const menu = (
    <Menu>
      {dropDownItems.map((item) => (
        <Menu.Item key={item.key} onClick={() => handleItemClick(item)}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="activeCoursesTable">
      <div className="activeCoursesTable__box">
        <div className="activeCoursesTable__box__heading">
          <div className="activeCoursesTable__box__heading__elem1">
            <GiGraduateCap size={30} />
            Courses
          </div>
          <div className="activeCoursesTable__box__heading__elem2">
            <div className="activeCoursesTable__box__heading__elem2__searchbar">
              <Search
                placeholder="Search here..."
                onChange={(e) => setUserSearchInput(e.target.value)}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="activeCoursesTable__box__heading__elem2__dropdown">
              <Dropdown overlay={menu} trigger={["click"]}>
                <Space>
                  {currentSelectedDropdown === 0
                    ? "Sort"
                    : currentSelectedDropdown === 1
                    ? "Newest First"
                    : "Oldest First"}
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="activeCoursesTable__box__tableBox">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: totalItems,
              onChange: (page) => setCurrentPage(page),
            }}
            rowClassName="activeCoursesTable__box__tableBox__antd__row"
            scroll={{ x: 'max-content' }}
            loading={tableData === null ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveCoursesTable;
